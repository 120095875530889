import { DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import CbButton from '../Buttons/CbButton';

export default ({ close, data }: any) => {
  const classes = useClasses();

  const detail =
    data.sendCodeTo === 'email' ? data.flowUserEmail : data.flowUserPhone;
  return (
    <>
      <DialogContent className="modal-title">
        <p className={classes.margins}>
          Your one-time security code is being sent to {detail}.
        </p>
        <p className={classes.margins}>
          Please check your {data.sendCodeTo}, and enter the 6-digit code on the
          Cowbell Platform.{' '}
        </p>
        <p className={classes.margins}>
          The security code expires in 5 minutes.
        </p>
      </DialogContent>
      <DialogActions>
        <CbButton
          styleName="ctaButton"
          buttonText="OK"
          onClick={close}
          size="small"
        />
      </DialogActions>
    </>
  );
};

const useClasses = makeStyles(() => ({
  margins: {
    margin: 0,
  },
}));
