import React from 'react';

const defaultElementProps = {
  id: 'recaptcha',
  className: 'g-recaptcha',
  'data-size': 'invisible',
};

export const useRecaptcha = (
  widgetId = 'recaptcha',
  elementProps = defaultElementProps
) => {
  const [state, setState] = React.useState({
    token: null,
    error: null,
    exception: null,
  });

  function executeChallenge() {
    try {
      // @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
      window.grecaptcha.execute();
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type '(current: { token: null; error: ... Remove this comment to see the full error message
      setState((current) => ({
        ...current,
        error: 'Please refresh the page.',
        token: null,
        exception: error,
      }));
    }
  }

  function getToken(token: any) {
    setState((current) => ({
      ...current,
      error: null,
      exception: null,
      token,
    }));
  }

  function resetToken() {
    try {
      // @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
      window.grecaptcha.reset();
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type '(current: { token: null; error: ... Remove this comment to see the full error message
      setState((current) => ({
        ...current,
        error: 'Please refresh the page.',
        token: null,
        exception: error,
      }));
    }
  }

  function setError(token: any) {
    // @ts-expect-error TS(2345): Argument of type '(current: { token: null; error: ... Remove this comment to see the full error message
    setState((current) => ({
      ...current,
      error: 'Not able to load captcha at this time',
      token,
    }));
  }

  React.useEffect(() => {
    try {
      // @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
      window.grecaptcha.render(widgetId, {
        sitekey: '6LeDSOEUAAAAAFLN2ez_AG2WweicCt4MIcQ2Z3JQ',
        callback: getToken,
        'expired-callback': resetToken,
        'error-callback': setError,
      });
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type '(current: { token: null; error: ... Remove this comment to see the full error message
      setState((current) => ({
        ...current,
        error:
          'Unable to verify if you are a human or a robot. Please make sure you are on latest version of Chrome or try again after page refresh',
        token: null,
        exception: error,
      }));
    }
  }, [widgetId]);

  return {
    token: state.token,
    recaptchaError: state.error,
    exception: state.exception,
    executeChallenge,
    resetToken,
    getElementProps: () => elementProps,
  };
};
