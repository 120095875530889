import _ from 'lodash';
import React from 'react';
import { useSnackbar } from 'notistack';
// mui
import { DialogContent, DialogActions, Grid, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { attestSurplusCompliance } from '../../accounts/AccountService';
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import { Checkbox } from '../../components/inputs';
import CbButton from '../../components/Buttons/CbButton';

const styles = makeStyles(({ config, palette }) => {
  return {
    header: {
      fontSize: (props) => {
        // @ts-expect-error TS(2339): Property 'from' does not exist on type '{}'.
        if (props.from === 'console') {
          return '1.17em';
        }
        return config.textSizes.primer;
      },
      textAlign: (props) => {
        // @ts-expect-error TS(2339): Property 'from' does not exist on type '{}'.
        if (props.from === 'console') {
          return 'left';
        }
        return 'center';
      },
      fontWeight: config.weights.bold,
    },
    subText: {
      textAlign: 'left',
      fontSize: config.textSizes.primer,

      fontWeight: 300,
      lineHeight: 1.38,
      '& p:first-child': {
        marginTop: (props) => {
          // @ts-expect-error TS(2339): Property 'from' does not exist on type '{}'.
          if (props.from === 'console') {
            return 0;
          }
          return '1rem';
        },
      },
    },
    pagination: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: 'normal',
      paddingLeft: '3rem',
    },
    attestCheckbox: {
      top: 0,
      position: 'relative',
      color: palette.common.cowbellBlue,

      '& > *': {
        color: palette.common.cowbellBlue,
      },
    },

    attestCheckboxChecked: {
      '& svg': {
        color: palette.common.cowbellBlue,
      },
    },

    attestLabel: {
      alignItems: 'flex-end',
      position: 'relative',

      '& > *': {
        color: `${palette.primary.contrastText} !important`,
        alignSelf: (props) => {
          // @ts-expect-error TS(2339): Property 'from' does not exist on type '{}'.
          if (props.from === 'console') {
            return 'center';
          }
          return 'end';
        },
        fontSize: config.textSizes.tertia,
        textAlign: 'left',
      },
    },
    alignCenter: {
      textAlign: (props) => {
        // @ts-expect-error TS(2339): Property 'from' does not exist on type '{}'.
        if (props.from === 'console') {
          return 'left';
        }
        return 'center';
      },
    },
  };
});

export const LACompliance = ({
  parentClasses = {},
  handleSubmit,
  setPage,
  setSignature,
  isSubmitting,
}: any) => {
  // @ts-expect-error TS(2339): Property 'bind' does not exist on type 'String'.
  const { bind, send, from } = useSurplusContext();
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles({ from });

  const [surplusNoticeAttested, setAttestation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAttestment = (event: any) => {
    setAttestation(event.target.checked);
  };

  const handlePrevious = () => {
    setPage(2);
  };

  const onConsoleSubmit = () => {
    setLoading(true);
    attestSurplusCompliance({}, { state: 'LA' })
      .then(() => {
        setSignature(true);
      })
      .catch(() => {
        enqueueSnackbar('Not able to attest compliance notice at this time.', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    if (typeof handleSubmit === 'function') {
      const values = from === 'bind' ? bind.surplus : send.surplus;
      handleSubmit({ ...values, surplusNoticeAttested });
    }
  };

  return (
    <>
      <h3 className={`contrast-text ${classes.header}`}>
        LOUISIANA SURPLUS LINES NOTICE
      </h3>
      <Card className={parentClasses.card}>
        <DialogContent
          className="modal-title"
          style={
            from === 'console' ? { padding: 0 } : { padding: '0 5rem 1rem' }
          }
        >
          <div className={classes.subText}>
            <p>
              This insurance policy is delivered as surplus line coverage under
              the Louisiana Insurance Code.
            </p>
            <p>
              In the event of insolvency of the company issuing this contract,
              the policyholder or claimant is not covered by the Louisiana
              Insurance Guaranty Association or the Louisiana Life and Health
              Guaranty association, which guarantees only specific types of
              policies issued by insurance companies authorized to do business
              in Louisiana.
            </p>
            <p>
              This surplus lines policy has been procured by the following
              licensed Louisiana surplus lines broker:
            </p>
          </div>
          <div className={classes.alignCenter}>
            <Checkbox
              checked={surplusNoticeAttested}
              onChange={handleAttestment}
              label={getAttestLabelText(_.get(bind, 'surplus'))}
              classes={{
                root: classes.attestCheckbox,
                checked: classes.attestCheckboxChecked,
              }}
              formLabelProps={{
                classes: {
                  root: classes.attestLabel,
                },
              }}
            />
          </div>
          <p className={classes.subText} style={{ fontSize: '1.16rem' }}>
            This serves as your legal signature and acknowledges the information
            provided.
          </p>
        </DialogContent>

        {from === 'console' ? (
          <DialogActions
            style={{ justifyContent: 'center', padding: '1.66rem 0 0' }}
          >
            <CbButton
              styleName="ctaButton"
              onClick={onConsoleSubmit}
              disabled={!surplusNoticeAttested || loading}
              buttonText="I Attest"
              loading={loading}
            />
          </DialogActions>
        ) : (
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid className={classes.pagination}>
                Step 3 of 3: Louisiana Surplus Lines Notice
              </Grid>
              <Grid>
                <CbButton
                  styleName="cancel"
                  onClick={handlePrevious}
                  buttonText="Previous"
                />
                <CbButton
                  styleName="ctaButton"
                  onClick={onSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting || !surplusNoticeAttested}
                  buttonText={from === 'bind' ? 'Bind Quote' : 'Send Quote'}
                />
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Card>
    </>
  );
};

const getAttestLabelText = (data: any) => {
  return `By checking the box, I ${_.get(
    data,
    'surplusLinesBrokerFirstName'
  )} ${_.get(
    data,
    'surplusLinesBrokerLastName'
  )}, acknowledge and agree to be the Licensed Louisiana Surplus Lines Broker or Authorized Representative`;
};

export default LACompliance;
