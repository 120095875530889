import React from 'react';
import { useForm } from 'react-hook-form';
// mui
import { DialogContent, FormHelperText, DialogActions } from '@mui/material';
// actions
import { resetMfa } from '../../api/AuthService';
// components
import withRouter from '../hocs/withRouter';
import CbButton from '../Buttons/CbButton';
import { manageAPIError } from '../../utils';

export const ResetMfaConfirm = withRouter(({ data, close, history }: any) => {
  const [error, setError] = React.useState('');
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleCancel = () => {
    close();
    history.push('/login');
  };

  const onSubmit = () => {
    resetMfa({ username: data.username, password: data.password })
      .then(() => {
        close();
      })
      .catch((error) => {
        if (error.response.status == 401) {
          setError(
            'Invalid login credentials. Please reset your password first, if necessary.'
          );
          return;
        }

        const errorMessage = manageAPIError(
          error,
          'Cannot reset password at this time. Please try again later.'
        );

        setError(errorMessage);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="modal-title">
        Are you sure you’d like to reset your MFA setting?
        <FormHelperText
          className="api-text-error"
          sx={{ width: '80%', margin: '0 auto' }}
        >
          {error}
        </FormHelperText>
      </DialogContent>

      <DialogActions>
        <CbButton
          buttonText="Cancel"
          styleName="cancel"
          onClick={handleCancel}
        />
        <CbButton
          buttonText="Reset MFA"
          styleName="ctaButton"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </DialogActions>
    </form>
  );
});

export default ResetMfaConfirm;
