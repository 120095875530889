import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import _ from 'lodash';
// ui
import { useTheme } from '@mui/material/styles';
// components
import { useForm } from 'react-hook-form';
import {
  Box,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { PubSub } from '../../../utils/eventUtils';

import { withFormController } from '../../../components/hocs/forms';
import { TextFieldBase } from '../../../components/inputs/TextFieldBase';
import CBButton from '../../../components/Buttons/CbButton';
import { CircularProgressWithLabel } from '../../../components/CircularProgressWithlabel';

import {
  installmentPlanForm,
  getXpressDocument,
  confirmFinancing,
} from '../_services/BillingService';
import { downloadAWSDoc } from '../../../policies/PolicyService';
import { fetchAgencyDetails } from '../../../agencies/AgencyService';
import { saveBlobFile, moneyFormat2Decimals } from '../../../utils/appUtils';
import { ProductTypes } from '../../../types';
import { manageAPIError } from '../../../utils';
import { toUniversalUtcDate } from '../../../utils/date.utils';

const TextField = withFormController(TextFieldBase);

const InstallmentPlan = ({ close, classes, data }: any) => {
  const accountDetails = data.firmographicData ?? {};

  const theme = useTheme();

  const textStyle = {
    fontSize: '20px',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  };

  const [installmentData, setData] = useState('');
  const [error, setError] = useState(false);
  const [agencyDetailsMissing, setDetails] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  useEffect(() => {
    const brokerFee = _.isUndefined(data.brokerFee) ? 0 : data.brokerFee;
    const mgaFee = _.isUndefined(data.mgaFee) ? 0 : data.mgaFee;

    const payload = {
      quoteNumber: data.quoteNumber,
      customerName: `${data.customerFirstName} ${data.customerLastName}`,
      customerPhone: data.customerPhone,
      customerEmail: data.customerEmail,
      addressLine1: accountDetails.address1,
      addressLine2: accountDetails.address2 || '',
      city: accountDetails.city,
      state: accountDetails.state,
      country: accountDetails.country,
      zip: accountDetails.zipCode,
      policyNumber: data.policyNumber,
      policyEffectiveDate: data.effectiveDate,
      policyTermMonths: 12,
      premium: (data.totalPremium - brokerFee - mgaFee).toFixed(2),
      earnedTaxes: brokerFee + mgaFee,
      financedTaxes: 0,
      policyCreated: data.created,
      policyId: data.id,
      productType: accountDetails.product,
      accountName: accountDetails.name,
      isEndorsement: _.get(data, 'isEndorsement', false),
    };

    if (
      accountDetails.product === ProductTypes.p250 ||
      accountDetails.product === ProductTypes.p100_pro
    ) {
      fetchAgencyDetails(data.agencyId)
        .then((resp) => {
          const { name, address1, address2, city, state, zipCode } = resp.data;
          // @ts-expect-error TS(2339): Property 'agencyId' does not exist on type '{ quot... Remove this comment to see the full error message
          payload.agencyId = data.agencyId;
          // @ts-expect-error TS(2339): Property 'agencyName' does not exist on type '{ qu... Remove this comment to see the full error message
          payload.agencyName = name;
          // @ts-expect-error TS(2339): Property 'agencyAddress' does not exist on type '{... Remove this comment to see the full error message
          payload.agencyAddress = address1 + address2;
          // @ts-expect-error TS(2339): Property 'agencyCity' does not exist on type '{ qu... Remove this comment to see the full error message
          payload.agencyCity = city;
          // @ts-expect-error TS(2339): Property 'agencyState' does not exist on type '{ q... Remove this comment to see the full error message
          payload.agencyState = state;
          // @ts-expect-error TS(2339): Property 'agencyZip' does not exist on type '{ quo... Remove this comment to see the full error message
          payload.agencyZip = zipCode;

          if (data.agencyId && name && address1 && city && state && zipCode) {
            installmentPlanForm({}, payload)
              .then((response: any) => {
                setData(response.data);
              })
              .catch((error: any) => {
                setDetails(false);
                const errorMsg = manageAPIError(
                  error,
                  'Something went wrong. Try again later.'
                );

                setError(errorMsg);
              });
          } else {
            setDetails(true);
            setError(
              // @ts-expect-error TS(2345): Argument of type '"Agency Details Missing. Please ... Remove this comment to see the full error message
              'Agency Details Missing. Please contact your agent or support@cowbellcyber.ai.'
            );
          }
        })
        .catch((error) => {
          setDetails(false);
          const errorMsg = manageAPIError(
            error,
            'Agency Details Missing. Please contact your agent or support@cowbellcyber.ai.'
          );

          setError(errorMsg);
        });
    } else {
      installmentPlanForm({}, payload)
        .then((resp: any) => {
          setData(resp.data);
        })
        .catch((error: any) => {
          const errorMsg = manageAPIError(
            error,
            'Something went wrong. Try again later.'
          );

          setError(errorMsg);
        });
    }

    // eslint-disable-next-line
  }, []);

  const getXpressDoc = () => {
    const { policyNumber, companyName } = data;

    const payload = {
      policyId: data.id,
      accountId: accountDetails.accountId,
      createdDate: data.created,
    };
    getXpressDocument(payload)
      .then((response: any) => {
        const url = _.get(response, 'data');
        downloadAWSDoc(url)
          .then((resp) => {
            saveBlobFile(
              resp,
              `${companyName}-${policyNumber}-Xpress Document`,
              '.pdf'
            );
          })
          .catch(() => {
            // @ts-expect-error TS(2345): Argument of type '"Unable to download the Policy D... Remove this comment to see the full error message
            setError('Unable to download the Policy Documents');
          });
      })
      .catch((error: any) => {
        setDetails(false);
        const errorMsg = manageAPIError(
          error,
          'Something went wrong. Try again later.'
        );

        setError(errorMsg);
      });
  };

  const onSubmit = (formData: any) => {
    const payload = {
      policyId: data.id,
      // @ts-expect-error TS(2339): Property 'FinanceQuoteNumber' does not exist on ty... Remove this comment to see the full error message
      financeQuoteNumber: installmentData.FinanceQuoteNumber,
      // @ts-expect-error TS(2339): Property 'AmountFinanced' does not exist on type '... Remove this comment to see the full error message
      amountFinanced: installmentData.AmountFinanced,
      // @ts-expect-error TS(2339): Property 'APR' does not exist on type 'string'.
      apr: installmentData.APR,
      // @ts-expect-error TS(2339): Property 'NumberOfInstallments' does not exist on ... Remove this comment to see the full error message
      numberOfIntsallments: installmentData.NumberOfInstallments,
      // @ts-expect-error TS(2339): Property 'TotalPremium' does not exist on type 'st... Remove this comment to see the full error message
      totalPremium: installmentData.TotalPremium,
      // @ts-expect-error TS(2339): Property 'DownPayment' does not exist on type 'str... Remove this comment to see the full error message
      downPayment: installmentData.DownPayment,
      // @ts-expect-error TS(2339): Property 'TotalPaymentAmount' does not exist on ty... Remove this comment to see the full error message
      totalPaymentAmount: installmentData.TotalPaymentAmount,
      // @ts-expect-error TS(2339): Property 'InstallmentAmount' does not exist on typ... Remove this comment to see the full error message
      installmentAmount: installmentData.InstallmentAmount,
      // @ts-expect-error TS(2339): Property 'FinanceCharge' does not exist on type 's... Remove this comment to see the full error message
      financeCharge: installmentData.FinanceCharge,
      email: data.customerEmail,
      signature: formData.name,
      productType: accountDetails.product,
    };

    if (formData.name) {
      // @ts-expect-error TS(2345): Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
      setError('');
      return confirmFinancing({}, payload)
        .then(() => {
          close();
          PubSub.publish('financing:accpeted', {
            isDownPayment: true,
          });
        })
        .catch((error: any) => {
          setDetails(false);

          setError(
            manageAPIError(
              error,
              'Something went wrong applying premium financing. Please try again.'
            )
          );
        });
    }
    setDetails(false);
    // @ts-expect-error TS(2345): Argument of type '"Please enter full name."' is no... Remove this comment to see the full error message
    setError('Please enter full name.');
  };

  return (
    <>
      {installmentData ? (
        <section className={classes.cardContentContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent classes={{ root: classes.container }}>
              <section className={classes.policyDetails}>
                <p
                  className={`${classes.title} ${classes.bold} ${classes.horizontalLine}`}
                >
                  Installment Plan Details
                </p>
                <section className={classes.line}>
                  <p>APR</p>
                  {/* @ts-expect-error: FIXME */}
                  <p>{installmentData.APR}%</p>
                </section>
                <section className={classes.line}>
                  <p>Total Premium</p>
                  {/* @ts-expect-error: FIXME */}
                  <p>{moneyFormat2Decimals(installmentData.TotalPremium)}</p>
                </section>
                <section
                  className={`${classes.line} ${classes.horizontalLine} ${classes.bold}`}
                >
                  <p>Down Payment</p>
                  {/* @ts-expect-error: FIXME */}
                  <p>{moneyFormat2Decimals(installmentData.DownPayment)}</p>
                </section>
                <section className={classes.line}>
                  <p>Amount Financed</p>
                  <p>
                    {/* @ts-expect-error: FIXME */}
                    {moneyFormat2Decimals(installmentData.AmountFinanced)}
                  </p>
                </section>
                <section
                  className={`${classes.line} ${classes.horizontalLine}`}
                >
                  <p>Finance Charge</p>
                  {/* @ts-expect-error: FIXME */}
                  <p>{moneyFormat2Decimals(installmentData.FinanceCharge)}</p>
                </section>
                <section
                  className={`${classes.line} ${classes.horizontalLine}`}
                >
                  <p>Total of Payment</p>
                  <p>
                    {/* @ts-expect-error: FIXME */}
                    {moneyFormat2Decimals(installmentData.TotalPaymentAmount)}
                  </p>
                </section>

                <section className={`${classes.line} ${classes.bold}`}>
                  <p>Installment Amount (x10)</p>
                  <p>
                    {/* @ts-expect-error: FIXME */}
                    {moneyFormat2Decimals(installmentData.InstallmentAmount)}
                  </p>
                </section>
                <section className={classes.line}>
                  <p>Your installments are scheduled</p>
                  <p>Monthly</p>
                </section>
                <section className={`${classes.line}`}>
                  <p>
                    Your first installment in the amount of{' '}
                    {/* @ts-expect-error: FIXME */}
                    {moneyFormat2Decimals(installmentData.InstallmentAmount)} is
                    due on
                  </p>
                  <p>
                    {toUniversalUtcDate(
                      // @ts-expect-error TS(2339): Property 'FirstInstallmentDue' does not exist on t... Remove this comment to see the full error message
                      Moment(installmentData.FirstInstallmentDue).add(1, 'day')
                    )}
                  </p>
                </section>
              </section>
              <section className={classes.terms}>
                <h3>Acknowledgement of Premium Financing:</h3>
                <p>
                  (A){' '}
                  <span>
                    I am entering into a premium finance agreement (“PFA”) with
                    gotoPremiumFinance.com
                  </span>
                </p>
                <p>
                  (B){' '}
                  <span>
                    A copy of the completed PFA disclosing all terms and
                    conditions of the financing transaction to which I am
                    agreeing can be viewed by clicking on this link.
                  </span>
                </p>
                <p>
                  (C){' '}
                  <Box
                    component="span"
                    className={classes.doc}
                    onClick={getXpressDoc}
                  >
                    I will have 10 days to disaffirm the PFA at no cost to me if
                    I do not agree to the terms and conditions of the PFA.
                  </Box>
                </p>
                <p>
                  (D){' '}
                  <span>
                    If I choose to disaffirm the PFA, I will be required to emit
                    the annual payment in full to Cowbell Cyber within 30 days
                    or risk cancellation of my insurance policy.
                  </span>
                </p>
                <p>
                  (E){' '}
                  <span>
                    The PFA will include, among other things, my granting of a
                    power of attorney to cancel my insurance policy if I am in
                    default under the PFA.
                  </span>
                </p>
              </section>
              <div className={classes.text}>
                <TextField
                  id="standard-basic"
                  placeholder="enter full name"
                  name="name"
                  // @ts-expect-error TS(2322): Type '{ control: Control<FieldValues, any>; }' is ... Remove this comment to see the full error message
                  controllerProps={{ control }}
                />
              </div>
              {error && (
                <FormHelperText className="api-text-error">
                  {error}
                </FormHelperText>
              )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
              <CBButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                styleName="ctaButton"
                buttonText="I Accept"
              />
            </DialogActions>
          </form>
        </section>
      ) : (
        <>
          {!error ? (
            <div className={classes.loading}>
              <CircularProgressWithLabel
                value={
                  <>
                    Installment Plan <br />
                    Coming Up.
                    <br /> Please Wait.
                  </>
                }
                size={200}
                style={textStyle}
              />
            </div>
          ) : (
            <>
              {agencyDetailsMissing ? (
                // @ts-expect-error TS(2322): Type '{ fontSize: string; textAlign: string; color... Remove this comment to see the full error message
                <p style={textStyle}>
                  Agency Details are missing.
                  <br /> Please contact support@cowbellcyber.ai for more
                  information.
                </p>
              ) : (
                <>
                  {/* @ts-expect-error: FIXME */}
                  <p style={textStyle}>Something went wrong.</p>
                  <div className={classes.flexbox}>
                    <FormHelperText className="api-text-error">
                      {error}
                    </FormHelperText>
                  </div>
                </>
              )}
            </>
          )}
          <DialogActions style={{ justifyContent: 'flex-start' }} />
        </>
      )}
    </>
  );
};

const styles = ({ config, palette }: any) => {
  return {
    cardContentContainer: {
      overflow: 'auto',
      maxHeight: '40rem',
    },
    terms: {
      fontSize: '1.167rem',
      color: palette.primary.main,
      paddingTop: '0.7rem',
      '& h3': {
        margin: '0 !important',
        fontWeight: config.weights.bold,
        lineHeight: 1.43,
        paddingLeft: '1.4rem',
      },
      '& p': {
        margin: '0.7rem 0 !important',
        lineHeight: 1.14,
      },
      '& span': {
        fontStyle: 'italic',
      },
    },
    bold: {
      fontWeight: config.weights.bold,
    },
    horizontalLine: {
      borderBottom: `1px solid ${palette.primary.modalBorder}`,
    },
    policyDetails: {
      width: '100%',
      borderRadius: 5,
      border: `1px solid ${palette.primary.modalBorder}`,
      padding: '1rem 1.33rem',

      '& p': {
        margin: '0 !important',
        fontSize: '1.33rem',
        lineHeight: 1.5,
        color: palette.primary.main,
      },
    },
    line: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '5px',
    },
    container: {
      width: '50rem',
      padding: '0 2.5rem',
    },
    inputFields: {
      justifyContent: 'space-around',
    },
    title: {
      paddingBottom: '5px',
    },
    doc: {
      color: config.colors.cowbellBlue,
      textDecoration: 'underline',
      fontWeight: config.weights.bold,
      cursor: 'pointer',
    },
    text: {
      '& .MuiInputBase-input': {
        borderRadius: 1,
        borderBottom: 'solid',
        borderStyle: 'inherit',
        borderBottomWidth: 'thin',
        background: palette.background.modal,
        width: 'auto',
        '&:focus': {
          color: palette.primary.contrastText,
          background: 'inherit',
        },
      },
    },
    flexbox: {
      display: 'flex',
      justifyContent: 'center',
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
    },
  };
};

export default withStyles(styles)(InstallmentPlan);
