import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { compose } from 'redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid } from '@mui/material';

import * as Yup from 'yup';

// components
import { withStyles } from '@mui/styles';
import { SimpleSelect } from '../inputs/selects/SimpleSelect';
import { withFormController } from '../hocs/forms';
import { TextFieldBase } from '../inputs/TextFieldBase';

import { UsStatesFull } from '../../utils/USState';
import { sendPolicyCertificateEmail } from '../../policies/PolicyService';

import { withShowable } from '../../console/_global/lib/withShowable';
import { globalSchema } from '../globalValidations';
import { withToggleModal } from '../hocs/withToggleModal';

import { useToggleModal } from '../../utils/modal.utils';
import { useAPIErrorHandler } from '../hooks/useAPIErrorHandler';

const ConnectedTextField = withFormController(TextFieldBase);
const Select = withFormController(SimpleSelect);

const MuiGrid = withShowable(Grid);
const TextField = withShowable(ConnectedTextField);

const schema = Yup.object().shape({
  address1: Yup.string().required('Please enter a Address '),
  organizationName: Yup.string().required('Please enter a Orginization.'),
  zip: globalSchema.zipCode,
  city: Yup.string().required('Please enter a City '),
  state: Yup.string().required('Please select a State'),
});

const styles = ({ config, palette }: any) => ({
  itemContainer: {
    width: '100%',
    position: 'relative',
    paddingBottom: 10,
  },
  headings: {
    marginTop: '27px',
    color: palette.primary.contrastText,
    fontWeight: config.weights.normal,
    fontSize: config.textSizes.primer,
  },
  nameContainer: {
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
  },
  itemNameLine: {
    margin: 0,
    fontWeight: 'bold',
  },
  cowbellBlue: {
    color: palette.common.cowbellBlue,
  },
  spanContainer: {
    position: 'relative',
    right: '10px',
    top: '70px',
    fontSize: config.textSizes.normal,
    color: palette.text.text8,
  },
});

export const CertificateHolderForm = compose(
  withToggleModal,
  // @ts-expect-error TS(2345): Argument of type '({ config, palette }: any) => { ... Remove this comment to see the full error message
  withStyles(styles),
  withShowable
)(({ data, docType }: any) => {
  const toggleModal = useToggleModal();
  const handleAPIError = useAPIErrorHandler();

  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  function onSubmit({ ...formData }) {
    const payload = {
      ...formData,
      policyId: data.id,
      docType,
    };

    return sendPolicyCertificateEmail({ productType: data.product }, payload)
      .then(() => {
        toggleModal.direct('certificateEmailConfirm', data, {
          title: 'Generate Policy Certificate',
          footerButtons: [
            {
              buttonText: 'Okay',
              action: 'cancel',
              showLoading: true,
              showDisabled: true,
            },
          ],
        });
      })
      .catch(handleAPIError('Unable to send policy certificate email'));
  }

  return (
    // @ts-expect-error TS(2741): Property 'handleSubmit' is missing in type '{ chil... Remove this comment to see the full error message
    <FormProvider {...methods}>
      <form
        id="certificateHolderForm"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <MuiGrid container>
          <MuiGrid container>
            <MuiGrid item md={12}>
              <TextField
                required
                label="Organization Name "
                name="organizationName"
                fullwidth
              />
            </MuiGrid>

            <MuiGrid container rowSpacing={4} columnSpacing={2}>
              <MuiGrid item xs={5}>
                <TextField required label="Address" name="address1" />
              </MuiGrid>
              <MuiGrid item xs={3}>
                <TextField required name="city" label="City" />
              </MuiGrid>
              <MuiGrid item xs={2}>
                <Select
                  label="State"
                  required
                  name="state"
                  options={UsStatesFull}
                  InputLabelProps={{ style: { padding: '0.5rem 0' } }}
                  variant="contained"
                />
              </MuiGrid>
              <MuiGrid item xs={2}>
                <TextField name="zip" label="Zip Code" required />
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </form>
    </FormProvider>
  );
});
