import { createAction, handleActions } from 'redux-actions';
import AT from '../actions/ActionTypes';
import { simpleStateMerge } from '../utils/reducerUtils';

const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
const SET_AGENCY_DETAILS = 'SET_AGENCY_DETAILS';

const accountDefaults = {
  hostname: '',
  name: '',
};

export const setAccountDetails = createAction(SET_ACCOUNT_DETAILS);
export const setAgencyDetails = createAction(SET_AGENCY_DETAILS);

export const account = handleActions(
  {
    SET_ACCOUNT_DETAILS: (state, action) => {
      const newState = action.error ? state : simpleStateMerge(state, action);
      return newState;
    },
    SET_AGENCY_DETAILS: (state, action) => {
      return {
        ...state,
        agency: action.payload,
      };
    },
    [AT.FETCH_ACCOUNT_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        policyHolderAccount: action.payload,
        // @ts-expect-error TS(2339): Property 'account' does not exist on type '{ hostn... Remove this comment to see the full error message
        orgData: action.payload.account || action.payload,
      };
    },
    [AT.UPDATE_ACCOUNT_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'account' does not exist on type '{ hostn... Remove this comment to see the full error message
        accountType: action.payload.account.accountType,
        // @ts-expect-error TS(2339): Property 'account' does not exist on type '{ hostn... Remove this comment to see the full error message
        id: action.payload.account.id,
        // @ts-expect-error TS(2339): Property 'account' does not exist on type '{ hostn... Remove this comment to see the full error message
        name: action.payload.account.name,
        // @ts-expect-error TS(2339): Property 'account' does not exist on type '{ hostn... Remove this comment to see the full error message
        type: action.payload.account.accountType,
      };
    },
    [AT.SEARCH_NAICS_CODES_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        NAICSCodes: action.payload,
      };
    },
    [AT.GET_PROFILE_SCORE_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        profileScore: action.payload,
      };
    },
    [AT.GET_PAYMENT_PROCESSOR]: (state, action) => {
      return {
        ...state,
        paymentProcessor: action.payload,
      };
    },
    [AT.UPDATE_EXCESS_DETAILS]: (state, action) => {
      return {
        ...state,
        excess: action.payload,
      };
    },

    [AT.USER_LOGOUT]: () => {
      return accountDefaults;
    },
  },
  accountDefaults
);
