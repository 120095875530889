import Moment from 'moment';
import { commonDateParamMappings } from '../../api/common/mappings';
import { trim } from '../../utility/QueryUtility';

export const fetchServiceParamsMapping = [
  ...commonDateParamMappings,
  {
    path: 'from',
    paramKey: 'after',
    transform: ({ from, to }: any) => {
      if (!from) {
        return null;
      }

      return from === to
        ? Moment.unix(from).startOf('day').toISOString()
        : Moment.unix(from).toISOString();
    },
  },
  {
    path: 'to',
    paramKey: 'before',
    transform: ({ to, from }: any) => {
      if (!to) {
        return null;
      }

      return from === to
        ? Moment.unix(to).endOf('day').toISOString()
        : Moment.unix(to).toISOString();
    },
  },

  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      switch (orderBy) {
        case 'eventSummary':
        case 'id':
        case 'eventType':
          return `${orderBy}.keyword,${order}`;

        case 'timestampInfo.timestamp':
        case 'timestamp':
          return `modified,${order}`;

        default:
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          return null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search }: any) => (search ? trim(search) : null),
  },

  // Service Mappings for search fields
  {
    path: 'name',
  },
  {
    path: 'description',
  },
  {
    path: 'hostname',
  },
];
