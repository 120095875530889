import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Grid as MuiGrid, DialogContent, FormHelperText } from '@mui/material';

// components
// utils

import { useForm } from 'react-hook-form';

import { updateWebHook } from './WebHookService';
import { PubSub } from '../utils/eventUtils';
import { TextFieldBase } from '../components/inputs/TextFieldBase';
import { withFormController } from '../components/hocs/forms';
import { useToggleModal } from '../utils/modal.utils';

const TextField = withFormController(TextFieldBase);

const WebHookEdit = ({ data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const [error, setError] = useState('');
  const { control } = useForm({
    defaultValues: {
      clientId: data.clientId || '',
      clientSecret: data.clientSecret || '',
      webhookUrl: data.webhookUrl || '',
    },
  });

  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData: any) => {
      if (eventData) {
        handleSubmit();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    // formData

    // @ts-expect-error TS(2339): Property 'getValues' does not exist on type 'Contr... Remove this comment to see the full error message
    const { clientId, clientSecret, webhookUrl } = control.getValues();

    const payload = {
      id: data.id,
      clientId,
      clientSecret,
      webhookUrl,
    };
    return updateWebHook(
      {},
      {
        ...payload,
      }
    )
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        enqueueSnackbar(`WebHook Updated successfully!`, {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'webhooks');
        toggleModal.direct('WebHookEdit', false);
      })
      .catch((error: any) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        setError(error.response.data);
      });
  }

  const inputProps32 = { maxlength: 32, minlength: 32 };
  const inputProps16 = { maxlength: 16, minlength: 16 };

  return (
    <DialogContent>
      <form id="submit-form">
        <MuiGrid item style={{ marginTop: -20 }}>
          <TextField
            name="clientId"
            label="Client ID"
            required
            // @ts-expect-error TS(2322): Type 'Control<{ clientId: any; clientSecret: any; ... Remove this comment to see the full error message
            controllerProps={{ control }}
            fullWidth
            inputProps={inputProps32}
          />
        </MuiGrid>
        <MuiGrid item style={{ paddingTop: 0 }}>
          <TextField
            name="clientSecret"
            label="Client Secret"
            required
            // @ts-expect-error TS(2322): Type 'Control<{ clientId: any; clientSecret: any; ... Remove this comment to see the full error message
            controllerProps={{ control }}
            fullWidth
            inputProps={inputProps32}
          />
        </MuiGrid>
        <MuiGrid item style={{ paddingTop: 0 }}>
          <TextField
            name="webhookUrl"
            label="Webhook Url"
            required
            // @ts-expect-error TS(2322): Type 'Control<{ clientId: any; clientSecret: any; ... Remove this comment to see the full error message
            controllerProps={{ control }}
            fullWidth
            inputProps={inputProps16}
          />
        </MuiGrid>
        <FormHelperText className="api-text-error">{error}</FormHelperText>
      </form>
    </DialogContent>
  );
};

export default WebHookEdit;
