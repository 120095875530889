import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
// components
import { makeStyles } from '@mui/styles';
import { TypeAheadBase } from '../../components/inputs/autocomplete';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
// services
import { getMyTeams } from '../../teams/TeamsService';

const defaultLabelProps = {
  style: { padding: 0, fontSize: '1rem' },
};

export const AgencyTeamsLookupField = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'show' does not exist on type '{}'.
    show = true,
    // @ts-expect-error TS(2339): Property 'defaultOptions' does not exist on type '... Remove this comment to see the full error message
    defaultOptions,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'labelProps' does not exist on type '{}'.
    labelProps = defaultLabelProps,
    ...props
  }) => {
    if (!show) {
      return null;
    }
    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue: any) {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(({ input }: any, callback: any) => {
      getMyTeams({ search: input }).then(({ data }: any) => {
        callback(
          data.content.map((team: any) => ({
            label: `${team.name}`,
            value: team.id,

            meta: {
              ...team,
            },
          }))
        );
      });
    }, []);

    function onOptionsChange(newOptions: any) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
          htmlFor={props.name}
          // @ts-expect-error TS(2339): Property 'required' does not exist on type '{}'.
          required={props.required}
          {...labelProps}
        >
          {label || 'Team'}
        </InputLabelBase>
        <TypeAheadBase
          id="teams"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          fetchOnMount
          {...props}
        />
      </>
    );
  }
);

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));
