import React from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText,
} from '@mui/material';

export const CheckBox = ({
  label = '',
  formLabelProps = {},
  required = false,
  ...props
}) => {
  const color = props.disableRipple ? 'primary' : 'secondary';
  if (label) {
    return (
      <FormControl
        error={props.errorMessage}
        variant="standard"
        {...formLabelProps}
      >
        <MuiFormControlLabel
          label={label}
          // @ts-expect-error TS(2322): Type '{ label: string; variant: string; control: E... Remove this comment to see the full error message
          variant="body1"
          // @ts-expect-error TS(2322): Type '{ fontSize: string; color: "primary" | "seco... Remove this comment to see the full error message
          control={<MuiCheckbox color={color} {...props} fontSize="small" />}
          required={required}
          sx={{
            '& .MuiFormControlLabel-asterisk': {
              color: '#f95f53',
              fontSize: '1.3333333333rem',
              lineHeight: '1.3333333333rem',
              fontStyle: 'italic',
            },
          }}
        />
        {props.errorMessage && (
          <FormHelperText>{props.errorMessage}</FormHelperText>
        )}
      </FormControl>
    );
  }

  // @ts-expect-error TS(2322): Type '{ color: "primary" | "secondary"; fontSize: ... Remove this comment to see the full error message
  return <MuiCheckbox color={color} fontSize="small" {...props} />;
};

CheckBox.defaultProps = {
  disableRipple: true,
  formLabelProps: {},
};
export default CheckBox;
