import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/react';
import * as Yup from 'yup';
import _ from 'lodash';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
// mui
import { FormHelperText, Typography } from '@mui/material';
// components
import { useRecaptcha } from '../../hooks';

import withRouter from '../../hocs/withRouter';
import { TextFieldBase } from '../../inputs/TextFieldBase';
import { withFormController } from '../../hocs/forms';
import CbButton from '../../Buttons/CbButton';
import NextPublicWrapper from '../NextPublicWrapper';
// actions
import { requestPasswordReset } from '../../../api/SignupService';
import { useToggleModal } from '../../../utils/modal.utils';
import { manageAPIError } from '../../../utils';

const TextField = withFormController(TextFieldBase);

const schema = Yup.object().shape({
  email: Yup.string().email().label('Email').required(),
});

interface Props {
  location: History;
}

interface FormValues {
  email: string;
}

const NewForgotPassword = withRouter(({ location }: Props) => {
  const toggleModal = useToggleModal();

  const [error, setError] = React.useState<string | null>('');
  const [mfa, setMfa] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  const {
    recaptchaError,
    token,
    resetToken,
    executeChallenge,
    getElementProps,
    ...recaptcha
  } = useRecaptcha();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (!_.isUndefined(location.state)) {
      const { username, password } = location.state;
      setMfa(true);
      toggleModal.direct(
        'ResetMfaConfirm',
        { username, password },
        {
          title: 'Reset Multi Factor Authentication',
          icon: 'Refresh',
          showClose: false,
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: FormValues) => {
    // ensure captcha challenge is executed if it has not yet
    if (token === null && recaptcha.exception === null) {
      executeChallenge();
      return;
    }

    if (typeof token === 'string') {
      return requestPasswordReset(data.email, token)
        .then(() => {
          setRedirect(true);
        })
        .catch((e: any) => {
          setRedirect(false);
          resetToken();

          const errorMessage = manageAPIError(
            e,
            'Not able to reset password at this time. Please try again after sometime.'
          );

          setError(errorMessage);
          Sentry.captureException(e, {
            extra: {
              email: data.email,
              token,
            },
          });
        });
    }

    setDisable(true);
    setError(
      'Cannot reset password at this time. Please try again after sometime.'
    );

    Sentry.captureException(recaptcha.exception, {
      extra: {
        email: data.email,
        token,
      },
    });
  };

  return (
    <NextPublicWrapper>
      {!redirect && !mfa && (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            <TextField
              name="email"
              type="email"
              label="Email"
              required
              placeholder="Please enter email address"
            />
            <FormHelperText style={{ maxWidth: '20rem' }}>
              {error || recaptchaError}
            </FormHelperText>
            <div {...getElementProps()} />
            <div>
              <CbButton
                disabled={
                  formMethods.formState.isSubmitting ||
                  !!recaptchaError ||
                  disable
                }
                loading={formMethods.formState.isSubmitting}
                type="submit"
                styleName="ctaButton"
                buttonText="Reset Password"
                size="large"
                onMouseEnter={executeChallenge}
              />
            </div>
          </form>
        </FormProvider>
      )}

      {mfa && (
        <Typography>
          An email has been sent to you. <br /> Please follow the instructions
          to reset your MFA.
        </Typography>
      )}
      {redirect && (
        <Typography>
          An email has been sent to you. <br /> Please follow the instructions
          to reset your password.
        </Typography>
      )}
      <p>
        Login to your account?{' '}
        <Link
          to="/login"
          style={{ color: '#fff', textDecoration: 'underline' }}
        >
          Login Here!
        </Link>
      </p>
    </NextPublicWrapper>
  );
});

export default NewForgotPassword;
