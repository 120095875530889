import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CheckBoxSelectedIcon(props: any) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 30 30" {...props}>
      <g transform="translate(13318 15799)">
        <path
          d="M0,0H24V24H0Z"
          transform="translate(-13318 -15799)"
          fill="none"
        />
        <g transform="translate(-13384.5 -16003)">
          <path
            d="M16.2,12.265V18.2H1.8V3.8H15.427c.761-.615,1.561-1.207,2.422-1.8H0V20H18V9.642c-.59.8-1.186,1.669-1.8,2.623Z"
            transform="translate(69 205)"
            fill="#2180e3"
          />
          <path
            d="M6.85,9.28,8.275,7.962a27.4,27.4,0,0,1,4.6,2.757A39.836,39.836,0,0,1,23.055,2l.463,1.067C19.949,6.181,17.335,9.65,13.572,16.4A71.409,71.409,0,0,0,6.85,9.28Z"
            transform="translate(66.082 205)"
            fill="#6ac52a"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
