import React from 'react';
import slugify from 'slugify';
import { TextField as MuiTextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

export const TextFieldBase = ({
  containerClass = '',
  label = '',
  error = '',
  showErrorPlace = true,
  fullWidth,
  helperText = '',
  required = false,
  show = true,
  showErrorMessage = true,
  inputProps = {},
  labelProps = {},
  'data-testid': dataTestID = '',
  ...props
}: any) => {
  const classes = useStyles(showErrorPlace);
  if (!show) {
    return null;
  }

  const id = props.id || props.name || `text-field-${slugify(label)}`;
  const testingAttributes = dataTestID ? { 'data-testid': dataTestID } : {};

  return (
    <div
      className={classnames(classes.container, containerClass)}
      style={{ width: fullWidth ? '100%' : 'auto' }}
    >
      {label && (
        <label className={classes.label} htmlFor={id} {...labelProps}>
          {label}
          {required && <span className={classes.requiredIndicator}>*</span>}
        </label>
      )}
      <div style={{ position: 'relative', width: fullWidth ? '100%' : 'auto' }}>
        <MuiTextField
          variant="standard"
          classes={{ root: classes.textFieldRoot }}
          id={id}
          error={Boolean(error)}
          {...props}
          helperText={undefined}
          inputProps={{
            'data-qa': `TEXT_INPUT:${props.name}`,
            ...testingAttributes,
            ...inputProps,
            error: Boolean(error),
          }}
        />
        {error && showErrorMessage && (
          <span className={classes.errorText}>{error}</span>
        )}
        {!error && showErrorPlace && (
          <span className={classes.noErrorText} aria-hidden>
            error ph
          </span>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ palette, config }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  label: {
    fontSize: config.textSizes.normal,
    fontFamily: config.fonts.stackSans,
    lineHeight: '1.3333333333rem',
    marginLeft: '0.8333333333rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    color: palette.text.text8,
  },
  textFieldRoot: {
    padding: 0,
    width: 'inherit',
    display: 'flex',
  },

  requiredIndicator: {
    color: '#f95f53',
    fontSize: '1.3333333333rem',
    lineHeight: '1.3333333333rem',
  },

  errorText: {
    visibility: 'visible',
    color: palette.error.dark,
    lineHeight: '1.66',
  },

  noErrorText: {
    visibility: 'hidden',
  },
}));
