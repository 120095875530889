import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useSnackbar } from 'notistack';
// components
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import * as Yup from 'yup';
import { DialogContent, DialogActions, Grid } from '@mui/material';

import { withStyles } from '@mui/styles';
import { withFormController } from '../hocs/forms';
import CBButton from '../Buttons/CbButton';

import { delayedEvent } from '../../utils/eventUtils';
import { updateBrokerFee } from '../../inbox/QuotesService';
import { additionalBrokerFee } from '../../utils/validationSchemas/accounts.schema';
import { useToggleModal } from '../../utils/modal.utils';
import LanguageCurrencyFieldBase from '../inputs/LanguageCurrencyField';
// helpers
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);
const schema = Yup.object().shape({
  brokerFee: Yup.string().required('Broker Fee is required'),
  additionalBrokerFee,
});

const EditBrokerFee = ({ classes, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const defaultValues = {
    brokerFee: _.get(data, 'brokerFee', '0'),
    additionalBrokerFee: _.get(data, 'additionalBrokerFee', '0'),
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { isDirty, isSubmitting, errors },
  } = methods;

  const onSubmit = (formData: any) => {
    const payload = {
      brokerFee: Number(formData.brokerFee.replace(/[^0-9.-]+/g, '')),
      additionalBrokerFee:
        typeof formData.additionalBrokerFee === 'number'
          ? Number(formData.additionalBrokerFee)
          : Number(formData.additionalBrokerFee.replace(/[^0-9.-]+/g, '')),
      product: data.product,
    };

    return updateBrokerFee(data.id, payload)
      .then(() => {
        toggleModal.direct('EditBrokerFee', false);
        enqueueSnackbar('Broker Fee updated Successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'uwInbox');
        delayedEvent('table-refetch', 500, 'adminQuotes');
      })
      .catch(console.error.bind(console));
  };

  const handleCancel = () => {
    toggleModal.direct('EditBrokerFee', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormProvider {...methods} handleSubmit={handleSubmit}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={12}>
                <LanguageCurrencyField
                  name="brokerFee"
                  label="Broker Fee"
                  error={_.get(errors, 'brokerFee.message')}
                  required
                />
              </Grid>
              <Grid item md={12}>
                <LanguageCurrencyField
                  name="additionalBrokerFee"
                  label="Additional Broker Fee"
                  error={_.get(errors, 'additionalBrokerFee.message')}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !isDirty}
              styleName="ctaButton"
              buttonText="Save"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
};

export default withStyles(styles)(EditBrokerFee);
