import { FormProvider, useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';

export const UWCancelConfirmation = ({ data, ...props }: any) => {
  const { classes } = data;
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = (formData: any) => {
    const { history } = data;
    toggleModalDirect('UWCancelConfirmation', false);
    return history.push('/admin/inbox');
  };

  const handleCancel = () => {
    toggleModalDirect('UWCancelConfirmation', false);
  };

  return (
    <section>
      {/* @ts-expect-error: FIXME */}
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.description}>
            Are you sure you want to cancel and go back to Inbox?
            <br />
            All your changes/updates to the quote will be lost.
          </p>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Stay Here
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Go to Inbox"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};
