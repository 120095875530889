import React, { Component } from 'react';
import { DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = ({ config }: any) => {
  return {
    innerTitle: {
      fontSize: config.textSizes.primer,
      fontWeight: 600,
      margin: '0px 0px 10px 0px',
    },
  };
};

class SocialEnggReasons extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { classes, data } = this.props;

    return (
      <DialogContent>
        <div className="modal-title" style={{ textAlign: 'left' }}>
          <p className={classes.innerTitle}>
            To add Social Engineering endorsement to this policy, the insured
            will need to:
          </p>
          {data.reason.map((value: any) => {
            return (
              <>
                {value === 'isVerifyingBankAccounts' && (
                  <li>
                    Verify vendor/supplier bank accounts before adding to your
                    accounts payable systems
                  </li>
                )}
                {value === 'isAuthenticatingFundsTransferRequest' && (
                  <li style={{ maxWidth: 650 }}>
                    Authenticate any funds transfer requests (e.g. by calling a
                    customer to verify the request at a predetermined phone
                    number
                  </li>
                )}
                {value === 'isPreventingUnauthorizedWireTransfers' && (
                  <li>
                    Prevent unauthorized employees from initiating wire
                    transfers
                  </li>
                )}
              </>
            );
          })}
        </div>
      </DialogContent>
    );
  }
}

export default withStyles(styles)(SocialEnggReasons);
