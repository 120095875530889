import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { DialogContent, FormHelperText } from '@mui/material';
import { deleteWebHookById } from './WebHookService';
import { PubSub } from '../utils/eventUtils';
import { useToggleModal } from '../utils/modal.utils';
import { REDACTED_FIELD } from '../config/logrocket';

const WebHookDeleteConfirm = ({ data: { id }, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const [error, setError] = useState('');

  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData: any) => {
      if (eventData) {
        handleSubmit();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteWebHookById({ id })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        PubSub.publish('table-refetch', 'webhooks');
        toggleModal.direct('WebHookDeleteConfirm', false);
        enqueueSnackbar(`WebHook Removed Successfully!`, {
          variant: 'success',
        });
      })
      .catch((error: any) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        setError(error.response.data);
      });
  };

  return (
    <DialogContent>
      <div className="modal-title">
        Are you sure you want to remove the WebHook for
        <span className={`modal-blue-text ${REDACTED_FIELD}`}>
          {' '}
          {data.companyName}
        </span>
        ?
      </div>
      <FormHelperText className="api-text-error">{error}</FormHelperText>
    </DialogContent>
  );
};

export default WebHookDeleteConfirm;
