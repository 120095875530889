import React from 'react';
import { SvgIcon } from '@mui/material';

export default function SpotlightIcon({ style, ...props }: any) {
  return (
    <SvgIcon
      id="Component_201_12"
      data-name="Component 201 – 12"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: props.color || '#FFF36A', ...(style || {}) }}
      {...props}
    >
      <g
        id="Group_11680"
        data-name="Group 11680"
        transform="translate(2.356 -1.891)"
      >
        <path
          id="Path_6987"
          data-name="Path 6987"
          className="cls-1"
          d="M1.8.9.954 7.629h6.618L6.724.9H1.8M1.421 0h5.684a.488.488 0 0 1 .474.5l.947 7.523a.488.488 0 0 1-.474.5H.474A.488.488 0 0 1 0 8.025L.947.5a.488.488 0 0 1 .474-.5z"
          transform="rotate(-45 12.603 5.22)"
        />
        <path
          id="Rectangle_4924"
          data-name="Rectangle 4924"
          className="cls-1"
          d="M.9.9v.449h3.139V.9H.9M.449 0h4.039a.449.449 0 0 1 .449.449V1.8a.449.449 0 0 1-.449.449H.449A.449.449 0 0 1 0 1.8V.449A.449.449 0 0 1 .449 0z"
          transform="rotate(-45 10.08 3.727)"
        />
        <path
          id="Ellipse_117"
          data-name="Ellipse 117"
          className="cls-1"
          d="M2.019.9a1.122 1.122 0 1 0 1.122 1.119A1.123 1.123 0 0 0 2.019.9m0-.9A2.019 2.019 0 1 1 0 2.019 2.019 2.019 0 0 1 2.019 0z"
          transform="rotate(-45 13.649 1.615)"
        />
        <path
          id="Line_18"
          data-name="Line 18"
          className="cls-1"
          d="M-.551 4.385a.447.447 0 0 1-.317-.131.449.449 0 0 1 0-.635L3.619-.869a.449.449 0 0 1 .635 0 .449.449 0 0 1 0 .635L-.234 4.254a.447.447 0 0 1-.317.131z"
          transform="translate(5.787 10.424)"
        />
        <path
          id="Line_19"
          data-name="Line 19"
          className="cls-1"
          d="M.449 6.283A.449.449 0 0 1 0 5.834V.449a.449.449 0 1 1 .9 0v5.385a.449.449 0 0 1-.451.449z"
          transform="rotate(-45 22.436 -4.246)"
        />
        <path
          id="Line_20"
          data-name="Line 20"
          className="cls-1"
          d="M1.8 3.141a.448.448 0 0 1-.385-.218L.064.68a.449.449 0 1 1 .77-.462L2.18 2.462a.449.449 0 0 1-.384.68z"
          transform="rotate(-45 21.313 -6.954)"
        />
        <path
          id="Line_21"
          data-name="Line 21"
          className="cls-1"
          d="M.448 3.141a.449.449 0 0 1-.384-.68L1.41.218a.449.449 0 0 1 .77.462L.834 2.923a.449.449 0 0 1-.386.218z"
          transform="rotate(-45 24.23 .088)"
        />
      </g>
      <path
        id="Rectangle_4924-2"
        data-name="Rectangle 4924"
        style={{ fill: 'none' }}
        d="M0 0h20v20H0z"
      />
    </SvgIcon>
  );
}
