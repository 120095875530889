import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

// components
import { makeStyles } from '@mui/styles';
import {
  TypeAheadBase,
  RegisteredTypeAhead,
} from '../../components/inputs/autocomplete';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
import { ManagedTypeAheadBase } from '../../components/inputs';
import { ManagedFetchingAutoComplete } from '../../components/inputs/autocomplete/ManagedFetchingAutoComplete';

// services
import {
  getTeamsForAgency,
  getMyTeams,
  getTeamsForAgentInAgency,
} from '../../teams/TeamsService';

const defaultLabelProps = {
  // style: { padding: 0, fontSize: '1rem' },
};

export const TeamsLookupField = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'show' does not exist on type '{}'.
    show = true,
    // @ts-expect-error TS(2339): Property 'defaultOptions' does not exist on type '... Remove this comment to see the full error message
    defaultOptions,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'labelProps' does not exist on type '{}'.
    labelProps = defaultLabelProps,
    // @ts-expect-error TS(2339): Property 'agencyId' does not exist on type '{}'.
    agencyId,
    ...props
  }) => {
    if (!show) {
      return null;
    }
    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue: any) {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(
      ({ input }: any, callback: any) => {
        getTeamsForAgency({ agencyId, search: input }).then(({ data }: any) => {
          callback(
            data.content.map((team: any) => ({
              label: `${team.name}`,
              value: team.id,

              meta: {
                ...team,
              },
            }))
          );
        });
      },
      [agencyId]
    );

    function onOptionsChange(newOptions: any) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
          htmlFor={props.name}
          // @ts-expect-error TS(2339): Property 'required' does not exist on type '{}'.
          required={props.required}
          {...labelProps}
        >
          {label || 'Team'}
        </InputLabelBase>
        <TypeAheadBase
          id="teams"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          {...props}
        />
      </>
    );
  }
);

export const ManagedTeamsLookupField = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'show' does not exist on type '{}'.
    show = true,
    // @ts-expect-error TS(2339): Property 'defaultOptions' does not exist on type '... Remove this comment to see the full error message
    defaultOptions,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'labelProps' does not exist on type '{}'.
    labelProps = defaultLabelProps,
    // @ts-expect-error TS(2339): Property 'agencyId' does not exist on type '{}'.
    agencyId,
    ...props
  }) => {
    if (!show) {
      return null;
    }

    const classes = useStyles();

    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const onFetch = React.useCallback(
      _.debounce(({ input }, callback) => {
        getTeamsForAgency({ agencyId, search: input }).then(({ data }: any) => {
          callback(
            data.content.map((team: any) => ({
              label: `${team.name}`,
              value: team.id,

              meta: {
                ...team,
              },
            }))
          );
        });
      }, 500),
      [agencyId]
    );

    return (
      <ManagedTypeAheadBase
        label={label}
        onFetch={onFetch}
        className={inputClasses}
        {...props}
      />
    );
  }
);

export const ManagedTeamByAgentAutocomplete = ({ agentId, ...props }: any) => {
  const queryKey = ['agent-teams-options', agentId];
  function fetchFuncton({ search }: any) {
    if (!agentId) {
      return () => Promise.resolve([]);
    }

    return getTeamsForAgentInAgency({ userId: agentId, search }).then(
      (res: any) => res.data.content
    );
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};

export const ManagedTeamByAgencyAutocomplete = ({
  agencyId,
  ...props
}: any) => {
  const queryKey = ['agency-teams-options', agencyId];
  function fetchFuncton({ search }: any) {
    if (!agencyId) {
      return () => Promise.resolve([]);
    }

    return getTeamsForAgency({ agencyId, search }).then(
      (res: any) => res.data.content
    );
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFuncton}
    />
  );
};

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

export const RegisteredTeamAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  ...props
}: any) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }: any, callback: any) => {
    return getMyTeams({ search: input }).then((response: any) =>
      callback(
        response.data.content.map((team: any) => ({
          label: `${team.name}`,
          value: team.id,

          meta: {
            ...team,
          },
        }))
      )
    );
  }, []);

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || 'Team'}
      </InputLabelBase>
      <RegisteredTypeAhead
        id="teams"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

function filterOptions(options: any) {
  return options;
}
