import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import { Api } from '../../config/axiosConfig';
import { accountServiceMappings } from '../../accounts/accountServiceMappings';

export const fetchClusters = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/cluster/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

export const createCluster = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/cluster',
    method: 'post',
  },
});

export const updateCluster = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/cluster/:id',
    method: 'put',
  },
});

export const deleteCluster = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/cluster/:id',
    method: 'delete',
  },
});
export function enterCluster(id: any) {
  return Api.post(`/api/auth/v1/cluster/${id}/enter`);
}
export const getClusterDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/cluster`,
  },
  isCancelable: true,
});
export const setClusterDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/cluster',
    method: 'put',
  },
  isCancelable: true,
});

export function removeAgency(agencyId: any) {
  return Api.put(`/api/account/v1/cluster/remove/agency/${agencyId}`);
}
