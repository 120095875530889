import { trimAndAddAsterisk } from '../utility/QueryUtility';

export const fetchStateParamsMapping = [
  { path: 'page' },
  { path: 'size' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      switch (orderBy) {
        case 'isSupported':
          if (orderBy && order) {
            return `${orderBy},${order}`;
          }
          break;
        default:
          if (orderBy && order) {
            return `${orderBy}.keyword,${order}`;
          }
      }

      return null;
    },
  },
  {
    path: 'search',
    transform: ({ search }: any) =>
      search ? trimAndAddAsterisk(search) : null,
  },
];
