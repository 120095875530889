import React from 'react';
// components
import { DialogContent, DialogActions } from '@mui/material';
import { withStyles } from '@mui/styles';
import CheckBoxBase from '../inputs/Checkbox';

import { PubSub } from '../../utils/eventUtils';
import { acceptPrivacyConsent } from '../../accounts/AccountService';
import CBButton from '../Buttons/CbButton';
import { useToggleModal } from '../../utils/modal.utils';

const PrivacyConsent = ({ classes, data }: any) => {
  const [agreement, setAgreement] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const toggleModal = useToggleModal();

  const onSubmit = () => {
    setIsSubmitting(true);
    const payload = {
      agreement,
    };

    const { connectorType, connectorName } = data.data;
    return acceptPrivacyConsent({ connectorType }, payload)
      .then(() => {
        toggleModal.direct('PrivacyConsent', false);
        PubSub.publish('privacyConsent', { connectorType, connectorName });
      })
      .catch(console.error.bind(console))
      .finally(() => setIsSubmitting(false));
  };

  const handleCancel = () => {
    toggleModal.direct('PrivacyConsent', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <DialogContent classes={{ root: classes.container }}>
        <section className={classes.privacyText}>
          <p>
            This addendum (‘Addendum’) to Cowbell’s Terms of Service and Privacy
            Policy applies to all users of the Cowbell Service that opt to use
            the Cowbell Connector functionality (as further defined below) and
            shall be read in conjunction with our Terms of Service and Privacy
            Policy (the “Service Documentation”). In case of any conflict
            between the Service Documentation and this Addendum, this Addendum
            shall prevail. Except as otherwise defined, each capitalized term in
            this Addendum shall have the meaning given such term in the Service
            Documentation.
          </p>
          <p>
            1. Cowbell Connectors. You may choose to connect certain
            cybersecurity systems to the Service in order to better utilize our
            Service, including third-party websites and applications such as AWS
            Security Hub, Google Security Command Center, and Microsoft Secure
            Score (each a “Third Party System”). You control which Third Party
            Systems to connect with the Cowbell Service.
          </p>
          <p>
            2. Data Collection. If you choose to sync a Third Party System with
            your Cowbell account, we will receive information from that Third
            Party System according to your settings with that Third Party System
            (“Account Information”). For example, if you connect AWS Security
            Hub to your Cowbell account, we may receive details of your
            business’s security alerts, or findings, from multiple AWS services.
            Cowbell does not review the Account Information for accuracy,
            legality or non-infringement. Cowbell is not responsible for the
            Account Information or products and services offered by or on
            third-party sites. Cowbell cannot always foresee or anticipate
            technical or other difficulties which may result in failure to
            obtain data or loss of data, personalization settings or other
            service interruptions. Cowbell cannot assume responsibility for the
            timeliness, accuracy, deletion, non-delivery or failure to store any
            user data, communications or personalization settings. Except as set
            forth herein, access to Account Information is restricted to Cowbell
            and its third party service providers, such as vendors and
            consultants only, and no other user of the Cowbell Service will have
            access to your Account Information. You agree that we may use and
            analyze the Account Information as follows: (a) present you an
            overview of improved risk ratings and remediation guidance; (b)
            provide you with customized recommendations and general information
            about you; and (c) provide you with the Service. Cowbell may also
            aggregate and evaluate the Account Information to do statistical
            analysis to provide historical or comparative information and to
            improve Cowbell products and services.
          </p>
          <p>
            3. Credentials. By submitting passwords, usernames, PINs, and other
            log-in information for Third Party Systems to Cowbell through the
            Service, you are licensing that content to Cowbell for the purpose
            of providing the Service. Cowbell may use and store the content in
            accordance with the Service Documentation. You represent that you
            are entitled to submit it to Cowbell for use for this purpose,
            without any obligation by Cowbell to pay any fees or be subject to
            any restrictions or limitations. By using the Service, you expressly
            authorize Cowbell to access your Account Information maintained by
            Third Party Systems, on your behalf as your agent, and you expressly
            authorize such third parties to disclose your information to us.
          </p>
          <p>
            4. Privacy. When connecting with a Third Party System, we do not
            anticipate that any PII will be collected from such Third Party
            System. We do not control any of the content from Third Party
            Systems and we bear no responsibility or obligation to monitor such
            Account Information to determine if it contains PII. For more
            information about the data shared from Third Party Systems, you
            should refer to those sites’ privacy and data/information sharing
            statements/policies.
          </p>
          <p>
            5. Deletion. Once you delete a connector for a Third Party System
            from your Cowbell account, Cowbell will delete all Account
            Information accessed and collected from that Third Party System.
            Purge of any backup data may take up to 90 days.
          </p>
        </section>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CheckBoxBase
            name="agreement"
            onChange={() => setAgreement((prevState) => !prevState)}
            label="I have read and accept Cowbell’s privacy policy addendum for the use of connectors"
          />
        </section>
      </DialogContent>

      <DialogActions>
        <CBButton action={handleCancel} styleName="cancel">
          Cancel
        </CBButton>
        <CBButton
          type="submit"
          onClick={onSubmit}
          disabled={!agreement || isSubmitting}
          styleName="ctaButton"
          buttonText="Accept"
        />
      </DialogActions>
    </section>
  );
};

const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  privacyText: {
    maxHeight: '35rem',
    marginBottom: 20,
    overflow: 'auto',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
};

export default withStyles(styles)(PrivacyConsent);
