import { makeStyles } from '@mui/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import CbButton from '../Buttons/CbButton';
import { SimpleMenu } from './SimpleMenu';

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    border: 'none',
    background: palette.background.paper,
  },
  menuItem: {
    paddingLeft: '1rem',
    color: palette.text.primary,
    '&:hover': {
      background: 'initial',
      color: palette.text.contrastText,
      fontWeight: 'initial',
    },
  },
}));

export const ButtonMenu = ({ children, label, ButtonProps, ...props }: any) => {
  const classes = useStyles();

  return (
    <SimpleMenu
      anchor={
        <CbButton
          styleName="primary"
          buttonText={label}
          endIcon={<KeyboardArrowDown />}
          {...ButtonProps}
        />
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: -45,
        horizontal: 'center',
      }}
      classes={classes}
      {...props}
    >
      {children}
    </SimpleMenu>
  );
};
