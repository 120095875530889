import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { PubSub } from '../../utils/eventUtils';

import { RequestDeleteUser } from '../../api/apis';
import { manageAPIError } from '../../utils';
import { withToggleModal } from '../hocs/withToggleModal';
import { REDACTED_FIELD } from '../../config/logrocket';

class UsersDeleteConfirm extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      accountsCount: 0,
      id: '',
      admin: false,
      customer: false,
      error: '',
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      props: { data },
    } = this;

    this.setState({
      email: data.email,
      accountsCount: data.accountsCount,
      id: data.id,
    });
    const admins = [
      'Super Admin',
      'Corp Admin',
      'Agency Admin',
      'Power User',
      'Customer Success',
      'Principal Producer',
      'Customer Service',
    ];
    const customer = ['Account Admin', 'Risk Manager', 'Policy Holder'];
    if (admins.includes(data.role.name)) {
      this.setState({ admin: true });
    } else if (customer.includes(data.role.name)) {
      this.setState({ customer: true });
    }
    this.subscription = PubSub.subscribe(
      'foot-button-one',
      (eventData: any) => {
        if (eventData) {
          this.handleSubmit();
        }
      }
    );
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Readonly<{}>... Remove this comment to see the full error message
    RequestDeleteUser(this.state.id)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('UsersDeleteConfirm', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar('User Removed Successfully!', {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'users');
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        const errorMessage = manageAPIError(
          error,
          'Failed to request user deletion. Please try again'
        );

        this.setState({ error: errorMessage });
      });
  };

  render() {
    return (
      <DialogContent>
        <form id="submit-form">
          {/* @ts-expect-error TS(2339): Property 'customer' does not exist on type 'Readon... Remove this comment to see the full error message */}
          {this.state.customer ? (
            <div className={`modal-title ${REDACTED_FIELD}`}>
              Are you sure you want to remove the user
              {/* @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
              <span className="modal-blue-text"> {this.state.email}</span> ?
            </div>
          ) : (
            <div className={`modal-title ${REDACTED_FIELD}`}>
              The user{' '}
              {/* @ts-expect-error TS(2339): Property 'email' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
              <span className="modal-blue-text"> {this.state.email}</span> has
              access to
              <span className="modal-blue-text">
                {' '}
                {/* @ts-expect-error TS(2339): Property 'admin' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
                {this.state.admin ? 'All' : this.state.accountsCount}{' '}
              </span>{' '}
              account(s), <br />
              Are you sure you want to remove them?
            </div>
          )}
          <FormHelperText className="api-text-error">
            {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
            {this.state.error}
          </FormHelperText>
        </form>
      </DialogContent>
    );
  }
}

export default compose(withSnackbar, withToggleModal)(UsersDeleteConfirm);
