import React from 'react';
import classnames from 'classnames';
import { Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const TickedSlider = ({ locked, mandatory, ...props }: any) => {
  const classes = useStyles();
  const className = classnames({
    locked,
    mandatory,
  });

  return (
    <Slider
      className={className}
      classes={classes}
      valueLabelDisplay="on"
      marks
      {...props}
    />
  );
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.ticker.barcolor,
    height: 2,
    padding: '15px 0',
    marginBottom: 0,
  },

  disabled: {
    '&.MuiSlider-root': {
      color: palette.ticker.barcolor,
    },

    '&.MuiSlider-thumb': {
      height: 10,
      width: 10,
      backgroundColor: `${palette.primary.oldBorder} !important`,
    },

    '&.MuiSlider-valueLabel': {
      display: 'none',
      visibility: 'hidden',
    },

    '&.locked .MuiSlider-valueLabel': {
      display: 'block',
    },

    '&.mandatory .MuiSlider-thumb': {
      backgroundColor: `${palette.primary.oldBorder} !important`,
    },

    '&.mandatory .MuiSlider-valueLabel': {
      display: 'flex',
    },
  },

  thumb: {
    height: 12,
    width: 12,
    marginTop: -1,
    marginLeft: -2,
    position: 'relative',
    zIndex: 1,
    backgroundColor: `${palette.common.cowbellGreen2} !important`,

    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    },

    '.mandatory &': {
      backgroundColor: `${palette.common.cowbellBlue} !important`,
    },
  },

  active: {},
  valueLabel: {
    left: 'unset',
    right: '100%',
    background: 'transparent',
    fontSize: '1rem',
    color: palette.text.contrastText,

    '& > span': {
      transform: 'translateY(165%)',
      background: 'transparent',
    },

    '& > span > span': {
      padding: 2,
      background: palette.background.paper,
      transform: 'translateX(-0.5rem)',
    },

    '.even & > span > span': {
      background: palette.background.default,
    },
  },
  track: {
    height: 2,
    opacity: 0.5,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: 'rgba(68, 68, 73, 0.35)',
  },
  mark: {
    backgroundColor: '#444449',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    display: 'none',
  },
}));
