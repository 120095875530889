import { commonDateParamMappings } from '../../../api/common/mappings';
import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const getAllNotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/note/account/all/:accountId',
  },
  isCancelable: false,
  paramSchema: [
    ...commonDateParamMappings,
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
  ],
});

export const getAllQuoteNotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/note/quote/:quoteId',
  },
  isCancelable: false,
  paramSchema: [
    ...commonDateParamMappings,
    { path: 'page' },
    { path: 'size' },
    {
      path: 'sort',
      transform: ({ orderBy, order }: any) => {
        return `${orderBy},${order}`;
      },
    },
    { path: 'search' },
  ],
});

export const getNoteById = ServiceHandlerFactory({
  axios: {
    url: `/api/quote/v1/note/:noteId`,
  },
  isCancelable: false,
});

export const createNote = ServiceHandlerFactory({
  axios: {
    url: `/api/quote/v1/note`,
    method: 'post',
  },
  isCancelable: false,
});

export const updateNoteById = ServiceHandlerFactory({
  axios: {
    url: `/api/quote/v1/note/:noteId`,
    method: 'put',
  },
  isCancelable: false,
});

export const deleteNote = ServiceHandlerFactory({
  axios: {
    url: `api/quote/v1/note/:noteId`,
    method: 'delete',
  },
  isCancelable: false,
});

export const getAllAccountNotes = ServiceHandlerFactory({
  axios: {
    url: `api/quote/v1/note/account/:accountId`,
  },
  isCancelable: false,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    {
      path: 'sort',
      transform: ({ orderBy, order }: any) => {
        return `${orderBy},${order}`;
      },
    },
    { path: 'search' },
    ...commonDateParamMappings,
  ],
});

export const createAccNote = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/note`,
    method: 'post',
  },
  isCancelable: false,
});
