import React from 'react';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';

import { withStyles } from '@mui/styles';

const CBAutocomplete = ({
  classes,
  placeholder,
  id,
  inputValue,
  options,
  getOptionLabel,
  onChangeHandler,
  label,
  onTextChangeHandler,
  onTextBlurHandler,
  required,
  loading,
  disabled,
  register,
  type = 'text',
  fullWidth,
  ...props
}: any) => {
  return (
    <section style={{ width: fullWidth ? '100%' : 'auto' }}>
      <div className={classes.label}>
        {label}
        {required ? (
          <span
            style={{ color: '#f95f53', fontSize: '16px', lineHeight: '16px' }}
          >
            *
          </span>
        ) : (
          ''
        )}
      </div>
      <div style={{ position: 'relative', width: fullWidth ? '100%' : 'auto' }}>
        <Autocomplete
          disabled={disabled}
          loading={loading}
          inputValue={inputValue}
          options={options}
          getOptionLabel={getOptionLabel}
          onChange={onChangeHandler}
          freeSolo
          {...props}
          classes={{
            root: classes.autoCompleteRoot,
            inputRoot: classes.inputRoot,
            input: classes.input,
            inputFocused: classes.inputFocused,
            popupIndicator: classes.popupIndicator,
            listbox: classes.listbox,
            clearIndicator: classes.clearIndicator,
            paper: classes.popper,
          }}
          style={{ width: '100%', margin: 'auto' }}
          renderInput={(rest) => {
            return (
              <MuiTextField
                placeholder={placeholder}
                {...rest}
                onChange={onTextChangeHandler}
                fullWidth
                onBlur={onTextBlurHandler}
                required
                name={rest.id}
                inputRef={register}
                type={type}
              />
            );
          }}
        />
      </div>
    </section>
  );
};

const styles = ({ palette, config }: any) => ({
  label: {
    paddingBottom: 10,
    fontSize: config.textSizes.normal,
    fontFamily: config.fonts.stackSans,
    lineHeight: '16px',
    marginLeft: '10px',
    color: palette.text.text8,
  },
  autoCompleteRoot: {
    padding: 0,
    border: 'none',
  },
  popupIndicator: {
    padding: 'unset',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  clearIndicator: {
    opacity: 0,
  },
  inputRoot: {
    padding: 0,
    borderRadius: 7,
    paddingRight: '0 !important',
  },
  input: {
    padding: '10px 40px 10px 10px !important',
    borderRadius: 5,
    border: `1px solid ${palette.primary.oldBorder}`,

    '&:focus-visible': {
      outline: 'none',
    },
  },
  listbox: {
    background: palette.background.default,
    border: `1px solid ${palette.primary.oldBorder}`,
    height: '22rem',
  },
});

// @ts-expect-error TS(2345): Argument of type '({ palette, config }: any) => { ... Remove this comment to see the full error message
export default withStyles(styles)(CBAutocomplete);
