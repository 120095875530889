/**
 * @description helper that creates and dispatches a custom event
 * @deprecated
 */
export const publishEvent = (eventName: any, detail = {}, $elem = window) => {
  if (typeof eventName !== 'string') {
    throw new Error('publishEvent: eventName is required');
  }

  // create new event
  const _event = new CustomEvent(eventName, { detail });

  // dispatch event
  $elem.dispatchEvent(_event);
};

/**
 * @deprecated
 */
export const publishDelayedEvent = (
  eventName: any,
  detail = {},
  $elem = window
) => {
  if (typeof eventName !== 'string') {
    throw new Error('publishEvent: eventName is required');
  }

  // create new event
  const _event = new CustomEvent(eventName, { detail });

  // dispatch event
  setTimeout(() => {
    $elem.dispatchEvent(_event);
  }, 500);
};

export const delayedEvent = (event = '', delay = 500, data: any) => {
  setTimeout(() => {
    PubSub.publish(event, data);
  }, delay);
};

/**
 * Do not mess with this module
 * This module is generic and flexible, it can be extended without touching it
 * please see @trevor for details
 */
export class PubSubModule {
  topics = {};

  /**
   * subscribe
   * @param topic {string}
   * @param listener {function}
   */
  subscribe(topic: any, listener: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // create the topic
    if (!this.topics.hasOwnProperty.call(this.topics, topic)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.topics[topic] = [];
    }

    // add the listener to queue
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const index = this.topics[topic].push(listener) - 1;

    // provide handle back for removal of topic
    return {
      remove() {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete self.topics[topic][index];
      },
    };
  }

  /**
   * publish
   * @param topic {string}
   * @param data {any - default object}
   */
  publish(topic: any, data: any) {
    // if the topic doesn't exist bail
    if (!this.topics.hasOwnProperty.call(this.topics, topic)) {
      return;
    }

    // cycle through topics queue and fire!
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this.topics[topic].forEach((item: any) => {
      item(data);
    });
  }
}

export const PubSub = new PubSubModule();
