import React from 'react';
import { PubSub } from '../../../utils/eventUtils';

const initialModalState = {
  config: {},
  component: () => <></>,
};

export const withModalState = (DialogBase: any) => {
  return () => {
    const [isOpen, setOpen] = React.useState(false);
    const [modal, setModal] = React.useState(initialModalState);

    const handleClose = () => {
      setOpen(false);
      setTimeout(() => {
        setModal(initialModalState);
      }, 500);
    };

    React.useEffect(() => {
      PubSub.subscribe('modal:show', (nextModal: any) => {
        setOpen(true);
        setModal(nextModal);
      });
      PubSub.subscribe('modal:hide', handleClose);
    }, []);

    return (
      <DialogBase open={isOpen} onClose={handleClose} {...modal.config}>
        {React.createElement(modal.component, {
          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{ config: ... Remove this comment to see the full error message
          data: modal.data,
          close: handleClose,
        })}
      </DialogBase>
    );
  };
};
