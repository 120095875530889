import React from 'react';

// components
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const FieldError = ({
  children,
  name,
  error,
  errors = {},
  ...props
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      {children || error || errors[name]}
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0.416rem 0.416rem 0.416rem 0.916rem',
    color: palette.error.light,
  },
}));
