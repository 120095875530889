import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
// ui
// components
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import _ from 'lodash';
import * as Yup from 'yup';
import CBButton from '../Buttons/CbButton';

import { MultiSelect } from '../Selects';
import { withFormController } from '../hocs/forms';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { getDocTypes, uploadDocumentV2 } from '../../api/DocumentsService';
import { useFileTypeIcons } from './useFileTypeIcons';
import { delayedEvent } from '../../utils/eventUtils';
import { setUploadedDocs } from '../../console/_tabs/notesTab/notes.reducer';
import { fetchAccountDetailsById } from '../../accounts/AccountService';
import { useActor } from '../hooks/useActor';
import { useToggleModal } from '../../utils/modal.utils';

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  fileName: Yup.string().required().label('File name'),
  docType: Yup.string().required().label('Doc type'),
});

export const AgencyUploadDocumentModal = ({ data }: any) => {
  const toggleModal = useToggleModal();

  const { file, from, note, toggleIsAttachmentProcessing } = data;
  const { accountId, productType } = data.docsData ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const docs = useSelector(({ notes }) => notes.docs);

  const actor = useActor();

  const [docTypes, setDocTypes] = React.useState([]);
  const [interestedAgencies, setInterestedAgencies] = React.useState([]);

  React.useEffect(() => {
    let query = {};

    if (data.isClaimDocument) {
      query = { claim: true };
    }

    getDocTypes(query)
      .then((response: any) => {
        const array = _.map(response.data, (v, k) => {
          return {
            label: k,
            value: v,
          };
        });
        // @ts-expect-error TS(2345): Argument of type '{ label: string; value: any; }[]... Remove this comment to see the full error message
        setDocTypes(array);
      })
      .catch(() => {
        enqueueSnackbar('Failed to load the doc types!', { variant: 'error' });
      });

    if (accountId) {
      fetchAccountDetailsById(accountId)
        .then((resp: any) => {
          const agencies = _.get(resp, 'data.agencies', []);
          const agencyMapping = agencies.map((agency: any) => {
            return {
              label: agency.agencyName,
              value: agency.agencyId,
            };
          });
          setInterestedAgencies([
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            { label: 'All', value: undefined },
            // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
            ...agencyMapping,
          ]);
        })
        .catch(() => {
          enqueueSnackbar('Failed to load the account details!', {
            variant: 'error',
          });
        });
    }
  }, [accountId, data.isClaimDocument, enqueueSnackbar]);

  const fileDetails = React.useMemo(() => {
    let extension = '';
    let fileName = '';

    if (file) {
      const splitString = file?.name?.split('.');
      extension = splitString.pop();
      fileName = splitString.join('.');
    }

    return { extension, fileName };
  }, [file]);

  const renderFileIcon = useFileTypeIcons(fileDetails.extension);

  const defaultValues = {
    fileName: fileDetails.fileName,
    docType: docTypes[0],
  };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    register,
    formState: { isSubmitting },
    setValue,
    getValues,
  } = methods;
  const values = getValues();

  const classes = useClasses();

  const onSubmit = ({ docType, fileName, agencyId }: any) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const myAgencyId = agencyId || data.docsData.agencyId;
    const myAccountId = accountId || data.docsData.agencyId;

    if (typeof data.onSubmit === 'function') {
      toggleModal.direct('AgencyUploadDocumentModal', false);
      data.onSubmit(
        myAccountId,
        note,
        docType,
        fileName,
        myAgencyId,
        bodyFormData,
        toggleIsAttachmentProcessing
      );
    } else {
      return uploadDocumentV2(
        {
          accountId: myAccountId,
          docType,
          docName: fileName,
          temp: from === 'notes',
          agencyId: myAgencyId,
          productType,
        },
        bodyFormData
      )
        .then((resp: any) => {
          if (from === 'notes') {
            const finalAttachments = [...docs.attachments, resp.data];
            dispatch(setUploadedDocs({ attachments: finalAttachments }));
          }

          if (typeof data.refetchTable === 'function') {
            data.refetchTable();
          }
          enqueueSnackbar('File uploaded successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'UWDocuments');
          toggleModal.direct('AgencyUploadDocumentModal', false);
        })
        .catch(() => {
          enqueueSnackbar('Failed to upload the file!', { variant: 'error' });
          toggleModal.direct('AgencyUploadDocumentModal', false);
        });
    }
  };

  const handleCancel = () => {
    toggleModal.direct('AgencyUploadDocumentModal', false);
  };

  const handleSelectDocType = React.useCallback(
    (name: any, selectedOption: any) => {
      // @ts-expect-error TS(2345): Argument of type '"docType"' is not assignable to ... Remove this comment to see the full error message
      setValue('docType', selectedOption.value);
    },
    [setValue]
  );

  const handleSelectAgency = React.useCallback(
    (name: any, selectedOption: any) => {
      // @ts-expect-error TS(2345): Argument of type '"agencyId"' is not assignable to... Remove this comment to see the full error message
      setValue('agencyId', selectedOption.value);
    },
    [setValue]
  );

  return (
    <section className={classes.container}>
      {/* @ts-expect-error TS(2741): Property 'handleSubmit' is missing in type '{ chil... Remove this comment to see the full error message */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            {actor.isCowbell && accountId ? (
              <DialogContentText>
                If Select Agency field is left blank, document will be visible
                to all interested agencies.
              </DialogContentText>
            ) : (
              <p className={classes.description}>Please select doc type</p>
            )}
            <Grid container justifyContent="left">
              {actor.isCowbell && accountId && (
                <Grid item md={12}>
                  <Grid container justifyContent="left">
                    <Grid item md={4} className={classes.rowName}>
                      Select Agency:
                    </Grid>
                    <Grid item md={8}>
                      <MultiSelect
                        // @ts-expect-error TS(2345): Argument of type '"agencyId"' is not assignable to... Remove this comment to see the full error message
                        {...register('agencyId')}
                        required
                        label=""
                        options={interestedAgencies}
                        onChange={handleSelectAgency}
                        // @ts-expect-error TS(2339): Property 'agency' does not exist on type '{ fileNa... Remove this comment to see the full error message
                        values={values.agency}
                        isMulti={false}
                        data-qa="agencyId"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item md={12}>
                <Grid container justifyContent="left">
                  <Grid item md={4} className={classes.rowName}>
                    File Name:
                  </Grid>
                  <Grid item md={8}>
                    <TextField
                      name="fileName"
                      fullWidth
                      required
                      data-qa="fileName"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container justifyContent="left">
                  <Grid item md={4} className={classes.rowName}>
                    File Type:
                  </Grid>
                  <Grid item md={8}>
                    {renderFileIcon}
                  </Grid>
                  <span className={classes.spacer}>spacer</span>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container justifyContent="left">
                  <Grid item md={4} className={classes.rowName}>
                    Doc Type:
                  </Grid>
                  <Grid item md={8}>
                    <MultiSelect
                      // @ts-expect-error TS(2345): Argument of type '"docType"' is not assignable to ... Remove this comment to see the full error message
                      {...register('docType')}
                      required
                      label=""
                      options={docTypes}
                      onChange={handleSelectDocType}
                      values={values.docType}
                      isMulti={false}
                      data-qa="docType"
                      error={methods.formState.errors?.docType?.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Submit"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  rowName: {
    lineHeight: 1.38,
    padding: '0.5rem 0',
  },
  description: {
    fontSize: '1.167rem',
    color: palette.primary.main,
    lineHeight: 1.5,
    fontStyle: 'italic',
    textAlign: 'center',
    margin: '0 0 2rem 0 !important',
  },
  container: {
    fontSize: '1.33rem',
    color: palette.primary.main,
    flex: '1 1 auto',
    padding: '0 3rem 0.5rem 3rem',
    'overflow-y': 'visible',
  },
  spacer: {
    visibility: 'hidden',
  },
}));
