import { combineReducers } from 'redux';

// reducers
import auth from './AuthReducers';
import ux from './UxReducers';
import { uiSettings } from './UiSettingsReducers';
import { globalModalsReducer as modals } from './global-modals.reducer';
import { account } from './AccountReducers';
import { Coverages250Reducer as coverage250 } from './Coverages250Reducers';
import { prime100Reducer as prime100 } from '../console/_reducers/prime100.reducer';
import { notesReducer as notes } from '../console/_tabs/notesTab/notes.reducer';
import { TabsReducer as tabs } from '../console/_tabs/tabs.reducer';
import { scopesReducer as scopes } from './scopes.reducer';
// @ts-expect-error TS(2691): An import path cannot end with a '.ts' extension. ... Remove this comment to see the full error message
import { GlobalTeamReducer } from './team.reducer.ts';

import { userstore } from '../store/reducers/user.store';
import { userAccountReducer as userAccount } from '../store/reducers/user-account.store';
import { teamsStore } from '../store/reducers/teams.store';

const reducers = combineReducers({
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ isAuthenticated: bo... Remove this comment to see the full error message
  auth,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ hostname: string; n... Remove this comment to see the full error message
  account,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<any, any>' is not ass... Remove this comment to see the full error message
  user_account: userAccount,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<any, any>' is not ass... Remove this comment to see the full error message
  user: userstore,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<any, any>' is not ass... Remove this comment to see the full error message
  team: GlobalTeamReducer,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<any, any>' is not ass... Remove this comment to see the full error message
  teams: teamsStore,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<string[], string[]>' ... Remove this comment to see the full error message
  scopes,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ account: { companyN... Remove this comment to see the full error message
  tabs,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{}, {}>' is not assig... Remove this comment to see the full error message
  coverage250,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ ui: { additionalIns... Remove this comment to see the full error message
  prime100,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ docs: { attachments... Remove this comment to see the full error message
  notes,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{ _legacy_persona: nu... Remove this comment to see the full error message
  ux,
  uiSettings,
  // @ts-expect-error TS(2322): Type 'ReduxCompatibleReducer<{}, {}>' is not assig... Remove this comment to see the full error message
  modals,
});

export default reducers;
