import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import LogoHeader from '../../entry/LogoHeader';
import SocialMedia from '../../entry/SocialMedia';
import { usePlatformLanguage } from '../../../utils/hooks/usePlatformLanguage';
import { legal } from '../../../i18n/constants';

const styles = () => {
  return {
    modalTitle: {
      fontFamily:
        '"Titillium Web", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif', // config.fonts.stackSans,
      fontSize: '1.16rem', // config.textSizes.tertia,
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ffffff', // palette.text.text8,
    },
    header: {
      fontSize: '2.5rem', // _config.textSizes.nonpareil,
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    platform: {
      fontSize: '1.33rem', // _config.textSizes.primer,
      fontWeight: 'normal',
      color: '#c9cdd9', // config.colors.almostWhite,
      lineHeight: 1.22,
    },
    url: {
      margin: 0,
      fontSize: '1.33rem', // _config.textSizes.primer,
      fontWeight: 'normal',
      color: '#c9cdd9', // _config.colors.almostWhite,
      fontFamily: 'Arial',
      lineHeight: 1.11,
      display: 'flex',
      justifyContent: 'center',
    },
    href: {
      color: '#2180e2', // _config.colors.cowbellBlue,
      textDecoration: 'underline',
      margin: 0,
      paddingLeft: 5,
      cursor: 'pointer',
    },
    contact: {
      padding: '3rem',
      margin: 0,
      lineHeight: 1.22,
    },
    link: {
      padding: '3.33rem',
      cursor: 'pointer',
    },
    copy: {
      width: '2rem',
      height: '2rem',
      marginLeft: '0.833rem',
      marginTop: -8,
      cursor: 'pointer',
    },
    publicContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'inherit',
      color: '#c9cdd9',
      fontSize: '10px !important',
      '& a': {
        textDecoration: 'underline',
        color: '#c9cdd9',
        fontSize: '10px',
      },
      '& .public-logo': {
        marginBottom: 40,
        maxWidth: 400,
        width: 250,
      },
    },

    publicContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
    },
    publicFooter: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 2,
      marginTop: 'auto',
      bottom: '0',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 10,
    },

    termsContainer: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '10px', // config.textSizes.petite,

      '& > *': {
        margin: '0 10px',
      },
    },
    socialContainer: {
      fontSize: '10px', // config.textSizes.primer,
    },

    entry: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      justifyContent: 'center',
    },
  };
};
// @ts-expect-error TS(2345): Argument of type '() => { modalTitle: { fontFamily... Remove this comment to see the full error message
export const BrowserUnsupported = withStyles(styles)(({ classes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const region = usePlatformLanguage();

  return (
    <main className={classes.publicContentWrapper}>
      <div className={classes.publicContentContainer}>
        <LogoHeader />
        <div className={classes.modalTitle}>
          <p className={classes.header}>
            Please upgrade your browser for a full Cowbell platform experience!
          </p>

          <div className={classes.platform}>
            We built the Cowbell platform using the latest browser technologies
            for a fast and easy experience.
            <br />
            Unfortunately, you are using an older browser that may not support
            these technologies.
            <br /> Download the latest version of the Chrome browser by clicking
            the icon below and you will be on your way.
          </div>

          <div className={classes.link}>
            <Box
              style={{ width: '8.33rem', margin: '0 auto' }}
              onClick={() => window.open('https://www.google.com/chrome/')}
            >
              <img src="images/Google_Chrome.svg" alt="Chrome" />
            </Box>
          </div>
          <div className={classes.url}>
            Your personalized login url:
            <Box
              component="p"
              className={classes.href}
              onClick={() => {
                window.open(window.location.origin);
              }}
            >
              {window.location.origin}
            </Box>
            <CopyToClipboard
              text={window.location.origin}
              onCopy={() =>
                enqueueSnackbar('Copied to Clipboard!', { variant: 'success' })
              }
            >
              <FileCopyOutlinedIcon className={classes.copy} />
            </CopyToClipboard>
          </div>

          <p className={classes.contact}>
            Already using the latest version of Chrome but still having issues?
            <br /> Contact Cowbell support at support@cowbellcyber.ai for
            additional help.
          </p>
        </div>
      </div>
      <footer className={classes.publicFooter}>
        <div className={classes.termsContainer}>
          <div className={classes.entry}>
            <a
              href="https://cowbell.insure/terms/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://cowbell.insure/privacy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <div
            className={classes.entry}
          >{`© ${Moment().year()} Cowbell Cyber Inc. All Rights Reserved, `}</div>
          <div className={classes.entry}>{legal[region]}</div>
        </div>

        <div className={classes.socialContainer}>
          <SocialMedia />
        </div>
      </footer>
    </main>
  );
});

export default BrowserUnsupported;
