import _ from 'lodash';
import { createAction, handleActions } from 'redux-actions';

import { mappedModals } from '../components/modals';
import { USER_PERSONA_DISCOVERED } from '../store/actions';

export const UI_UPDATE = 'UI_UPDATE';
export const UI_TOGGLE_MODAL = 'UI_TOGGLE_MODAL';

// selectors
export const getModals = (state: any) => state.ux.modals;
export const getWindowWidth = (state: any) => state.ux.windowWidth;
export const getSnackbarMessage = (state: any) =>
  state.ux.snackbar.snackbarMessage;
export const getSnackbarOpen = (state: any) => state.ux.snackbar.snackbarOpen;

// actions
export const updateUi = createAction(UI_UPDATE);
export const toggleModal = createAction(
  UI_TOGGLE_MODAL,
  // @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
  (key, content, config) => ({
    key,
    content,
    config,
  })
);

// reducer
const initialState = {
  /**
   * @name _legacy_persona
   * @description Legacy form of persona still used in some views
   * @deprecated
   */
  _legacy_persona: null,

  persona: null, // taken from the token to normalize use between us and backend,

  fontSize: 12,
  windowWidth: window.innerWidth,
  modals: {
    modalConfig: {},
    modalContent: {},
    ...mappedModals,
  },
};

const UxReducer = handleActions(
  {
    [UI_UPDATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    [USER_PERSONA_DISCOVERED]: (state, action) => {
      const { persona, _legacy_persona } = action.payload;

      return {
        ...state,
        persona,
        _legacy_persona,
      };
    },

    [UI_TOGGLE_MODAL]: (state, action) => {
      const activeModalKey = Object.keys(state.modals).find(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (key) => _.isBoolean(state.modals[key]) && !!state.modals[key]
      );
      const updates = {};

      if (activeModalKey) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        updates[activeModalKey] = false;
      }

      // @ts-expect-error TS(2339): Property 'key' does not exist on type '{ _legacy_p... Remove this comment to see the full error message
      if (action.payload.key) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        updates[action.payload.key] = true;
      }

      // user wants to close the current modal
      // @ts-expect-error TS(2339): Property 'key' does not exist on type '{ _legacy_p... Remove this comment to see the full error message
      if (action.payload.key && action.payload.content === false) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        updates[action.payload.key] = false;
      }

      return {
        ...state,
        modals: {
          ...state.modals,
          ...updates,
          modalContent:
            // @ts-expect-error TS(2339): Property 'content' does not exist on type '{ _lega... Remove this comment to see the full error message
            action.payload.content || initialState.modals.modalContent,
          // @ts-expect-error TS(2339): Property 'config' does not exist on type '{ _legac... Remove this comment to see the full error message
          modalConfig: action.payload.config || initialState.modals.modalConfig,
        },
      };
    },
  },
  initialState
);

export default UxReducer;
