import React, { Component } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { withStyles } from '@mui/styles';

class DescTooltip extends Component {
  render() {
    const {
      // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
      props: { classes, tooltip, style, onOpen, onClose },
    } = this;

    return (
      <Tooltip
        title={tooltip}
        placement="top"
        arrow
        className={classes.launcher}
        classes={{ tooltip: classes.container }}
        TransitionComponent={Zoom}
        onOpen={onOpen}
        onClose={onClose}
      >
        <img
          src="/images/Tooltip.svg"
          className={classes.tooltipIcon}
          style={style}
          alt="ToolTip"
        />
      </Tooltip>
    );
  }
}

const styles = ({ palette }: any) => ({
  launcher: {
    cursor: 'auto',
    marginLeft: '.3rem',
  },
  tooltipIcon: {
    width: 15,
    height: 15,
    marginLeft: 2,
    marginBottom: -3,
  },
  container: {
    border: `1px solid  ${palette.common.cowbellBlue}`,
    backgroundColor: palette.background.default,
    color: palette.text.primary,
    borderRadius: 5,
    padding: '1rem',
    marginBottom: 5,
    fontSize: '0.875rem',
    display: 'flex',
    flexDirection: 'column',
  },
});

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { launcher... Remove this comment to see the full error message
export default withStyles(styles, { withTheme: true })(DescTooltip);
