import React from 'react';

// components
import { Autocomplete as MuiAutocomplete } from '@mui/lab';
import { TextFieldBase } from '../TextFieldBase';
import { withShowable } from '../../../console/_global/lib/withShowable';

export const AutoCompleteBase = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
    name,
    // @ts-expect-error TS(2339): Property 'required' does not exist on type '{}'.
    required,
    // @ts-expect-error TS(2339): Property 'fullWidth' does not exist on type '{}'.
    fullWidth,
    // @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'.
    error,
    // @ts-expect-error TS(2339): Property 'textFieldProps' does not exist on type '... Remove this comment to see the full error message
    textFieldProps = {},
    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    options = [],
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{}'... Remove this comment to see the full error message
    placeholder,
    ...props
  }) => {
    return (
      <MuiAutocomplete
        disableClearable
        fullWidth={fullWidth}
        getOptionLabel={getOptionLabel}
        forcePopupIcon={false}
        openOnFocus
        options={options}
        noOptionsText="Type to search"
        renderInput={(params) => {
          return (
            <TextFieldBase
              name={name}
              required={required}
              label={label}
              // @ts-expect-error TS(2783): 'fullWidth' is specified more than once, so this u... Remove this comment to see the full error message
              fullWidth={fullWidth}
              error={error}
              showErrorPlace={false}
              placeholder={placeholder}
              {...params}
              {...textFieldProps}
            />
          );
        }}
        {...props}
      />
    );
  }
);

export const getOptionLabel = (option: any) => option?.label ?? '';
