import Moment from 'moment';
import {
  applyQueryConfig,
  responseStringTransformer,
  ServiceHandler,
} from '../utils';
import {
  standardParamSerializer,
  standardQuerySerializer,
} from './serializers';
import { cmailAccountsParamsSerializer } from './cmail/cmail-accounts.params-serializer';
import type { CmailMessage } from './cmail/cmail.dto';

export const transformMessage = (message: CmailMessage) => {
  return {
    ...message,
    ui_dateReceivedShort: Moment.utc(message.dateReceived).format('MMM D'),
    ui_dateReceivedLong: `${Moment.utc(message.dateReceived).format(
      'LLLL'
    )} (${Moment.utc(message.dateReceived).fromNow()})`,
    seenBy: message.seenBy ?? [],
    isArchived: message.isArchived ?? false,
    isStarred: message.isStarred ?? false,
    labels: message.labels ?? [],
  };
};

export const getMessageByMsgId = ServiceHandler({
  url: '/api/docmessage/v1/message',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig([{ path: 'msgId' }], params)),
});

export const getAllMessages = ServiceHandler({
  url: '/api/docmessage/v1/search',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(standardParamSerializer, params)),
  transformResponse: [responseStringTransformer],
});

export const updateCmailMessage = ServiceHandler({
  url: '/api/docmessage/v1/message',
  method: 'PUT',
});

export const getMessagesByAccountIdAndAccountName = ServiceHandler({
  url: '/api/docmessage/v1/searchForAccount',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(cmailAccountsParamsSerializer, params)
    ),
});

export type MessageInfoLabel = {
  label: string;
  value: string;
};

export const getLabels = ServiceHandler<unknown, MessageInfoLabel[]>({
  url: '/api/docmessage/v1/labels',
});

export const updateLabel = ServiceHandler({
  url: '/api/docmessage/v1/:msgId/label',
  method: 'PUT',
});
