import _ from 'lodash';
import { Api } from '../config/axiosConfig';
import { ProductTypes } from '../types';
import { ServiceHandler } from '../utils';
import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { quoteServiceMappings } from './quoteServiceMappings';
import { quoteServiceMappingsE7 } from './quoteServiceMappingsE7';

export const fetchQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

export const fetchBinders = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/search/binders',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

export const getBinderStatusOptions = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/binder-statuses' },
});

export const fetchUwQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search/uw-inbox',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

export const fetchUwReferalReason = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/referral-reasons',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

export const binderRead = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/search/binders/read',
  },
  paramSchema: quoteServiceMappings,
  isCancelable: true,
});

export const updateQuoteAgentInfo = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/action/agent',
    method: 'put',
  },
  isCancelable: true,
});

export const getExpiredQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search/action?filter%5BagencyStatus%5D%5Bin%5D=READY%2CBOUND%2CINDICATION',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappings,
});

export const fetchQuotesStats = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/premium' },
  paramSchema: quoteServiceMappings,
});

export const fetchBinderQuotesStats = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/bound-premium' },
  paramSchema: quoteServiceMappings,
});

export const fetchUWInboxStats = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/uw-inbox/aggregate' },
  paramSchema: quoteServiceMappings,
});

/**
 * New location src/api/accounts.api.ts
 * @deprecated
 */
export const fetchUniqueAccountStats = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/uniqueAccounts' },
  paramSchema: quoteServiceMappings,
});
export const fetchAggregateQuotes = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/aggregateQuotes' },
  paramSchema: quoteServiceMappings,
});
export const fetchAggregateQuoteAccounts = ServiceHandlerFactory({
  axios: { url: '/api/quote/v1/search/aggregateQuotedAccounts' },
  paramSchema: quoteServiceMappings,
});

export function generateExcessPolicy(policyId: any) {
  return Api.put(`/api/policy/v1/P250/excess/issue/${policyId}`);
}
export function regenerateApplication({ id, product }: any) {
  if (product === ProductTypes.p100) {
    return Api.get(`/api/quote/v1/document/${id}`);
  }

  if (product === ProductTypes.p250) {
    return Api.put(`/api/quote/v1/quoteDoc?quoteId=${id}`);
  }

  return Api.put(`api/quote/v1/${product}/${id}/quoteDoc`);
}
export function regenerateProposal({ id, product }: any) {
  return Api.put(`/api/quote/v1/proposal?quoteId=${id}&product=${product}`);
}

export function generatePolicyQuote(quoteId: any, product: any) {
  if (product === ProductTypes.p100) {
    return Api.put(`/api/quote/v1/action/policy/${quoteId}`);
  }

  return Api.put(`/api/esign/v1/quoteSigned?quoteId=${quoteId}`);
}

/**
 * New location src/apis/quotes.api.ts
 * @deprecated
 */
export function fetchQuoteDetails(quoteId: any) {
  return Api.get(`/api/quote/v1/${quoteId}`);
}

/**
 * New location src/apis/quotes.api.ts
 * @deprecated
 */
export function fetchP250QuoteDetails(quoteId: any) {
  return Api.get(`/api/quote/v1/P250/quotes/${quoteId}`);
}

export function fetchQuoteDetailsOffProduct(product: any, quoteId: any) {
  if (product === 'P250') {
    return fetchP250QuoteDetails(quoteId);
  }
  return fetchQuoteDetails(quoteId);
}

export function fetchP250QuotePageDetails(quoteId: any) {
  return Api.get(`/api/quote/v1/P250/details/${quoteId}`);
}

export function fetchPolicyQuoteDetails(quoteId: any) {
  return Api.get(`/api/quote/v1/policy/${quoteId}`);
}

export const fetchUnreadQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search/read',
  },
  paramSchema: quoteServiceMappings,
});

function cleanAis(payload: any) {
  return {
    ...payload,
    additionalInsureds: payload.additionalInsureds.map(
      ({ naicsCode, ...ai }: any) => {
        const naicsDescription = getNaicsDescription({ naicsCode, ...ai });
        return {
          ...ai,
          naicsCode:
            typeof naicsCode === 'object' ? naicsCode.value : naicsCode,
          naicsDescription,
        };
      }
    ),
  };
}

const getNaicsDescription = (ai: any) => {
  const { naicsCode, naicsDescription } = ai;
  let description;
  if (typeof naicsDescription === 'string') {
    description = naicsDescription;
  }
  if (typeof naicsDescription === 'object') {
    description = _.get(
      naicsDescription,
      'meta.secondaryIndustry',
      description
    );
  }
  if (typeof naicsCode === 'object') {
    description = _.get(naicsCode, 'meta.secondaryIndustry', description);
  }
  return description;
};

export const calculatePricing = ServiceHandlerFactory({
  axios: {
    method: 'POST',
    url: '/api/calculator/v1/calculate',
    transformRequest: [
      (data: any) => {
        // eslint-disable-next-line
        const {
          endDate,
          yearEstablished,
          claimHistory,
          isRenewal,
          zip,
          timezone,
          retroactivePeriod,
          ...rest
        } = data;
        return {
          ...rest,
          additionalInsureds: Array.isArray(data.additionalInsureds)
            ? data.additionalInsureds.length
            : 0,
        };
      },
      JSON.stringify,
    ],
  },
});

const normalizeProperties = (data = {}) => {
  // @ts-expect-error TS(2339): Property 'isAuthenticatingFundsTransferRequest' do... Remove this comment to see the full error message
  const { isAuthenticatingFundsTransferRequest, ...rest } = data;
  const isAuthenticatingFundTransferRequests =
    isAuthenticatingFundsTransferRequest;
  return { isAuthenticatingFundTransferRequests, ...rest };
};

export const calculatePricingV2 = ServiceHandlerFactory({
  axios: {
    method: 'POST',
    url: '/api/calculator/v2/calculate',
    transformRequest: [
      (data: any) => {
        // eslint-disable-next-line
        const { yearsInBusiness, retroactiveYear, ...rest } = data;
        return cleanAis(rest);
      },
      normalizeProperties,
      JSON.stringify,
    ],
  },
});

export function generateQuoteForAccount(accountId: any, details: any) {
  const payload = cleanAis(details);
  return Api.post(`/api/quote/v1/${accountId}`, payload);
}

export function generateQuoteEdit(quoteId: any, details: any) {
  const payload = cleanAis(details);
  return Api.put(`api/quote/v1/edit/${quoteId}`, payload);
}

export function updateBrokerFee(quoteId: any, details: any) {
  return Api.put(`/api/quote/v1/broker-fees/${quoteId}`, details);
}
export function updateMgaFee(quoteId: any, details: any) {
  return Api.put(`/api/quote/v1/mga-fees/${quoteId}`, details);
}

export function generateMultipleQuotesForAccount(details: any) {
  return Api.post(`/api/quote/v1/bulk`, details);
}

export function addToListQuotes(details: any) {
  return Api.post(`/api/quote/v1/calculator`, details);
}

export function getListForAccount(accountId: any, agencyId: any) {
  return Api.get(
    `/api/quote/v1/calculator/account/${accountId}?agencyId=${agencyId}`
  );
}

export function sendQuote(details: any) {
  return Api.put(`/api/quote/v1/action/send`, details);
}

export function downloadQuoteProposal(
  accountId: any,
  quoteId: any,
  created: any
) {
  return Api.get(
    `/api/docs/v1/quote/${accountId}/proposal/${quoteId}?createdDate=${created}`,
    {
      responseType: 'json',
    }
  );
}

export const restoreQuotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/search/restore',
    method: 'put',
  },
  isCancelable: true,
});

export function downloadQuoteDocuments(
  accountId: any,
  quoteId: any,
  created: any,
  isEndorsement: any,
  product: any,
  quoteNumber: any
) {
  return Api.get(
    `/api/docs/v1/quote/${accountId}/${quoteId}?createdDate=${created}&isEndorsement=${isEndorsement}&product=${product}&quoteNumber=${quoteNumber}`
  );
}
export function deleteQuote(quoteId: any) {
  return Api.delete(`/api/quote/v1/${quoteId}`);
}

export function deleteBulkQuote(details: any) {
  return Api.put('/api/quote/v1/action/salesInbox/invalid', details);
}

export function deleteListedQuote(quoteId: any, agencyId: any) {
  return Api.delete(`/api/quote/v1/calculator/${quoteId}?agencyId=${agencyId}`);
}

export function deleteListedQuotesAll(details: any) {
  return Api.put(`/api/quote/v1/calculator/`, details);
}

/**
 * New bind api found in src/api/bind-quote.api.ts
 * @deprecated
 */
export function approveQuote(details: any) {
  return Api.put(`/api/quote/v1/action/approve`, details);
}

export function updateQuote(details: any) {
  return Api.put(`/api/quote/v1/update`, details);
}

export function getQuoteStatus(quoteId: any) {
  return Api.get(`/api/quote/v1/status/${quoteId}`);
}

export function deleteALlBoundQuotes() {
  return Api.delete(`api/quote/v1/bulk/bound`);
}

export function deleteALlInvalidQuotes() {
  return Api.delete(`api/quote/v1/bulk/invalid`);
}
export function retryRequestQuote(quoteId: any) {
  return Api.put(`/api/quote/v1/action/retryrequest/${quoteId}`);
}

export function deleteALlExpiredQuotes() {
  return Api.delete(`/api/quote/v1/bulk/expired`);
}

export const actionOnCustomer = ServiceHandlerFactory({
  axios: {
    url: 'api/quote/v1/action/customer',
    method: 'put',
  },
});

export const attestTermsOnQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/attest/:quoteId',
    method: 'put',
  },
});

export const sendSignRequest = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/action/send-sign-request',
    method: 'post',
  },
});

export const getQuote = (quoteId: any, productType: any) => {
  if (productType === ProductTypes.p100) {
    return fetchQuoteDetails(quoteId);
  }
  if (productType === ProductTypes.p250) {
    return fetchP250QuotePageDetails(quoteId);
  }
};

export const sendRenewalQuoteDetailsEmail = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/action/renewal',
    method: 'put',
  },
});

export const getAgencyStatuses = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/agency-statuses',
  },
});

export const getCustomerStatuses = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/customer-statuses',
  },
});

export const getEndorsementsInProgress = ServiceHandlerFactory({
  axios: {
    url: `/api/quote/v1/endorsement/:product/available/:policyNumber`,
  },
});

export const deleteEndorsementsInProgress = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/endorsement/:product/:policyNumber',
    method: 'delete',
  },
});

export const generateEndorsementQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/endorsement',
    method: 'post',
  },
});

export const generateEndorsementQuoteP250 = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/endorsement/P250/:id',
    method: 'post',
  },
});

export const createOverride = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/tam/override',
    method: 'post',
  },
});

export const deleteOverride = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/tam/override',
    method: 'delete',
  },
});

export const fetchOverrides = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/tam/override',
  },
  isCancelable: true,
  paramSchema: quoteServiceMappingsE7,
});

export function downloadBinder(accountId: any, quoteId: any, boundOn: any) {
  return Api.get(
    `/api/docs/v1/quote/${accountId}/binder/${quoteId}?createdDate=${boundOn}`,
    {
      responseType: 'json',
    }
  );
}

export const rescindBinder = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/rescind/:quoteId',
    method: 'put',
  },
});

export const toggleFollowupFlag = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/followup/:quoteId',
    method: 'put',
  },
});

export const toggleUrgentFlag = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/urgent/:quoteId',
    method: 'put',
  },
});

export const toggleSalesFlag = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/sales/:quoteId',
    method: 'put',
  },
});

export const setUWRiskEng = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/re/:quoteId',
    method: 'put',
  },
});

export const setUWClaims = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/claims/:quoteId',
    method: 'put',
  },
});

/**
 * @name toggleRequestToBindFlag
 * @deprecated
 */
export const toggleRequestToBindFlag = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/request-to-bind/:quoteId',
    method: 'put',
  },
});

export const fetchRenewalPolicyId = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/action/renewal/:quoteId',
  },
});

export const checkStatusOnAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/policy/v1/business/:accountId',
  },
  paramSchema: [{ path: 'agencyId' }],
});

export const generateAutoRenewal = ServiceHandler({
  url: '/api/policy/v1/renewal/:policyId',
  method: 'POST',
});

export const pollForStatusOfAutoRenewal = ServiceHandler({
  url: '/api/quote/v1/agencyStatus/:quoteId',
});

export const existingRenewalQuoteOnAccount = ServiceHandler({
  url: '/api/quote/v1/:product/renewal-quotes/:accountId',
});

/**
 * New Issue api found in src/api/quotes.api.ts
 * @deprecated
 */
export const issueRenewalPolicyForAgency = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/action/issuePolicy/:quoteId',
    method: 'PUT',
  },
  paramSchema: [{ path: 'agreeToTerms' }, { path: 'nkllIncludedInApp' }],
});

export const getSubjectivitiesForUW = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/:productType/:quoteId/quote-subjectivity/settings',
  },
});

export const saveSubjectivitiesFromUW = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/:productType/:quoteId/quote-subjectivity/settings',
    method: 'POST',
  },
});

export const checkIfAutoRenewalQuoteExists = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/auto-renewal/:accountId/:productType',
  },
});

// TODO: Stubs a waive/unwaive subjectivity API
export const waiveSubjectivity = () => Promise.resolve();
