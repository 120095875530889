import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import _ from 'lodash';

// components
import { makeStyles } from '@mui/styles';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
import {
  RegisteredTypeAhead,
  ManagedTypeAheadBase,
  TypeAheadBase,
} from '../../components/inputs/autocomplete/index';
import { ManagedFetchingAutoComplete } from '../../components/inputs/autocomplete/ManagedFetchingAutoComplete';

// services
import { fetchAgencies } from '../../api/apis';
import {
  getSelectItemAgency,
  reduceForSelect,
} from '../../console/admin/users/userUtils';
import { useCowbellTranslations } from '../../i18n/translations';

export const AgencyLookUpSchema = Yup.object({
  label: Yup.string().required(),
  value: Yup.string().required(),
})
  .required()
  .typeError('Please select a valid option, current option not found.');

const defaultLabelProps = {
  style: { fontSize: '1rem' },
};

export const AgencyLookupField = ({
  defaultOptions,
  label,
  labelProps = defaultLabelProps,
  name,
  ...props
}: any) => {
  const { t } = useCowbellTranslations();

  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const { setValue } = useFormContext();
  const [options, setOptions] = React.useState(defaultOptions);

  const handleChange = React.useCallback(
    (newValue: any) => {
      setValue(name, newValue);
    },
    [name, setValue]
  );

  const onFetch = React.useCallback(({ input }: any, callback: any) => {
    fetchAgencies({
      search: input,
      size: 200,
    }).then(({ data }: any) => {
      callback(reduceForSelect(data.content, getSelectItemAgency));
    });
  }, []);

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={name}
        required={props.required}
        {...labelProps}
      >
        {label || t('agency')}
      </InputLabelBase>
      <TypeAheadBase
        id="agency"
        options={options}
        onChange={handleChange}
        onOptionsChange={setOptions}
        onFetch={onFetch}
        className={inputClasses}
        {...props}
      />
    </>
  );
};

export default withShowable(AgencyLookupField);

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

export const RegisteredAgencyAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  ...props
}: any) => {
  const { t } = useCowbellTranslations();
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(({ input }: any, callback: any) => {
    return fetchAgencies({ search: input, size: 100 }).then(({ data }: any) => {
      callback(reduceForSelect(data.content, getSelectItemAgency));
    });
  }, []);

  return (
    <>
      <InputLabelBase
        indent
        htmlFor={props.name}
        required={props.required}
        {...labelProps}
      >
        {label || t('agency')}
      </InputLabelBase>
      <RegisteredTypeAhead
        id="agency"
        className={inputClasses}
        onFetch={handleSearch}
        filterOptions={filterOptions}
        {...props}
      />
    </>
  );
};

export const ManagedAgencyAutoComplete = ({
  label,
  labelProps = defaultLabelProps,
  ...props
}: any) => {
  const classes = useStyles();
  const inputClasses = classnames({
    [classes.inline]: labelProps.inline,
  });

  const handleSearch = React.useCallback(
    _.debounce(({ input }, callback) => {
      fetchAgencies({ search: input, size: 200 }).then(({ data }: any) => {
        callback(reduceForSelect(data.content, getSelectItemAgency));
      });
    }, 500),
    []
  );

  return (
    <ManagedTypeAheadBase
      label={label}
      className={inputClasses}
      onFetch={handleSearch}
      {...props}
    />
  );
};

export const ManagedAgencyAutoCompleteV2 = ({ ...props }) => {
  const queryKey = ['agency-options'];
  function fetchFunc({ search }: any) {
    return fetchAgencies({ search }).then((res: any) => res.data.content);
  }

  return (
    <ManagedFetchingAutoComplete
      {...props}
      queryKey={queryKey}
      fetchFunc={fetchFunc}
      itemsToSelectOptionsTransformer={(listItems: any) =>
        reduceForSelect(listItems, getSelectItemAgency)
      }
    />
  );
};

function filterOptions(options: any) {
  return options;
}
