import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import classnames from 'classnames';
import { useSnackbar } from 'notistack';

import {
  Box,
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import withRouter from '../../components/hocs/withRouter';
import { AccountDetails } from './confirm/AccountDetails';
import { SecurityInfo } from './confirm/SecurityInfo';
import { CowbellFactors } from './confirm/CowbellFactors';
import { Coverages } from './confirm/Coverages';

import { fetchQuoteDetails, fetchAccountDetailsById } from '../../api/apis';
import { PubSub } from '../../utils/eventUtils';
import { AttestRequest } from './confirm/AttestRequest';

import { fetchP100Coverages } from '../../accounts/AccountService';
import { setAuthentication } from '../../reducers/AuthReducers';
import {
  READY_STATUS,
  INDICATION_STATUS,
  BOUND_STATUS,
  INVALID_STATUS,
} from '../../console/_statics/quote.statics';
import { SimpleFooter } from '../../components/entry/SimpleFooter';

export const QuoteRequestToBindConfirm = withRouter(
  ({ match, location, q }: any) => {
    const { heading1, heading2, card, ...classes } = useClasses();
    const [$cardRef, set$cardRef] = useState(null);
    const getCardRef = useCallback((ref: any) => {
      set$cardRef(ref);
    }, []);

    // handles fetching / refetching of quote
    const { loading, quote, account, unauthenticated } = useDependencies(match);

    const statusHeading = classnames(heading2, {
      'orange-text':
        // @ts-expect-error TS(2339): Property 'agencyStatus' does not exist on type '{ ... Remove this comment to see the full error message
        quote.agencyStatus === READY_STATUS && quote.wraRequestToBind,
      // @ts-expect-error TS(2339): Property 'agencyStatus' does not exist on type '{ ... Remove this comment to see the full error message
      'error-text': quote.agencyStatus === INVALID_STATUS,
    });

    if (loading) {
      return (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }

    const route = location.pathname.split('/')[1];

    return (
      <>
        <Container classes={classes} maxWidth="lg">
          <Box margin="2.5rem auto" maxWidth="13.9166666667rem">
            <img src="/logos/logo@3x.png" alt="cowbell logo" />
          </Box>
          <h1 className={heading1}>Welcome {q.agency} to Cowbell Cyber</h1>
          <h2 className={statusHeading}>
            {/* @ts-expect-error: FIXME */}
            {quote.agencyStatus === INDICATION_STATUS ||
              (READY_STATUS &&
                // @ts-expect-error TS(2339): Property 'wraRequestToBind' does not exist on type... Remove this comment to see the full error message
                !quote.wraRequestToBind &&
                // @ts-expect-error TS(2339): Property 'quoteNumber' does not exist on type '{ f... Remove this comment to see the full error message
                `Quote ${quote.quoteNumber} for ${quote.companyName}`)}
            {/* @ts-expect-error: FIXME */}
            {quote.agencyStatus === READY_STATUS &&
              // @ts-expect-error TS(2339): Property 'wraRequestToBind' does not exist on type... Remove this comment to see the full error message
              quote.wraRequestToBind &&
              // @ts-expect-error TS(2339): Property 'quoteNumber' does not exist on type '{ f... Remove this comment to see the full error message
              `Request to Bind Quote ${quote.quoteNumber} for ${quote.companyName} has been sent`}
            {/* @ts-expect-error: FIXME */}
            {quote.agencyStatus === BOUND_STATUS &&
              // @ts-expect-error TS(2339): Property 'agencyName' does not exist on type '{ fi... Remove this comment to see the full error message
              `Quote has already been bound by ${quote.agencyName} for ${quote.companyName}`}
            {/* @ts-expect-error: FIXME */}
            {quote.agencyStatus === INVALID_STATUS &&
              // @ts-expect-error TS(2339): Property 'quoteNumber' does not exist on type '{ f... Remove this comment to see the full error message
              `Quote ${quote.quoteNumber} for ${quote.companyName} has become Invalid`}
          </h2>
          <Divider className={classes.divider} />
          <Grid container spacing={4}>
            <Grid item md={12}>
              <AccountDetails
                parentClasses={{ card }}
                account={account}
                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                accountId={account.id}
                quote={quote}
                $cardRef={$cardRef}
                route={route}
                from="wholesale"
              />
            </Grid>
            <Grid item md={12}>
              <SecurityInfo
                parentClasses={{ card }}
                firmographicData={quote.firmographicData}
              />
            </Grid>
            <Grid item md={6}>
              <CowbellFactors
                parentClasses={{ card }}
                // @ts-expect-error TS(2339): Property 'cowbellRiskFactors' does not exist on ty... Remove this comment to see the full error message
                cowbellRiskFactors={quote.cowbellRiskFactors}
                $cardRef={$cardRef}
              />
            </Grid>
            <Grid item md={6}>
              <Coverages
                parentClasses={{ card }}
                account={account}
                quote={quote}
                // @ts-expect-error TS(2339): Property 'initialRequestData' does not exist on ty... Remove this comment to see the full error message
                coverageData={quote.initialRequestData}
                getCardRef={getCardRef}
              />
            </Grid>
            <Grid item md={12}>
              <AttestRequest
                parentClasses={{ card }}
                quote={quote}
                account={account}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <SimpleFooter />
        </Container>
        <Backdrop
          // @ts-expect-error TS(2339): Property 'agencyStatus' does not exist on type '{ ... Remove this comment to see the full error message
          open={quote.agencyStatus === INVALID_STATUS}
          className={classes.backdrop}
          style={{ backgroundColor: 'rgba(0, 0, 0, .75)' }}
        >
          <Container maxWidth="md">
            <p className="canon-text error-text">
              {/* @ts-expect-error: FIXME */}
              Quote {quote.quoteNumber} for Adobe Systems Incorporated has
              become Invalid because you've made the following changes:
            </p>
            {/* @ts-expect-error: FIXME */}
            <p className="canon-text error-text">{quote.agencyDescription}</p>

            <Box marginTop="5rem">
              <p className="canon-text orange-text">
                If you made this change by mistake or need to update the
                information to get a new Quote, please contact{' '}
                {/* @ts-expect-error: FIXME */}
                {quote.agentFirstName} {quote.agentLastName} at:
              </p>
              {/* @ts-expect-error: FIXME */}
              <Box className="canon-text orange-text">{quote.agencyName}</Box>
              <Box className="canon-text orange-text" marginTop=".25rem">
                {/* @ts-expect-error: FIXME */}
                {quote.agentEmail}
              </Box>
              <Box className="canon-text orange-text" marginTop=".25rem">
                {/* @ts-expect-error: FIXME */}
                {quote.agentPhone}
              </Box>
            </Box>
          </Container>
        </Backdrop>
        <Backdrop
          open={unauthenticated}
          className={classes.backdrop}
          style={{ backgroundColor: 'rgba(0, 0, 0, .75)' }}
        >
          <Container maxWidth="md" style={{ textAlign: 'center' }}>
            <a href="https://cowbell.ensure">
              <img
                src="/logos/logo@2x.png"
                alt="Cowbell logo, and link to cowbell website"
                style={{ width: 300 }}
              />
            </a>
            <p className="canon-text text-center">
              Looks like you're not able to view this quote. If you believe you
              recieved this message in error, please go back to the email and
              click the "Review & Request to bind" link again.
            </p>
          </Container>
        </Backdrop>
      </>
    );
  }
);

const useClasses = makeStyles(({ config, ...theme }) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,

    color: theme.palette.text.link,
  },

  maxWidthLg: {
    maxWidth: '90.8333333333rem',
  },

  heading1: {
    margin: 0,
    marginTop: '2.5rem',
    fontSize: config.textSizes.canon,
    lineHeight: 1,
    textAlign: 'center',
    color: 'white',
  },

  heading2: {
    marginTop: '1.3333333333rem',
    marginBottom: 0,
    fontSize: config.textSizes.paragon,
    fontWeight: 400,
    lineHeight: 1,
    textAlign: 'center',
    color: 'white',
  },

  card: {
    padding: '1.6666666667rem',
    border: '1px solid #707070',
    backgroundColor: 'rgba(38, 38, 46, .5)',
    color: 'white',
  },

  divider: {
    margin: '1.5rem 0',

    background: theme.palette.common.cowbellBlue,
  },
}));

const useDependencies = (match: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState({ firmographicData: {} });
  const [account, setAccount] = useState({});
  const [unauthenticated, setUnauthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleQuoteError(error: any) {
      const status = _.get(error, 'response.status', '');

      if (status === 401) {
        setUnauthenticated(true);
      }

      setLoading(false);
      enqueueSnackbar('There was an error while retrieving the quote', {
        variant: 'error',
      });
    }

    // on first page render
    setLoading(true);
    fetchP100Coverages()
      .then((response) => dispatch(setAuthentication({ P_100: response.data })))
      .catch(handleQuoteError);

    fetchQuoteDetails(match.params.id)
      .then(({ data }) => {
        setQuote(data);
        return fetchAccountDetailsById(data.accountId);
      })
      .then(({ data }) => {
        setAccount(data);
        setLoading(false);
      })
      .catch(handleQuoteError);

    // subscribe for when a quote is patched
    const quotePatchedSub = PubSub.subscribe(
      'quote:patched',
      (updatedQuote: any) => {
        setQuote(updatedQuote);
        setLoading(false);
      }
    );

    const accountRefreshSub = PubSub.subscribe(
      'account:refresh',
      ({ accountId }: any) => {
        setLoading(true);
        fetchAccountDetailsById(accountId)
          .then(({ data }) => {
            setLoading(false);
            setAccount(data);
          })
          .catch(handleQuoteError);
      }
    );

    const quoteRefreshSub = PubSub.subscribe('quote:refresh', () => {
      setLoading(true);
      fetchQuoteDetails(match.params.id)
        .then(({ data }) => {
          setLoading(false);
          setQuote(data);
        })
        .catch(handleQuoteError);
    });

    return () => {
      quotePatchedSub.remove();
      accountRefreshSub.remove();
      quoteRefreshSub.remove();
    };

    // eslint-disable-next-line
  }, []);

  return { loading, quote, account, unauthenticated };
};
