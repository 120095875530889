import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// react
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// mui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid,
} from '@mui/material';
// platform helpers
import { useQueryClient } from '@tanstack/react-query';
import { delayedEvent } from '../../utils/eventUtils';
import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';

// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import CbButton from '../Buttons/CbButton';
import { submitPxQuoteForUWReview } from '../../api';

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  reason: Yup.string().required('Reason is a required field'),
});

export const SubmitForUWReview = ({ close, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const defaultValues = {
    apiKey: '',
  };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const [error, setError] = useState('');
  const { id, quoteNumber, product } = data;

  const onSubmit = (formData: any) => {
    const payload = {
      reason: formData.reason,
    };

    submitPxQuoteForUWReview(product, id, {
      data: payload,
    })
      .then(() => {
        enqueueSnackbar(
          `Quote# ${quoteNumber} submitted for Underwriter review`,
          { variant: 'success' }
        );
        delayedEvent('table-refetch', 500, AGENCY_QUOTES_LISTING_STORE_ID);
        queryClient.invalidateQueries(['COWBELL_QUOTES']);
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong, please try again later.'
            )
          )
        );
      });
  };
  return (
    // @ts-expect-error TS(2741): Property 'handleSubmit' is missing in type '{ chil... Remove this comment to see the full error message
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid item md={8}>
            <TextField name="reason" label="Reason" fullWidth required />
          </Grid>
          {error && (
            <FormHelperText className="api-text-error">{error}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton
            loading={isSubmitting}
            styleName="ctaButton"
            buttonText="Submit for Review"
            type="submit"
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};
