import { trimAndAddAsterisk } from '../utility/QueryUtility';
import { commonDateParamMappings } from './common/mappings';

export const activityTrackingServiceMappings = [
  ...commonDateParamMappings,
  { path: 'size' },
  { path: 'dateFilter' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      const newOrder = order || 'asc';
      switch (orderBy) {
        case 'created':
          return `${orderBy},${newOrder}`;
        default:
          return orderBy ? `${orderBy}.keyword,${newOrder}` : null;
      }
    },
  },
  {
    path: 'search',
    transform: ({ search, searchValue }: any) => {
      if (searchValue) {
        return searchValue;
      }
      return search ? trimAndAddAsterisk(search) : null;
    },
  },
  {
    path: 'searchAfter',
    transform: ({ searchAfter }: any) => {
      if (Array.isArray(searchAfter)) {
        const copy = [...searchAfter];
        const id = copy.pop();
        const name = copy.join(',');

        return [name, id].join(encodeURIComponent(','));
      }

      return searchAfter;
    },
  },
  {
    path: 'pageRequested',
  },
  {
    path: 'apiClientId',
  },
];
