import React from 'react';
import * as Ramda from 'ramda';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

// components
import { Box as MuiBox } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InputLabelBase } from '../components/inputs/InputLabelBase';
import { ManagedTypeAheadBase as TypeAheadBase } from '../components/inputs/autocomplete';

import { withShowable } from '../console/_global/lib/withShowable';

// utils
import { PubSub } from '../utils/eventUtils';

// api
import { getMyTeams } from './TeamsService';

// reducers
import {
  selectGlobalTeamAsDropdownOption,
  setGlobalTeam,
} from '../reducers/team.reducer';
import { useAuth } from '../components/hooks/useAuth';
import { TOKEN_REALMS } from '../console/_statics/users/roles.statics';

const Box = withShowable(MuiBox);

export const TeamsMenu = () => {
  const dispatch = useDispatch();
  const storedTeam = useSelector(selectGlobalTeamAsDropdownOption);
  const auth = useAuth();

  // account is not scoped to particular teams (e.g: agency corp admin role has access to all teams)
  const isAccountScoped = auth.tokenRealm === TOKEN_REALMS.a;

  const { data: initialAvailableTeams, isLoading } =
    useGetInitialTeams(isAccountScoped);

  const initialSelectedTeam = useInitialTeam(
    storedTeam,
    initialAvailableTeams,
    isAccountScoped
  );

  const handleSelectTeam = React.useCallback(
    function handleSelectTeam(selectedTeam: any) {
      const nextTeam = { name: selectedTeam.label, id: selectedTeam.value };
      dispatch(setGlobalTeam(nextTeam));
      // this exists to dynamically change url on certain tables, we should remove this and just listen for the state update on those views
      PubSub.publish('team-change', nextTeam);
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (initialSelectedTeam?.value) {
      handleSelectTeam(initialSelectedTeam);
    }
  }, [initialSelectedTeam, initialAvailableTeams, handleSelectTeam]);

  return (
    <Box
      show={!isLoading}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      minWidth={200}
    >
      <InputLabelBase inline>Team:</InputLabelBase>
      <TeamSearch
        defaultValue={initialSelectedTeam}
        onChange={handleSelectTeam}
        isAccountScoped={isAccountScoped}
      />
    </Box>
  );
};

const useGetInitialTeams = (isAccountScoped: any) =>
  useQuery(
    ['global', 'teams'],
    () =>
      getMyTeams({ search: '' }).then((response: any) => {
        const nextAvailableTeams = response.data?.content ?? [];
        return isAccountScoped
          ? [ALL_TEAM, ...nextAvailableTeams]
          : nextAvailableTeams;
      }),
    {
      select: (teams) => teams.map(transformTeamToOption),
    }
  );

const useInitialTeam = (
  storedTeam: any,
  initialAvailableTeams: any,
  isAccountScoped: any
) => {
  return React.useMemo(() => {
    if (initialAvailableTeams == null) {
      return EMPTY_OPTION;
    }

    const isStoredTeamAvailable = initialAvailableTeams.some(
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      optionIs(storedTeam)
    );

    // the previously selected team (or store default) is available
    if (isStoredTeamAvailable) {
      return storedTeam;
    }

    // user's account is not scoped to particular teams and the stored team is not available
    if (isAccountScoped) {
      return ALL_TEAM_OPTION;
    }

    const defaultAvailableTeam = initialAvailableTeams.find(
      (team: any) => team.meta.isDefault
    );

    // the user does not have access to the accounts default team, return the first available team
    if (!defaultAvailableTeam) {
      return initialAvailableTeams[0];
    }

    // the user has access to the account's default team
    return defaultAvailableTeam;

    // eslint-disable-next-line
  }, [initialAvailableTeams, isAccountScoped]);
};

const TeamSearch = ({ isAccountScoped, ...props }: any) => {
  const classes = useMenuStyles();
  const { defaultValue } = props;
  const lastInputRef = React.useRef('');

  function handleSearch({ input }: any, callback: any) {
    const isSearchAll = input === 'All' || input === lastInputRef.current;
    lastInputRef.current = input;
    const nextSearch = isSearchAll ? '' : input;

    return getMyTeams({ search: nextSearch }).then((response: any) => {
      const nextAvailableTeams =
        response.data.content?.map(transformTeamToOption) ?? [];

      if (isAccountScoped) {
        callback([ALL_TEAM_OPTION, ...nextAvailableTeams]);
      } else {
        callback(nextAvailableTeams);
      }
    });
  }

  const defaultOptions = !Ramda.isEmpty(defaultValue) ? [defaultValue] : [];
  return (
    <>
      <TypeAheadBase
        id="global-header-team-search"
        classes={classes}
        onFetch={handleSearch}
        fetchOnMount
        fetchOnFocus
        blurOnSelect
        defaultOptions={defaultOptions}
        renderOption={renderOption}
        noOptionsText={
          <span style={{ fontSize: '1rem' }}>Search by team name</span>
        }
        {...props}
      />
    </>
  );
};

function renderOption(optionProps: any, option: any) {
  return (
    <li {...optionProps}>
      <span style={{ fontSize: '1rem' }}>{option.label}</span>
    </li>
  );
}

const useMenuStyles = makeStyles(({ palette, shape }) => ({
  root: {
    minWidth: '10rem',
  },
  input: {
    marginLeft: '.75rem',
    padding: '4px 8px !important',
    background: 'none',
    border: `1px solid ${palette.primary.border}`,
    borderRadius: shape.borderRadius,

    '&&': {
      padding: '4px 8px !important',
    },

    '&:focus': {
      background: 'none',
      color: palette.primary.contrastText,
    },
  },
  paper: {
    position: 'absolute',
    right: 0,
    width: '100%',
    backgroundColor: palette.background.paper,
    border: 0,
    boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.5)',
  },
}));

export const ALL_TEAM = {
  id: 'all',
  name: 'All',
};

const ALL_TEAM_OPTION = transformTeamToOption(ALL_TEAM);
const EMPTY_OPTION = { value: '', label: '' };

function transformTeamToOption(team: any) {
  return {
    meta: team,
    label: team.name,
    value: team.id,
  };
}

const optionIs = Ramda.eqProps('value');
