import React from 'react';

const SVG = ({
  style = {
    height: '25px',
    width: '25px',
    position: 'relative',
    top: '3px',
    left: '2px',
  },
  fill = '#9ba0ae',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
}) => (
  <svg
    width={width}
    // @ts-expect-error TS(2322): Type '{ height: string; width: string; position: s... Remove this comment to see the full error message
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>.cls-1{`fill:${fill};`}</style>
    </defs>
    <title>Open Actions Menu</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M0,0H24V24H0Z" fill="none" />
        <path d="M3,18H21V16H3Zm0-5H21V11H3ZM3,6V8H21V6Z" fill="#9ba0ae" />
      </g>
    </g>
  </svg>
);

export default SVG;
