import React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

// components
import {
  Box as MuiBox,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import CbButton from '../../Buttons/CbButton';
import { withShowable } from '../../../console/_global/lib/withShowable';

import { AdditionalInsuredFields } from '../../../console/additional-insureds/forms/AdditionalInsuredFields';
import {
  AIAddInsuredButton,
  AIActionBar,
  AIFieldsContainer,
  AINoContentMessage,
} from '../../additional-insured';

import {
  globalSchema,
  naicsCodeValidation,
  stateValidation,
} from '../../globalValidations';

// statics
import StateStatics from '../../../console/_statics/states.statics.json';

const Box = withShowable(MuiBox);

const fieldsName = 'insureds';

const schema = Yup.object().shape({
  [fieldsName]: Yup.array().of(
    Yup.object().shape({
      businessName: Yup.string().label('Organization Name').required(),
      address1: Yup.string().label('Address').required(),
      city: Yup.string().label('City').required(),
      state: stateValidation.label('State').required(),
      zipCode: globalSchema.zipCode,
      naicsCode: naicsCodeValidation.label('Industry').required(),
    })
  ),
});

export const AdditionalInsuredModal = ({ data, close }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, ...methods } = useForm({
    resolver: yupResolver(schema),
  });

  const { append, fields, remove } = useFieldArray({
    control: methods.control,
    name: fieldsName,
  });

  useInsuredEnrichment(data, append);

  function handleAddInsured() {
    append({
      businessName: '',
      address1: '',
      address2: '',
      city: '',
      state: [],
      zipCode: '',
      naicsCode: '',
    });
  }

  function handleOnSubmit(values: any) {
    if (typeof data.onSubmit === 'function') {
      return data.onSubmit(values).then(() => {
        enqueueSnackbar('Additional insured successfully updated', {
          variant: 'success',
        });
        close();
      });
    }
  }

  return (
    // @ts-expect-error TS(2741): Property 'handleSubmit' is missing in type '{ chil... Remove this comment to see the full error message
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogContent>
          <AIActionBar>
            <Box mr={2}>
              <Typography>Additional Insureds {fields.length}</Typography>
            </Box>
            <AIAddInsuredButton variant="outlined" onClick={handleAddInsured}>
              Add Additional Insured
            </AIAddInsuredButton>
          </AIActionBar>

          <AIFieldsContainer>
            {fields.map((field, index) => {
              return (
                <Box key={field.id}>
                  <AdditionalInsuredFields
                    fieldsName={fieldsName}
                    position={index}
                    onRemove={remove}
                    defaultValues={
                      Array.isArray(data.insureds) && data.insureds[index]
                    }
                  />
                </Box>
              );
            })}
            <AINoContentMessage show={!fields.length}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography style={{ marginLeft: '1rem' }}>
                  There are no additional insureds
                </Typography>
              </Box>
              <AIAddInsuredButton variant="outlined" onClick={handleAddInsured}>
                Add Additional Insured
              </AIAddInsuredButton>
            </AINoContentMessage>
          </AIFieldsContainer>
        </DialogContent>
        <DialogActions>
          <CbButton styleName="cancel" onClick={close}>
            Cancel
          </CbButton>
          <CbButton styleName="ctaButton" type="submit">
            Update Insureds
          </CbButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const AdditionalInsuredModalConfig = {
  AdditionalInsuredModal: {
    component: AdditionalInsuredModal,
    config: {
      disableEnforceFocus: true,
      fullWidth: true,
      maxWidth: 'md',
      title: 'Add Additional Insured Coverage',
    },
  },
};

function useInsuredEnrichment(data: any, append: any) {
  React.useEffect(() => {
    if (Array.isArray(data.insureds) && data.insureds.length) {
      data.insureds.forEach((insured: any) => {
        append({
          ...insured,
          state: StateStatics.full.find((s) => s.value === insured.state),
          naicsCode: insured.naicsCode,
        });
      });
    }
    // eslint-disable-next-line
  }, []);
}
