import { Api as client } from '../config/axiosConfig';

export * from './p250.service';

export {
  addNewAccount,
  fetchPagedAccounts,
  fetchUsersByAccount,
  fetchAccountQuoteDetailsById,
  updateAccountContact,
  assignAgency,
  fetchAccountDetailsById,
  getSocialEngg,
  fetchAccountRiskDetails,
} from '../accounts/AccountService';

export { refreshAccessToken } from './AuthService';

export {
  fetchAgencies,
  fetchAgencyDetails,
  updateAgency,
  addNewAgency,
  fetchPagedAccessibleAgencies,
} from '../agencies/AgencyService';

export { fetchActivityDetails } from './activity.api';

export * from './UserService';

export {
  fetchBlackListedDomains,
  createBlackListedDomains,
  deleteBlackListedDomains,
} from './DomainService';

export {
  fetchQuotes,
  fetchQuoteDetails,
  fetchP250QuoteDetails,
  generateQuoteForAccount,
  generateQuoteEdit,
  sendQuote,
  deleteQuote,
  calculatePricing,
  addToListQuotes,
  getListForAccount,
  deleteListedQuote,
  generateMultipleQuotesForAccount,
  deleteListedQuotesAll,
} from '../inbox/QuotesService';

export function editSelectedAgency(cbapId: any, app: any) {
  return client.put(`/api/cbap/v1/cbaps/${cbapId}`, JSON.stringify(app));
}

export function deleteSelectedAgency(agencyId: any) {
  return client.delete(`/api/auth/v1/admin/agencies/${agencyId}`);
}

/*
 * Login / Signup related APIs
 */

/* Account */
export function fetchAccount() {
  return client.get('/api/account/v1/account');
}

export function postAccount(account: any) {
  return client.post('/api/account/v1/account', JSON.stringify(account));
}

/* Edit User Profile */

export function updateUserProfile(account: any) {
  return client.put(`api/auth/v1/me`, JSON.stringify(account));
}

export function updateUserPassword(account: any) {
  return client.put('api/auth/v1/me/password', JSON.stringify(account));
}

/* role table meta */
export function fetchRolesTablePage(
  pageIndex: any,
  pageSize: any,
  order: any,
  orderBy: any
) {
  return client.get(
    `/api/auth/v1/roles/search?page=${pageIndex}&size=${pageSize}&sort=${orderBy},${order}`
  );
}

/**
 * Should only be used to fetch the latest version available.
 * Current app version is injected at build-time, which can be accessed via the REACT_APP_VERSION environment
 *
 *
 */
export function fetchConsoleVersion() {
  return client.get('/api/console-version');
}
