import _ from 'lodash';
import React, { useContext, useMemo } from 'react';

import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
} from '@mui/material';

import DescTooltip from '../../../DescTooltip';
import { TableContext } from './context.table';

export const TableHeader = () => {
  const ctx = useContext(TableContext);
  // @ts-expect-error TS(2339): Property 'columns' does not exist on type '{ onSor... Remove this comment to see the full error message
  const { columns, order, orderBy, onSort, styles } = ctx;

  const classes = useMemo(() => {
    if (styles) {
      return styles.head || {};
    }

    return {};
  }, [styles]);

  return (
    <MuiTableHead classes={classes}>
      <MuiTableRow>
        {columns.map((column: any, idx: any) => {
          const { sort, name, tooltip } = column;
          const key = `${name}-${idx}`;
          const sortName = (sort && name) || name;

          if (column.show === false) {
            return null;
          }

          let headContent = column.label;
          let headProps = {};

          if (_.isFunction(column.Head)) {
            headContent = column.Head(column, ctx);
          }

          if (_.isObject(column.Head)) {
            headProps = _.omit(column.Head, 'C');

            if (_.has(column.Head, 'C')) {
              headContent = column.Head.C(column, ctx);
            }
          }

          // this is not good, this should be in an enhancement
          let tooltipTag = '';
          if (!_.isEmpty(tooltip)) {
            // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'string'.
            tooltipTag = (
              <DescTooltip
                style={{
                  display: 'inline-block',
                  marginLeft: 3,
                  marginRight: 3,
                }}
                tooltip={tooltip}
              />
            );
          }

          return (
            <MuiTableCell key={key} align="left" {...headProps}>
              {column.sort === false ? (
                headContent
              ) : (
                <MuiTableSortLabel
                  active={orderBy === name || orderBy === sortName}
                  onClick={sortHandler(sortName, order, onSort)}
                  direction={order}
                >
                  {headContent}
                  {tooltipTag}
                </MuiTableSortLabel>
              )}
            </MuiTableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
};

function sortHandler(name: any, prevOrder: any, onSort: any) {
  return () => {
    const order = prevOrder === 'asc' ? 'desc' : 'asc';

    if (typeof onSort === 'function') {
      onSort(name, order);
    }
  };
}

TableHeader.defaultProps = {
  onSort: () => null,
  onRefresh: () => null,
};
