import React from 'react';
import { useFormContext } from 'react-hook-form';
// components
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/lab';

export const RegisteredToggleButtonGroup = ({ name, ...props }: any) => {
  const { register, setValue, watch } = useFormContext();

  const value = watch(name);

  const handleChange = React.useCallback(
    (_event: any, nextValue: any) => {
      if (nextValue !== null) {
        setValue(name, nextValue, { shouldDirty: true });
      }
    },
    // eslint-disable-next-line
    [setValue]
  );

  return (
    <MuiToggleButtonGroup
      {...register(name)}
      onChange={handleChange}
      value={value}
      {...props}
    />
  );
};
