import React from 'react';
import { withRouter, Link } from 'react-router-dom';
// actions
import { Typography } from '@mui/material';
import { confirmResetMfa } from '../../api/AuthService';
// components
import NextPublicWrapper from './NextPublicWrapper';

export const ResetMfaLanding = withRouter(({ match, history }) => {
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    confirmResetMfa(match.params.resetMFAToken)
      .then(() => {
        setSuccess(true);
      })
      .catch((error: any) => {
        if (!error?.response) {
          throw error;
        }

        if (error.response.status === 404) {
          history.push('/login');
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <NextPublicWrapper>
      {success && (
        <>
          <Typography>
            Multi Factor Authentication has been reset.
            <br />
            You can now login without MFA settings.
          </Typography>

          <p>
            Login without Mfa?&nbsp;
            <Link
              style={{ color: 'inherit' }}
              className="link-underlined"
              to="/login"
            >
              Login Here
            </Link>
          </p>
        </>
      )}
    </NextPublicWrapper>
  );
});
