import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { PubSub } from '../../utils/eventUtils';
import { onDemandScan } from '../../accounts/AccountService';

import { withQueryClient } from '../hocs/withQueryClient';
import { withToggleModal } from '../hocs/withToggleModal';
import { manageAPIError } from '../../utils';
import { queries } from '../../queries';

class OnDemandScan extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      accountName: '',
      accountId: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      props: { data },
    } = this;
    this.setState({
      accountName: data.name,
      accountId: data.id,
    });

    this.subscription = PubSub.subscribe('foot-button-one', (subData: any) => {
      if (subData) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    // @ts-expect-error TS(2339): Property 'queryClient' does not exist on type 'Rea... Remove this comment to see the full error message
    const { queryClient } = this.props;

    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    // @ts-expect-error TS(2339): Property 'accountId' does not exist on type 'Reado... Remove this comment to see the full error message
    onDemandScan(this.state.accountId)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('OnDemandScan', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar('Data Scanned Successfully!', {
          variant: 'success',
        });

        queryClient.invalidateQueries(queries.accounts.list._def);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: manageAPIError(error) });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to do a On-Demand scan data for
          {/* @ts-expect-error TS(2339): Property 'accountName' does not exist on type 'Rea... Remove this comment to see the full error message */}
          <span className="modal-blue-text"> {this.state.accountName}</span>?
        </div>
        <FormHelperText className="api-text-error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
OnDemandScan.defaultProps = {
  data: {},
};

export default compose(
  withSnackbar,
  withQueryClient,
  withToggleModal
)(OnDemandScan);
