import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useSnackbar } from 'notistack';
// components
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import CBButton from '../Buttons/CbButton';

import { delayedEvent } from '../../utils/eventUtils';
import { updateMgaFee } from '../../inbox/QuotesService';
import { useToggleModal } from '../../utils/modal.utils';
// helpers

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  mgaFee: Yup.string().required('MGA Fee is required'),
});

const EditMgaFee = ({ classes, close, data, ...props }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const defaultValues = {
    mgaFee: _.get(data, 'mgaFee', 0),
    error: '',
  };

  const { handleSubmit, control, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    getValues,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData: any) => {
    const payload = {
      mgaFee: Number(formData.mgaFee),
    };
    return updateMgaFee(data.id, payload)
      .then(() => {
        toggleModal.direct('EditMgaFee', false);
        enqueueSnackbar('MGA Fee updated Successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'uwInbox');
        delayedEvent('table-refetch', 500, 'adminQuotes');
      })
      .catch((error) => {
        setValue('error', error);
      });
  };
  const values = getValues();
  return (
    <section className={classes.cardContentContainer}>
      {/* @ts-expect-error TS(2739): Type '{ children: Element; watch: UseFormWatch<{ m... Remove this comment to see the full error message */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={12}>
                <TextField
                  name="mgaFee"
                  label="Mga Fee"
                  required
                  type="number"
                  // @ts-expect-error TS(2322): Type 'Control<{ mgaFee: any; error: string; }, any... Remove this comment to see the full error message
                  controllerProps={{ control }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <FormHelperText className="api-text-error">
            {values.error}
          </FormHelperText>
          <DialogActions>
            <CBButton action={close} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Save"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
  inputFields: {
    justifyContent: 'space-around',
  },
};

export default withStyles(styles)(EditMgaFee);
