import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { PubSub } from '../../../../../utils/eventUtils';

export const UWResetConfirmation = ({ data, ...props }: any) => {
  const { classes } = data;
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = (formData: any) => {
    PubSub.publish('resetUWQuote', true);
  };

  const handleCancel = () => {
    toggleModalDirect('UWResetConfirmation', false);
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={classes.description}>
          Are you sure you want to reset the quote?
          <br />
          All your changes/updates will go back to the original state.
        </p>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Reset"
          />
        </DialogActions>
      </form>
    </section>
  );
};
