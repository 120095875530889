import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { ClickAwayListener } from '@mui/material';
import { withStyles } from '@mui/styles';

import SelectClasses, {
  MultiSelectStyles,
  MultiSelectStyles1,
} from './MultiSelectStyles';

import MultiSelectButtonWithStyles from './MultiSelectButton';
import {
  Dropdown,
  DropdownIndicator,
  MenuList,
  Option,
} from './MultiSelectComponentOverrides';
import YellowWarningIcon from '../../../_assets/svg/YellowWarning.svg';
import SimpleTooltip from '../../SimpleTooltip';

class MultiSelect extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      values: props.values,
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.toggleAllHandler = this.toggleAllHandler.bind(this);
  }

  componentDidUpdate(prevProps: any) {
    const { props } = this;

    // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (!_.isEmpty(prevProps.values) && _.isEmpty(props.values)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        values: [],
      });
    }

    // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (_.isEmpty(prevProps.values) && !_.isEmpty(props.values)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
        values: props.values,
      });
    }

    // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (!_.isEqual(prevProps.values, props.values)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
        values: props.values,
      });
    }
  }

  render() {
    const { props, state }: any = this;

    if (!props.isShowing) {
      return null;
    }

    let _Option = Option;
    if (_.get(props, 'components.Option')) {
      _Option = props.components.Option;
    }

    const isRequired = props.required ? { required: true } : {};

    return (
      <Fragment>
        {props.label && (
          <label
            className={`${props.classes.label} ${props.labelClass}`}
            htmlFor={props.name}
          >
            {props.label}
            {props.required ? (
              <i
                style={{
                  color: '#f95f53',
                  fontSize: '16px',
                  lineHeight: '16px',
                }}
              >
                *
              </i>
            ) : (
              ''
            )}
          </label>
        )}
        <Dropdown
          id={props.name}
          isOpen={state.isOpen}
          onClose={this.toggleOpen}
          disabled={props.disabled}
          target={
            props.components.DropDownButton ? (
              React.createElement(props.components.DropDownButton, {
                fullWidth: true,
                toggleOpen: this.toggleOpen,
                disabled: props.disabled,
                buttonPlaceholder: props.buttonPlaceholder,
                data: state,
                ...props,
              })
            ) : (
              <MultiSelectButtonWithStyles
                isOpen={state.isOpen}
                fullWidth
                toggleOpen={this.toggleOpen}
                disabled={props.disabled}
                classname={props.classes.select}
                error={props.error}
                customStyle={props.customStyle}
              >
                <span>{generateSelectedLabel(state, props)}</span>

                <ArrowDropDown className={props.classes.buttonDown} />
              </MultiSelectButtonWithStyles>
            )
          }
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            {/* @ts-expect-error TS(2739): FIXME */}
            {this.state.isOpen && (
              <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{
                  DropdownIndicator,
                  Option: _Option,
                  MenuList,
                  IndicatorSeparator: null,
                }}
                toggleAllHandler={this.toggleAllHandler}
                classes={props.classes}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                isMulti={props.isMulti}
                supportSelectAll={props.supportSelectAll}
                menuIsOpen
                onChange={this.onSelectChange}
                options={props.options}
                placeholder={props.placeholder}
                styles={
                  !props.supportSelectAll
                    ? MultiSelectStyles(props.theme)
                    : MultiSelectStyles1(props.theme)
                }
                tabSelectsValue={false}
                value={state.values}
                {...isRequired}
                onFocus={props.onFocusHandler}
                onBlur={props.onBlurHandler}
                onMenuScrollToBottom={props.onMenuScrollToBottom}
                onMenuScrollToTop={props.onMenuScrollToTop}
                inputProps={
                  props.inputProps ? { required: true } : { required: false }
                }
                onInputChange={props.inputChange}
                isLoading={props.isLoading}
                noOptionsMessage={props.noOptionsMessage}
              />
            )}
          </ClickAwayListener>
        </Dropdown>

        {props.error && (
          <span className={props.classes.errorText}>{props.error}</span>
        )}

        {!props.error && props.showErrorPlace && (
          <span className={props.classes.noErrorText} aria-hidden>
            error ph
          </span>
        )}
      </Fragment>
    );
  }

  toggleAllHandler() {
    // @ts-expect-error TS(2339): Property 'toggleAllHandler' does not exist on type... Remove this comment to see the full error message
    this.props.toggleAllHandler();
  }

  toggleOpen() {
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
    if (!this.props.disabled) {
      this.setState((prevState) => ({
        // @ts-expect-error TS(2339): Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
        isOpen: !prevState.isOpen,
      }));
    }
  }

  handleClickAway() {
    this.setState({
      isOpen: false,
    });
  }

  onSelectChange(values: any) {
    const { props } = this;
    this.setState({ values });

    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    if (typeof props.onChange === 'function') {
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      props.onChange(props.name, values);
    }

    // close menu on select if not a multi select
    // @ts-expect-error TS(2339): Property 'isMulti' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (!props.isMulti) {
      this.toggleOpen();
    }
  }
}

function generateSelectedLabel(
  state: any,
  { customStyle, buttonPlaceholder, customLabel }: any
) {
  if (customLabel) {
    return customLabel;
  }

  if (customStyle && Array.isArray(state.values)) {
    const str = state.values.map(({ label }: any) => label).join(', ');

    if (state.values.length === 1) {
      return str;
    }

    if (state.values.length > 1) {
      return (
        <SimpleTooltip title={str}>
          <YellowWarningIcon />
        </SimpleTooltip>
      );
    }
    return buttonPlaceholder;
  }

  if (Array.isArray(state.values)) {
    if (state.values.length > 0) {
      return `${state.values
        .map(({ label }: any, index: any) => (index < 2 ? label : ''))
        .slice(0, 2)
        .join(', ')}${state.values.length > 2 ? ', ...' : ''}`;
    }
  }

  if (typeof state.values === 'object' && !_.isEmpty(state.values)) {
    return state.values.label;
  }

  return buttonPlaceholder;
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
MultiSelect.defaultProps = {
  isMulti: true,
  isShowing: true,
  disabled: false,
  buttonPlaceholder: 'Select',
  options: [],
  values: [],
  components: {},
  showErrorPlace: true,
};

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
MultiSelect.propTypes = {
  components: PropTypes.object,
  buttonPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isShowing: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showErrorPlace: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

// @ts-expect-error TS(2345): Argument of type '({ config, palette, ...theme }: ... Remove this comment to see the full error message
export default withStyles(SelectClasses, { withTheme: true })(MultiSelect);
