import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { DialogContent, Grid as MuiGrid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

// actions
import { deleteDocumentById } from '../../api/DocumentsService';
// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
import { useToggleModal } from '../../utils/modal.utils';

// components
import CBButton from '../Buttons/CbButton';

// constants
import { queries } from '../../queries';

export const useDeleteDocument = () => {
  const toggleModal = useToggleModal();

  return (docId: any) =>
    toggleModal.direct(
      'DeleteDocument',
      { docId },
      { title: 'Delete document', maxWidth: 'md' }
    );
};

export const DeleteDocument = ({ data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const classes = styles();
  const queryClient = useQueryClient();
  const { handleSubmit, ...methods } = useForm({});
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    const { docId } = data;
    deleteDocumentById({ docId })
      .then(() => {
        enqueueSnackbar('Deleted the document successfully', {
          variant: 'success',
        });

        delayedEvent('table-refetch', 500, 'UWDocuments');
        queryClient.invalidateQueries(queries.accounts.list._def);

        toggleModal.direct('DeleteDocument', false);
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete the document', { variant: 'error' });
      });
  };

  const handleCancel = () => {
    toggleModal.direct('DeleteDocument', false);
  };

  return (
    // @ts-expect-error TS(2741): Property 'handleSubmit' is missing in type '{ chil... Remove this comment to see the full error message
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent classes={{ root: classes.container }}>
          <MuiGrid>
            <p className={classes.description}>
              Are you sure you want to delete the document?
            </p>
          </MuiGrid>
        </DialogContent>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            styleName="ctaButton"
            buttonText="Delete"
            // @ts-expect-error TS(2339): Property 'declineButton' does not exist on type 'C... Remove this comment to see the full error message
            customClass={classes.declineButton}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

const styles = makeStyles(({ palette }) => ({
  description: {
    fontSize: '1.167rem',
    color: palette.primary.main,
    lineHeight: 1.5,
    fontStyle: 'italic',
    textAlign: 'center',
    margin: '0 0 2rem 0 !important',
  },
  container: {
    flex: '1 1 auto',
    padding: '0 5rem 0.5rem 5rem',
    'overflow-y': 'visible',
  },
}));
