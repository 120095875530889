import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import { Api } from '../../config/axiosConfig';
import { accountServiceMappings } from '../../accounts/accountServiceMappings';

export const fetchDAs = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/da/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});
export const createDa = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/da',
    method: 'post',
  },
});

export const addAgencyDa = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/agency',
    method: 'post',
    paramSchema: accountServiceMappings,
  },
});

export const updateDa = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/da/:id',
    method: 'put',
  },
});

export const updateDaDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/da',
    method: 'put',
  },
});

export const deleteDa = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/da/:id',
    method: 'delete',
  },
});
export function enterDa(id: any) {
  return Api.post(`/api/auth/v1/da/${id}/enter`);
}
export const getDaDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/da`,
  },
  isCancelable: true,
});
export const setDaDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/da',
    method: 'put',
  },
  isCancelable: true,
});

export function postDaDetails(agency: any) {
  return Api.post(' /api/account/v1/agency', agency);
}
