import { Box } from '@mui/material';

import { debounce } from 'lodash';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@mui/styles';
import { reduceForSelect } from '../../../console/admin/users/userUtils';
import { AutoCompleteBase } from './AutoCompleteBase';

const minuteAsMS = 1000 * 60;

export const ManagedFetchingAutoComplete = ({
  fetchFunc,
  itemsToSelectOptionsTransformer = reduceForSelect,
  queryKey,
  textFieldProps = {},
  options: passedOptions,
  ...props
}: any) => {
  const classes = useStyles();
  const [input, setInput] = React.useState('');

  const { data: availableOptions } = useQuery(
    [...queryKey, { input, passedOptions }],
    () =>
      fetchFunc({ search: input }).then((data: any) => {
        const transformedOptions = itemsToSelectOptionsTransformer(data);

        // merge the queried options and options passed as props
        if (passedOptions && Array.isArray(passedOptions)) {
          const optionValueDict = transformedOptions.reduce(
            // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
            (acc, curr) => ({
              ...acc,
              [curr.value]: true,
            }),
            {}
          );

          const passedOptionsUniqueFromQueriedOptions = passedOptions.filter(
            (opt) => !(opt.value in optionValueDict)
          );

          return [
            ...transformedOptions,
            ...passedOptionsUniqueFromQueriedOptions,
          ];
        }

        return transformedOptions;
      }),
    {
      placeholderData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1 * minuteAsMS,
    }
  );

  const handleInputChange = debounce((event, newValue) => {
    setInput(newValue);
  }, 300);

  function handleSelected(event: any, selectedOption: any) {
    props.onChange(selectedOption);
  }

  return (
    <AutoCompleteBase
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      {...props}
      options={availableOptions}
      onInputChange={handleInputChange}
      onChange={handleSelected}
      textFieldProps={{ classes, ...textFieldProps }}
      renderOption={(optionProps: any, option: any) => {
        return (
          <li {...optionProps}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Box component="li" classes={classes}>
              {option.label}
            </Box>
          </li>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
});
