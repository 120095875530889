import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
// utils

import { Card, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';

import { delayedEvent } from '../../../../utils/eventUtils';
// services
import { deleteClaim } from '../../_services/claims.service';
import CBButton from '../../../../components/Buttons/CbButton';
import { useToggleModal } from '../../../../utils/modal.utils';

export const ClaimDeleteConfirm = withStyles(
  ({ palette }) => ({
    card: {
      outline: 'none',
      padding: '0',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: 'inherit',
    },
    cardContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      width: '750px',
      height: '150px',
      margin: 'auto',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    insideWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    dataWrapper: {
      width: '100%',
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: '17px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      marginTop: 50,
      color: palette.text.link,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 35,
    },
    color: {
      opacity: 0.9,
    },
    text: {
      color: palette.primary.title,
      fontSize: '17px',
    },
  }),
  { withTheme: true }
  // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
)(({ classes, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const [disabled, setDisabled] = useState(false);

  const onConfirm = () => {
    setDisabled(true);
    deleteClaim({ claimId: data.id })
      .then(() => {
        toggleModal.direct('ClaimDeleteConfirm', false);
        enqueueSnackbar('Claim successfully removed', { variant: 'success' });
        delayedEvent('table-refetch', 500, 'claims');
        setDisabled(false);
      })
      .catch((error: any) => {
        enqueueSnackbar('Failed to delete claim', { variant: 'error' });
        setDisabled(false);
      });
  };
  return (
    <div className={classes.color}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.title}>
            Are you sure you want to remove the claim
            <span className={classes.text}> {data.claimNumber}</span>?
          </div>
        </CardContent>
      </Card>
      <div className={classes.buttonBox}>
        <CBButton
          styleName="cancel"
          action={toggleModal.deferred('ClaimDeleteConfirm', false)}
          color="secondary"
          buttonText="Cancel"
        />
        <CBButton
          disabled={disabled}
          onClick={onConfirm}
          type="submit"
          color="primary"
          styleName="ctaButton"
          buttonText="Delete Claim"
        />
      </div>
    </div>
  );
});
