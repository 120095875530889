import { PureComponent } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { DialogContent, FormHelperText } from '@mui/material';

import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { formatAsPhone } from '../../utils';

import { assignAccount } from '../../accounts/AccountService';
import { PubSub } from '../../utils/eventUtils';
import { getAgentInfo } from '../../agencies/AgencyService';
import { withActor } from '../hocs/withActor';
import { withToggleModal } from '../hocs/withToggleModal';

class SetMyAgentConfirm extends PureComponent {
  approveQuoteHandler: any;

  subscription: any;

  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      error: '',
      agentData: {},
    };
  }

  componentDidMount() {
    this.subscription = PubSub.subscribe(
      'foot-button-one',
      (eventData: any) => {
        if (eventData) {
          this.handleSubmit();
        }
      }
    );
    // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.props.data.getAgent) {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      getAgentInfo(this.props.data.agencyId).then((resp) => {
        this.setState({ agentData: resp.data });
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  render() {
    const { props } = this;
    // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { classes } = props;
    // @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { error } = this.state;
    const {
      name,
      contactEmail,
      address1,
      city,
      state,
      zipCode,
      contactPhone,
      contactName,
      agentName,
      agentEmail,
      agentPhone,
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    } = props.data;

    let header = '';
    let footer = '';

    // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'string'.
    header = (
      <div className={`${classes.header}`}>
        Are you sure you want to remove{' '}
        <span className={classes.blueText}>{name}</span>?
      </div>
    );
    footer = `If you remove ${name}, you may receive quotes from other agencies.`;

    const agencyInfo = ['Name:', 'Address:', ''];

    const agencyInfoValues = [
      name,
      address1 || '',
      address1 ? city + state + zipCode : '',
    ];

    const agentInfo = ['Name:', 'Email:', 'Contact:'];
    const agentInfoValues = [
      // @ts-expect-error TS(2339): Property 'agentData' does not exist on type 'Reado... Remove this comment to see the full error message
      contactName || agentName || this.state.agentData.agentName,
      // @ts-expect-error TS(2339): Property 'agentData' does not exist on type 'Reado... Remove this comment to see the full error message
      contactEmail || agentEmail || this.state.agentData.agentEmail,
      formatAsPhone(contactPhone) ||
        formatAsPhone(agentPhone) ||
        // @ts-expect-error TS(2339): Property 'agentData' does not exist on type 'Reado... Remove this comment to see the full error message
        formatAsPhone(this.state.agentData.agentPhone),
    ];

    return (
      <DialogContent>
        <form
          className="modal-title"
          style={{ width: 600 }}
          onSubmit={this.approveQuoteHandler}
          id="submit-form"
        >
          {header}
          <div
            className={classes.wrapper}
            style={{ textAlign: 'initial', paddingBottom: 10 }}
          >
            <div>
              <span style={{ fontWeight: 600 }}>AGENCY DETAILS</span>
              <div className={classes.statsLabel} style={{ marginRight: 50 }}>
                <div className={classes.dataWrapper}>
                  {agencyInfo.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
                <div className={classes.statsValue}>
                  {agencyInfoValues.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
              </div>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>AGENT DETAILS</span>

              <div className={classes.statsLabel}>
                <div className={classes.dataWrapper}>
                  {agentInfo.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
                <div className={classes.statsValue}>
                  {agentInfoValues.map((factor) => {
                    return <div key={factor}>{factor}</div>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <FormHelperText className="api-text-error">{error}</FormHelperText>
          <span
            className="modal-blue-text"
            style={{ fontWeight: 600, textAlign: 'center' }}
          >
            {footer}
          </span>
        </form>
      </DialogContent>
    );
  }

  handleSubmit() {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);

    const { props } = this;
    // @ts-expect-error TS(2339): Property 'actor' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { actor } = props;

    const {
      agencyId,
      linking,
      name,
      contactPhone,
      contactFirstName,
      contactEmail,
      contactLastName,
      contactName,
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    } = props.data;

    // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { utils } = props.data;

    const { agentFirstName, agentLastName, agentEmail, agentPhone, agentName } =
      // @ts-expect-error TS(2339): Property 'agentData' does not exist on type 'Reado... Remove this comment to see the full error message
      this.state.agentData;

    assignAccount(actor.account.id, {
      agencyId,
      agencyName: name,
      customerFirstName: actor.firstName,
      customerLastName: actor.lastName,
      customerEmail: actor.email,
      customerPhoneNumber: actor.phone,
      agentFirstName: contactFirstName || agentFirstName,
      agentLastName: contactLastName || agentLastName,
      agentEmail: contactEmail || agentEmail,
      agentPhoneNumber: contactPhone || agentPhone,
      agentName: contactName || agentName,
      link: linking,
    })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('SetMyAgentConfirm', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar(
          `Agent ${name} has been Removed Successfully!`,
          { variant: 'success' }
        );
        utils.setInterestedAgencies([
          utils.myAgent,
          ...utils.interestedAgencies,
        ]);
        utils.setMyAgent(null);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({
          error: _.get(
            error,
            'response.data',
            'There was an error with your request'
          ),
        });
      });
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SetMyAgentConfirm.defaultProps = {
  data: {},
};

const styles = ({ palette, config, ...theme }: any) => {
  return {
    header: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.13,
      textAlign: 'center',
      marginBottom: 30,
    },
    card: {
      maxWidth: '100%',
      minheight: '25vh',
      textAlign: 'center',
      backgroundColor: 'inherit',
    },
    cardContent: {
      padding: theme.spacing(5),
      color: palette.text.secondary,
    },
    footer: {
      padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    },
    title: {
      fontFamily: 'Titillium Web',
      fontSize: '15px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: palette.text.link,
      marginTop: 50,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 20,
    },
    blueText: {
      color: palette.common.cowbellLight,
    },
    greenText: {
      color: palette.common.cowbellGreen2,
    },
    textCenter: {
      textAlign: 'center',
      marginBottom: 20,
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dataWrapper: {
      marginRight: '10px',
      textAlign: 'left',
      fontWeight: 300,
      display: 'flex',
      flexDirection: 'column',
    },
    statsValue: {
      textAlignLast: 'left',
      display: 'flex',
      flexDirection: 'column',
    },
    statsLabel: {
      display: 'flex',
    },
  };
};

export default compose(
  withToggleModal,
  withActor,
  withSnackbar,
  // @ts-expect-error TS(2345): Argument of type '({ palette, config, ...theme }: ... Remove this comment to see the full error message
  withStyles(styles)
)(SetMyAgentConfirm);
