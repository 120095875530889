import React from 'react';

const SVG = ({
  id = '',
  style = {},
  fill1 = '#9192a4',
  fill2 = '#9192a4',
  width = '100%',
  className = '',
  viewBox = '0 0 30 30',
  title = 'Cowbell Logo',
}: any) => (
  <svg
    fill={fill1}
    width={width}
    style={style}
    className={`svg ${className}`}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .svg-cowbell-1 { fill: none; }
          .${id}-svg-fill-cowbell-1 { fill: ${fill1}; }
          .${id}-svg-fill-cowbell-2 { fill: ${fill2}; }
        `}
      </style>
    </defs>
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Isolation_Mode" data-name="Isolation Mode">
        <rect className="svg-cowbell-1" width="30" height="30" />
        <path
          className={`${id}-svg-fill-cowbell-1`}
          d="M5.44,18.22l10.82,2.53a1.26,1.26,0,0,0,.27,0,1.2,1.2,0,0,0,1.11-.74L23.72,5.47a.76.76,0,0,0,0-.72.75.75,0,0,0-.62-.35l-4.54-.29a1,1,0,0,0-.69.28L5.07,16.92a.73.73,0,0,0,.37,1.3ZM18.52,5l4.29.27-6,14.41a.29.29,0,0,1-.27.18L5.89,17.39Z"
        />
        <path
          className={`${id}-svg-fill-cowbell-1`}
          d="M25.91,7.77l-.66-1.7a.57.57,0,0,0-.52-.43.56.56,0,0,0-.51.41l-5.9,14.14a1.57,1.57,0,0,0,.16,1.51l1.76,2.53a.81.81,0,0,0,.66.41c.12,0,.52,0,.69-.64L26,8.64A1.44,1.44,0,0,0,25.91,7.77ZM20.8,23.46l-1.58-2.28a.68.68,0,0,1-.07-.65L24.72,7.2l.34.9a.44.44,0,0,1,0,.3Z"
        />
        <path
          className={`${id}-svg-fill-cowbell-2`}
          d="M4.19,18.74h-.1s-.08,0-.08,0,0,.07,0,.17L6,22.22s0,.08.1.08a.15.15,0,0,0,.11-.06l2.27-2.55Z"
        />
        <path
          className={`${id}-svg-fill-cowbell-2`}
          d="M17.8,22.16a.87.87,0,0,0-.5-.34l-1.72-.4,0,0a2.41,2.41,0,0,1-4.27-1l-2.15-.51L6.83,22.63a.13.13,0,0,0,0,.12.13.13,0,0,0,.11.07l13.22,3.05.12,0a.12.12,0,0,0,.08,0,.26.26,0,0,0-.07-.18Z"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
