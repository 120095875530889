import { yupResolver } from '@hookform/resolvers/yup';
import Moment from 'moment';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// mui
import {
  Grid as MuiGrid,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
// components
import { RegisteredAgentAutoComplete } from '../../accounts/agentUpdate/AgentsAutoComplete';
import { withFormController } from '../hocs/forms';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { SimpleSelect } from '../inputs/selects/SimpleSelect';
import CBButton from '../Buttons/CbButton';
// actions
import {
  createAgentLicense,
  updateAgentLicense,
} from '../../console/agencies/_services/licenses.service';
// helpers
import { delayedEvent } from '../../utils/eventUtils';
import { UsStatesFull } from '../../utils/USState';

const SSelect = withFormController(SimpleSelect);
const TextField = withFormController(TextFieldBase);
const today = Moment().format('YYYY-MM-DD');

const schema = Yup.object().shape({
  agentId: Yup.string().required().label('Agent'),
  number: Yup.string().required().label('License#'),
  state: Yup.string().required().label('State'),
  expirationDate: Yup.date().required().label('Expiration Date'),
});

export const AgentLicenseCreateEdit = ({ data, close }: any) => {
  const editing = Boolean(data.id);
  const { enqueueSnackbar } = useSnackbar();

  const [backendError, setBackendError] = useState('');

  const { handleSubmit, getValues, watch, ...methods } = useForm({
    defaultValues: {
      number: data.number || '',
      state: data.state || '',
      agentId: data.agentId || '',
      agentEmail: data.agentEmail || '',
      agentFirstName: data.agentFirstName || '',
      agentLastName: data.agentLastName || '',
      agentPhone: data.agentPhone || '',
      npn: data.npn || '',
      expirationDate: data
        ? Moment(data.expirationDate).format('YYYY-MM-DD')
        : '',
    },
    resolver: yupResolver(schema),
  });

  const values = getValues();

  useEffect(() => {
    methods.register('agentEmail');
    methods.register('agentFirstName');
    methods.register('agentLastName');
    methods.register('npn');
    methods.register('agentPhone');
    watch([
      'agentId',
      'agentEmail',
      'agentFirstName',
      'agentLastName',
      'npn',
      'agentPhone',
    ]);

    // eslint-disable-next-line
  }, []);

  const {
    formState: { isSubmitting },
  } = methods;

  const handleSelect = React.useCallback(
    (selectedOption: any) => {
      methods.setValue('agentId', selectedOption.value);
      methods.setValue('agentFirstName', selectedOption.meta.firstName);
      methods.setValue('agentLastName', selectedOption.meta.lastName);
      methods.setValue('agentEmail', selectedOption.meta.email);
      methods.setValue('agentPhone', selectedOption.meta.phone);
      methods.setValue('npn', _.get(selectedOption, 'meta.npn', ''));
    },
    // eslint-disable-next-line
    []
  );

  const onSubmit = ({ expirationDate, ...formData }: any) => {
    const payload = {
      ...formData,
      id: data.id,
      agencyName: data.agency.name,
      expirationDate: Moment(expirationDate).toISOString(),
    };

    const func = editing ? updateAgentLicense : createAgentLicense;
    return func({}, payload)
      .then(() => {
        enqueueSnackbar(
          `License ${formData.number} ${
            editing ? 'Updated' : 'Created'
          } successfully`,
          {
            variant: 'success',
          }
        );
        makeRefreshCall();
        close();
      })
      .catch((error: any) => {
        setBackendError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Not able to add license at this time.Please try again later.'
            )
          )
        );
      });
  };

  return (
    // @ts-expect-error TS(2739): Type '{ children: Element; getFieldState: UseFormG... Remove this comment to see the full error message
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: '1.33rem' }}>
          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <RegisteredAgentAutoComplete
                name="agentId"
                fetchOnMount
                onChange={handleSelect}
                required
                labelProps={{}}
                value={
                  values.agentEmail
                    ? `${_.get(values, 'agentFirstName', '')} ${_.get(
                        values,
                        'agentLastName',
                        ''
                      )} (${_.get(values, 'agentEmail', '')})`
                    : ''
                }
                error={
                  !values.agentId &&
                  _.get(methods.formState.errors, 'agentId.message')
                }
              />
            </MuiGrid>
            <MuiGrid item md={6}>
              <SSelect
                required
                label="State"
                name="state"
                options={UsStatesFull}
              />
            </MuiGrid>
          </MuiGrid>

          <MuiGrid container spacing={6}>
            <MuiGrid item md={6}>
              <TextField name="number" label="Licensed #" required />
            </MuiGrid>
            <MuiGrid item md={6}>
              <TextField
                name="expirationDate"
                label="Expiration Date"
                required
                type="date"
                InputProps={{ inputProps: { min: today } }}
              />
            </MuiGrid>
          </MuiGrid>
          <FormHelperText className="api-text-error">
            {backendError}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <CBButton
            size="medium"
            styleName="cancel"
            onClick={close}
            buttonText="Cancel"
          />
          <CBButton
            type="submit"
            styleName="ctaButton"
            loading={isSubmitting}
            disabled={isSubmitting}
            buttonText={editing ? 'Update License' : 'Add License'}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

function makeRefreshCall() {
  delayedEvent('table-refetch', 500, 'agent_license');
}

export const AgentLicenseCreateEditConfig = {
  AgentLicenseCreateEdit: {
    component: AgentLicenseCreateEdit,
    config: {
      maxWidth: 'sm',
      fullWidth: true,
      PaperProps: { style: { overflow: 'visible' } },
    },
  },
};
