import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
// material ui
import { DialogActions, DialogContent } from '@mui/material';
// platform helpers

import { PubSub } from '../../utils/eventUtils';

// components
import CbButton from '../Buttons/CbButton';
// actions
import {
  updateOrgProfile,
  updateSecurityQuestions,
  updateSocialEngg,
} from '../../accounts/AccountService';
import { ProductTypes } from '../../types';
import { useToggleModal } from '../../utils/modal.utils';

const apiMappings = {
  firmo: updateOrgProfile,
  questions: updateSecurityQuestions,
  social: updateSocialEngg,
};

// @ts-expect-error TS(2339): Property 'close' does not exist on type 'RouteComp... Remove this comment to see the full error message
export const InvalidateQuotes = withRouter(({ location, close, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toggleModal = useToggleModal();

  const history = useHistory();
  const product = location.pathname.split('/')[5];

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = () => {
    const payload = _.omit(data, 'update');

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const api = apiMappings[data.update];

    return api({}, payload)
      .then(() => {
        close();
        PubSub.publish('disable-Submit', false);
        PubSub.publish('quote-invalidated', true);
        PubSub.publish('fetch-details', true);
        PubSub.publish('org:agent-attested', {
          isVerifiedByAgent: false,
        });

        if (data.reset) {
          data.reset(payload);
        }
        enqueueSnackbar('Account Details Updated Successfully.', {
          variant: 'success',
        });
        if (product !== ProductTypes.p250) {
          toggleModal.direct(
            'InvalidateQuotesConfirm',
            {},
            { title: `All Quotes Invalidated`, maxWidth: 'md' }
          );
        } else {
          history.push('/customer/policies');
        }
      })
      .catch(() => {
        enqueueSnackbar(
          'Not able to update account details at this time.Please try again later',
          { variant: 'error' }
        );
      });
  };

  const onCancel = () => {
    close();
    PubSub.publish('disable-Submit', false);
    PubSub.publish('cancel-detailChanges', true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent
        className="modal-title"
        style={{
          fontSize: '1.33rem',
          textAlign: 'left',
          paddingBottom: '1rem',
        }}
      >
        <p>
          Are you sure you want to move forward?{' '}
          {product === ProductTypes.p250 &&
            'The quote will be referred to Underwriting for review.'}
          {product === ProductTypes.p100 &&
            'The changes you have made will invalidate all impacted quotes including this one.'}
        </p>
        {product === ProductTypes.p250 && (
          <p>Note: Your changes may update the quote premium!</p>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton buttonText="Cancel" action={onCancel} styleName="cancel" />
        <CbButton
          type="submit"
          buttonText="Make Changes"
          disabled={isSubmitting}
          loading={isSubmitting}
          styleName="ctaButton"
        />
      </DialogActions>
    </form>
  );
});

export default InvalidateQuotes;
