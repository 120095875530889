import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// helpers
import _ from 'lodash';
import Moment from 'moment';
// mui
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

// utils
import { saveBlobFile } from '../../utils/appUtils';
// actions
import { downloadAWSDoc, getCommissionStatementUrl } from './CommissionService';
import { checkAgencyBilledInvoice } from '../../console/customers/_services/BillingService';
import { downloadDocument } from '../../api/DocumentsService';
// components
import CbButton from '../../components/Buttons/CbButton';
import { MultiSelect } from '../../components/Selects';

const currentYear = Moment().year();
const currentMonth = Moment().month();

const DownloadStatements = ({ classes, close, data }: any) => {
  const { download } = data;

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const yearOptions = (() => {
    const lowest = 2020;
    const result = [];
    const range = 7;
    for (let i = 0; i < range; i += 1) {
      const temp = currentYear - i;
      if (temp >= lowest) {
        result.push({
          label: temp,
          value: temp,
        });
      } else {
        break;
      }
    }
    return result;
  })();

  const currentYearMonthOptions = () => {
    const result = [];
    for (let i = 0; i <= currentMonth; i += 1) {
      result.push({
        label: Moment().month(i).format('MMMM'),
        value: i,
      });
    }
    return result;
  };

  const [myData, setMyData] = useState({
    ready: false,
    url: null,
    myOption: getPreviousMonthOption(),
    monthOptions: currentYearMonthOptions(),
  });

  function handleFindDocError(error: any) {
    setLoading(false);
    setMyData((prev) => ({
      ...prev,
      ready: false,
      url: null,
    }));

    if (error.response?.status !== 404) {
      setError('Something went wrong. Please try again later.');
    }
  }

  const checkStatement = () => {
    setLoading(true);
    if (download === 'invoice') {
      checkAgencyBilledInvoice({
        month: myData.myOption.month.value + 1,
        year: myData.myOption.year.value,
      })
        .then((resp: any) => {
          setLoading(false);
          const url = _.get(resp, 'data.docId', '');
          setDisabled(!url);
          setMyData((prevState) => {
            return {
              ...prevState,
              url,
              ready: true,
            };
          });
        })
        .catch(handleFindDocError);
    } else {
      getCommissionStatementUrl({
        year: myData.myOption.year.value,
        month: myData.myOption.month.value,
      })
        .then((response: any) => {
          setLoading(false);
          const url = _.get(response, 'data');
          setDisabled(!url);
          setMyData((prevState) => {
            return {
              ...prevState,
              url,
              ready: true,
            };
          });
        })
        .catch(handleFindDocError);
    }
  };

  useEffect(() => {
    if (!myData.ready) {
      checkStatement();
    }
    // eslint-disable-next-line
  }, []);

  const selectYearHandler = (name: any, option: any) => {
    const { myOption } = myData;
    myOption.year = {
      label: option.label,
      value: option.value,
    };

    let months: any = null;
    if (option.value === currentYear) {
      months = currentYearMonthOptions();
    } else {
      months = monthOptions;
    }

    setMyData((prevState) => {
      return {
        ...prevState,
        myOption,
        monthOptions: months,
      };
    });
    checkStatement();
  };

  const selectMonthHandler = (name: any, option: any) => {
    const { myOption } = myData;
    myOption.month = {
      label: option.label,
      value: option.value,
    };
    setMyData((prevState) => {
      return {
        ...prevState,
        myOption,
      };
    });
    checkStatement();
  };

  const onSubmit = () => {
    if (download === 'invoice') {
      downloadDocument({ docId: myData.url })
        .then((response: any) => {
          const url = _.get(response, 'data');

          downloadAWSDoc(url)
            .then((resp) => {
              saveBlobFile(
                resp,
                `${myData.myOption.year.value}-${myData.myOption.month.label}-Agency-Billed-Invoice`,
                '.pdf'
              );
            })
            .catch(() => {
              setError(
                'Unable to download at this time. Please try again later.'
              );
            });
        })
        .catch(() => {
          setError('Unable to download at this time. Please try again later.');
        });
    } else {
      downloadAWSDoc(myData.url)
        .then((response) => {
          saveBlobFile(
            response,
            `${myData.myOption.year.value}-${myData.myOption.month.label}-Commission-Statement`,
            '.pdf'
          );
          close();
        })
        .catch(() => {
          setError('Unable to download at this time. Please try again later.');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <section className={classes.dropdown}>
          <MultiSelect
            label="Pick a year"
            name="year"
            isMulti={false}
            options={yearOptions}
            onChange={selectYearHandler}
            values={myData.myOption.year}
            required
            classes={{
              select: classes.button,
            }}
          />
        </section>
        <section className={classes.dropdown}>
          <MultiSelect
            label="Pick a month"
            name="month"
            isMulti={false}
            options={myData.monthOptions}
            onChange={selectMonthHandler}
            values={myData.myOption.month}
            required
            classes={{
              select: classes.button,
            }}
          />
        </section>
        {!myData.url && !loading && !error && (
          <section className={classes.warningContainer}>
            <img
              src="images/VerifiedExclamationNo.svg"
              className={classes.warningIcon}
              alt="Not Verified warning icon"
            />
            <p className={classes.limitReach}>
              No {download === 'invoice' ? 'invoice' : 'statements'} found for
              this month.
            </p>
          </section>
        )}
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
        <CbButton
          type="submit"
          styleName="ctaButton"
          buttonText="Download"
          loading={isSubmitting || loading}
          disabled={isSubmitting || disabled || !myData.url}
        />
      </DialogActions>
    </form>
  );
};

const styles = ({ palette }: any) => {
  return {
    warningIcon: {
      width: '20px',
      margin: '10px 5px',
    },
    warningContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    tagContainer: {
      width: '10.83rem',
      height: '2.5rem',
      borderRadius: 16,
      border: `1.3px solid ${palette.primary.main}`,
      textAlign: 'center',
      paddingTop: '0.5rem',
      margin: '0.5rem 0.4rem',
      float: 'left',
      cursor: 'pointer',
    },
    container: {
      width: '35rem',
      height: '15rem',
      margin: 'auto',
      textAlign: 'center',
    },
    tagSelected: {
      border: `1.3px solid ${palette.primary.oldBorder2}`,
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { warningI... Remove this comment to see the full error message
export default withStyles(styles)(DownloadStatements);

const monthOptions = [
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'February',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];

const getPreviousMonthOption = () => {
  const now = Moment();
  return {
    month: {
      label: now.subtract(1, 'months').format('MMMM'),
      value: now.month(),
    },
    year: {
      label: now.year(),
      value: now.format('YYYY'),
    },
  };
};
