import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ManagedTypeAheadBase } from './ManagedTypeAhead';

/**
 * @deprecated
 */
export const RegisteredTypeAhead = ({ onChange, ...props }: any) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    register(props.name, { required: props.required });
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback(
    (selectedOption: any) => {
      if (typeof onChange === 'function') {
        onChange(selectedOption);
      } else {
        setValue(props.name, selectedOption);
      }
    },
    // eslint-disable-next-line
    [onChange, props.name]
  );

  const handleInputChange = useCallback(
    (nextInputValue: any) => {
      setValue(props.name, nextInputValue);
    },
    // eslint-disable-next-line
    [props.name]
  );

  return (
    <ManagedTypeAheadBase
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...props}
    />
  );
};
