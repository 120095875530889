import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
// components
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DialogContent, DialogActions, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TextFieldBase } from '../../components/inputs/TextFieldBase';
import { withFormController } from '../../components/hocs/forms';
import CBButton from '../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../utils/storeUtils';
import { securityStudioConnect } from './ConnectorService';
import { PubSub } from '../../utils/eventUtils';
import { CONNECTOR_TYPES } from './connectors.constants';

// helpers

const TextField = withFormController(TextFieldBase);
const schema = Yup.object().shape({
  token: Yup.string().required('Token is required'),
});

const SecurityStudioTokenForm = ({ classes, data }: any) => {
  const { cbid } = data;
  const defaultValues = {
    token: '',
  };

  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formData: any) => {
    return securityStudioConnect(
      {},
      {
        data: {
          connector: CONNECTOR_TYPES.SECURITYSTUDIO,
          provider: 'COWBELL',
          credentials: {
            ...formData,
          },
          cbid,
          metadata: null,
        },
      }
    )
      .then(() => {
        toggleModalDirect('SecurityStudioTokenForm', false);
        PubSub.publish('connector-page-refetch', true);
      })
      .catch(console.error.bind(console));
  };

  const handleCancel = () => {
    toggleModalDirect('SecurityStudioTokenForm', false);
  };

  return (
    <section className={classes.cardContentContainer}>
      <FormProvider {...methods} handleSubmit={handleSubmit}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.description}>
            Get the refresh token for your account from securitystudio.com
          </p>
          <DialogContent classes={{ root: classes.container }}>
            <Grid
              container
              justifyContent="left"
              className={classes.inputFields}
            >
              <Grid item md={10}>
                <TextField
                  name="token"
                  label="Enter Token"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Connect"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const styles = ({ palette }: any) => {
  return {
    description: {
      fontSize: '1.167rem',
      color: palette.primary.main,
      lineHeight: 1.5,
      fontStyle: 'italic',
      textAlign: 'center',
      margin: '0 0 2rem 0 !important',
    },
    container: {
      flex: '1 1 auto',
      padding: '0 5rem 0.5rem 5rem',
      'overflow-y': 'visible',
    },
    inputFields: {
      justifyContent: 'space-around',
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { descript... Remove this comment to see the full error message
export default withStyles(styles)(SecurityStudioTokenForm);
