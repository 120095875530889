import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { PubSub, delayedEvent } from '../../utils/eventUtils';

import { deleteState } from '../../states/StateService';
import { withToggleModal } from '../hocs/withToggleModal';

class StateDeleteConfirm extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      id: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      props: { data },
    } = this;

    this.setState({
      id: data.id,
      stateName: data.stateName,
      stateNameAbbrv: data.stateNameAbbrv,
    });
    this.subscription = PubSub.subscribe(
      'foot-button-one',
      (eventData: any) => {
        if (eventData) {
          this.handleSubmit();
        }
      }
    );
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteState({
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Readonly<{}>... Remove this comment to see the full error message
      id: this.state.id,
    })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('StateDeleteConfirm', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar('State Deleted Successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'states');
      })
      .catch((error: any) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: error.response.data });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          You are about to remove the State -
          <span className="modal-blue-text">
            {/* @ts-expect-error TS(2339): Property 'stateName' does not exist on type 'Reado... Remove this comment to see the full error message */}
            {this.state.stateName} ({this.state.stateNameAbbrv})
          </span>
          .<p style={{ margin: 0 }}>Are you sure you want to delete it?</p>
        </div>
        <FormHelperText className="api-text-error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

export default compose(withSnackbar, withToggleModal)(StateDeleteConfirm);
