import { getPlatformRegion } from '../utils';

const platformLanguage = getPlatformRegion();

export default (state: any) => {
  return {
    ...state,
    uiSettings: {
      ...state.uiSettings,
      platformLanguage,
    },
  };
};
