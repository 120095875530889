import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
// ui
// components
import { FormProvider, useForm } from 'react-hook-form';
import { DialogContent, Grid, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import CBButton from '../../../../../components/Buttons/CbButton';
import { withFormController } from '../../../../../components/hocs/forms';
import { TextFieldBase } from '../../../../../components/inputs/TextFieldBase';
import { TextAreaBase } from '../../../../../components/inputs/TextAreaBase';
import { setPrime250Ui } from '../../../../_reducers/prime250.reducer';
import { PubSub } from '../../../../../utils/eventUtils';
import { createIRPMNote } from '../../../../../api/p250.service';
import { moneyFormat2Decimals } from '../../../../../utils/appUtils';

const TextArea = withFormController(TextAreaBase);

const TextField = withFormController(TextFieldBase);
const getTotal = (obj: any) => {
  return _.reduce(
    obj,
    (result, value) => {
      if (value) {
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        const temp = +(parseFloat(result) + parseFloat(value)).toFixed(10);
        return temp;
      }
      return result;
    },
    0.0
  );
};

export const UWIrpmModal = ({ data, ...props }: any) => {
  const classes = useClasses();
  const {
    irpmMap,
    dispatch,
    state,
    minVal,
    maxVal,
    quoteId,
    accountName,
    accountId,
    quoteNumber,
    initialTotalPremium,
    initialIRPM,
    irpmRiskCharacteristics,
    isSurplus,
    numberOfEmployees,
    revenue,
    irpmNote,
  } = data;
  const { enqueueSnackbar } = useSnackbar();
  const [newTotalPremium, setNewTotalPremium] = React.useState('');

  const schema = React.useMemo(() => {
    return Yup.object().shape(
      _.mapValues(irpmRiskCharacteristics, (risk, key) => {
        const { min, max } = irpmMap[key];
        return (
          Yup.string()
            .ensure()
            // @ts-expect-error TS(2345): Argument of type '(this: TestContext, inputStr: an... Remove this comment to see the full error message
            .test(key, 'Invalid', (inputStr) => {
              if (!inputStr) {
                return true;
              }
              const input = +inputStr;
              if (_.isNumber(input)) {
                return (
                  input >= parseFloat(min) * 100 &&
                  input <= parseFloat(max) * 100
                );
              }
            })
        );
      })
    );
  }, [irpmMap, irpmRiskCharacteristics]);

  const defaultValues = { ...irpmRiskCharacteristics, irpmNote };
  const { handleSubmit, ...methods } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    getValues,
    watch,
    control,
    formState: { isSubmitting },
  } = methods;
  const values = getValues();
  const watchAllFields = watch();
  const [total, setTotal] = useState(getTotal(irpmRiskCharacteristics) || '');

  const blurHandler = React.useMemo(() => {
    return () => {
      let newTotal = 0;
      _.forEach(irpmMap, (risk, key) => {
        if (values[key]) {
          // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          newTotal = +(parseFloat(newTotal) + parseFloat(values[key])).toFixed(
            10
          );
        }
      });

      if (_.isFinite(newTotal)) {
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        const newIrpmVal = parseFloat(1 + newTotal / 100).toFixed(10);
        dispatch(
          setPrime250Ui({
            // @ts-expect-error TS(2345): Argument of type '{ irpm: string; shouldRecalculat... Remove this comment to see the full error message
            irpm: newIrpmVal,
            shouldRecalculate: Moment().unix(),
          })
        );
        PubSub.publish('updateIRPM', newIrpmVal);
        setTotal(newTotal);
      } else {
        setTotal('');
      }
    };
    // eslint-disable-next-line
  }, [values, watchAllFields]);

  React.useEffect(() => {
    const sub = PubSub.subscribe('newTotalPremium', (eventData: any) => {
      setNewTotalPremium(eventData);
    });
    return () => {
      sub.remove();
    };
  }, []);

  const onSubmit = (formData: any) => {
    const irpmRiskCharacteristicsObj = {
      companyStability: formData.companyStability
        ? parseFloat(formData.companyStability)
        : '',
      dcmPractices: formData.dcmPractices
        ? parseFloat(formData.dcmPractices)
        : '',
      drPlanning: formData.drPlanning ? parseFloat(formData.drPlanning) : '',
      securityAwareness: formData.securityAwareness
        ? parseFloat(formData.securityAwareness)
        : '',
      financialCondition: formData.financialCondition
        ? parseFloat(formData.financialCondition)
        : '',
      managementExperience: formData.managementExperience
        ? parseFloat(formData.managementExperience)
        : '',
      managementOfContent: formData.managementOfContent
        ? parseFloat(formData.managementOfContent)
        : '',
    };

    const payload = {
      accountId,
      accountName,
      quoteId,
      quoteNumber,
      irpmNote: formData.irpmNote,
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      irpmModifier: parseFloat(1 + total / 100).toFixed(10),
      ...irpmRiskCharacteristicsObj,
      numberOfEmployees,
      revenue,
    };

    return createIRPMNote({ isSurplus }, payload)
      .then(() => {
        toggleModalDirect('UWIrpmModal', false);
        dispatch(
          setPrime250Ui({
            // @ts-expect-error TS(2345): Argument of type '{ irpmRiskCharacteristics: { com... Remove this comment to see the full error message
            irpmRiskCharacteristics: irpmRiskCharacteristicsObj,
            irpmNote: formData.irpmNote,
          })
        );
      })
      .catch(() => {
        enqueueSnackbar('Failed to save note', { variant: 'error' });
        toggleModalDirect('UWIrpmModal', false);
      });
  };

  const handleCancel = () => {
    dispatch(
      setPrime250Ui({
        // @ts-expect-error TS(2345): Argument of type '{ irpm: any; shouldRecalculate: ... Remove this comment to see the full error message
        irpm: initialIRPM,
        shouldRecalculate: Moment().unix(),
      })
    );
    PubSub.publish('updateIRPM', initialIRPM);
    toggleModalDirect('UWIrpmModal', false);
  };

  const renderRisks = React.useCallback(() => {
    let count = 0;
    const mappings = {
      managementOfContent: 'Management of Content',
      dcmPractices: 'Data Collection and Management Practices',
      companyStability: 'Company Stability',
      drPlanning: 'Disaster Recovery Planning',
      financialCondition: 'Financial Condition',
      securityAwareness: 'Employee Security Awareness',
      managementExperience: 'Management Experience',
    };
    return _.map(irpmMap, (risk, riskName) => {
      const { min, max } = risk;
      count += 1;
      return (
        // @ts-expect-error TS(2551): Property 'riskContainer' does not exist on type 'C... Remove this comment to see the full error message
        <section className={classes.riskContainer}>
          <Grid container>
            <Grid item md={6} className="flex align-left">
              {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {count}.{mappings[riskName]}
            </Grid>
            <Grid item md={2} className="flex align-left">
              ({min * 100}%)
            </Grid>
            <Grid item md={2} className="flex align-left">
              ({max * 100}%)
            </Grid>
            <Grid item md={2} className="flex align-right">
              <Grid container className={classes.valueColContainer}>
                <Grid item md={6} className="flex align-left">
                  <TextField
                    name={riskName}
                    className={classes.inputField}
                    InputProps={{
                      classes: { input: classes.inputBase },
                    }}
                    onBlur={blurHandler}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} className="flex align-left">
                  {values[riskName] && values[riskName] <= 0 && (
                    <span className={classes.greenColor}>
                      %&nbsp;&nbsp;&nbsp;CREDIT
                    </span>
                  )}
                  {values[riskName] && values[riskName] > 0 && (
                    <span className={classes.redColor}>
                      %&nbsp;&nbsp;&nbsp;DEBIT
                    </span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      );
    });
    // eslint-disable-next-line
  }, [control, irpmMap, methods, values, watchAllFields]);

  return (
    <section>
      <FormProvider {...methods} handleSubmit={handleSubmit}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent classes={{ root: classes.container }}>
            <Grid container className={classes.risksHeaderContainer}>
              <Grid item md={6} className="flex align-left">
                RISK CHARACTERISTIC
              </Grid>
              <Grid item md={2} className="flex align-left">
                MIN IRPM
              </Grid>
              <Grid item md={2} className="flex align-left">
                MAX IRPM
              </Grid>
              <Grid item md={2} className="flex align-left">
                VALUE
              </Grid>
            </Grid>
            <section className={classes.risksContainer}>
              {renderRisks()}
            </section>
            <Grid container className={classes.stateLine}>
              <Grid item md={5} className="flex align-left">
                {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                STATE - {UsStatesObjects[state].label}
              </Grid>
              {minVal ? (
                <Grid item md={2} className="flex align-left">
                  (-{minVal * 100}%)
                </Grid>
              ) : (
                <Grid item md={2} className="flex align-left" />
              )}
              {maxVal ? (
                <Grid item md={2} className="flex align-left">
                  ({((maxVal - 1.0) * 100).toFixed(10)}%)
                </Grid>
              ) : (
                <Grid item md={2} className="flex align-left" />
              )}
              <Grid item md={3} className="flex align-left">
                TOTAL {total}%
              </Grid>
            </Grid>
            <Grid container className={classes.totalLine}>
              <Grid item md={2} className="flex align-left">
                IRPM NOTE
              </Grid>
              <Grid
                item
                md={4}
                className={`flex align-left ${classes.currentPremium}`}
              >
                CURRENT PREMIUM {moneyFormat2Decimals(initialTotalPremium)}
              </Grid>
              <Grid
                item
                md={3}
                className="flex align-left"
                style={{ fontWeight: 600 }}
              >
                NEW PREMIUM{' '}
                {newTotalPremium ? moneyFormat2Decimals(newTotalPremium) : ''}
              </Grid>
              <Grid item md={3} className="flex align-left">
                {/* @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message */}
                (MODIFIER {parseFloat(1 + total / 100).toFixed(10)})
              </Grid>
            </Grid>
            <Grid container>
              <TextArea
                className={classes.textAreaRoot}
                maxRows={6}
                minRows={10}
                name="irpmNote"
                fullWidth
                multiline
                // @ts-expect-error TS(2322): Type '{ control: Control<any, any>; }' is not assi... Remove this comment to see the full error message
                controllerProps={{ control }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <CBButton action={handleCancel} styleName="cancel">
              Cancel
            </CBButton>
            <CBButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
              buttonText="Save Changes"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </section>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  valueColContainer: {
    display: 'flex',
  },
  greenColor: {
    color: palette.primary.green,
    fontSize: '1.167rem',
    lineHeight: 1.5,
    marginLeft: '0.25rem',
  },
  redColor: {
    color: palette.text.text9,
    fontSize: '1.167rem',
    lineHeight: 1.5,
    marginLeft: '0.25rem',
  },
  stateLine: {
    borderBottom: `1px solid ${palette.primary.main}`,
    padding: '1rem 0 1rem 0',
    minWidth: '59rem',
    fontSize: '1.33rem',
  },
  totalLine: {
    padding: '1rem 0 1rem 0',
    minWidth: '59rem',
    fontSize: '1.33rem',
  },
  container: {
    maxHeight: '45rem',
    overflow: 'auto',
    paddingBottom: '1rem',
    color: palette.primary.contrastText,
  },
  risksContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${palette.primary.main}`,
    padding: '1rem 0 1rem 0',
  },
  textAreaRoot: {
    width: '100%',
    borderRadius: 5,
    fontSize: '1rem',
    lineHeight: '1.33',
    color: palette.primary.main,
    height: '9.5rem',
    border: `0.8px solid ${palette.text.secondary}`,
    background: palette.background.darkerBlue,
  },
  currentPremium: {
    fontWeight: 600,
    color: palette.text.primary,
  },
  risksHeaderContainer: {
    fontSize: '1.33rem',
    fontWeight: 600,
    lineHeight: 1.38,
    textAlign: 'left',
    color: palette.primary.main,
  },
  inputField: {
    float: 'right',
  },
  inputBase: {
    padding: '0.35rem !important',
    height: '1rem',
    textAlign: 'center',
    color: palette.primary.main,
    fontSize: '1.167rem',
  },
}));
