import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// platform helpers
import { compose } from 'redux';
import { FormHelperText, DialogContent } from '@mui/material';
import { delayedEvent, PubSub } from '../../utils/eventUtils';

import { AGENCY_QUOTES_LISTING_STORE_ID } from '../../reducers/UiSettingsReducers';
// actions
import { deleteALlInvalidQuotes } from '../../inbox/QuotesService';
import { withToggleModal } from '../hocs/withToggleModal';

class DeleteInvalidConfirm extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = { error: '' };
  }

  componentDidMount() {
    this.subscription = PubSub.subscribe('foot-button-one', (data: any) => {
      if (data) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteALlInvalidQuotes()
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('DeleteInvalidConfirm', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar(
          'Submitted Request to Delete All Invalid Quotes!',
          { variant: 'success' }
        );
        delayedEvent('quote:read', 5000, '');
        delayedEvent('table-refetch', 5000, AGENCY_QUOTES_LISTING_STORE_ID);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: error.response.data });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to delete
          <span className="modal-blue-text"> {'All Invalid\t'} </span> Quotes?
        </div>
        <FormHelperText className="api-text-error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

export default compose(withSnackbar, withToggleModal)(DeleteInvalidConfirm);
