import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import CbButton from '../Buttons/CbButton';

export const InvalidateQuotesConfirm = ({ close }: any) => {
  return (
    <>
      <DialogContent
        className="modal-title"
        style={{
          fontSize: '1.33rem',
          textAlign: 'left',
          paddingBottom: '1rem',
        }}
      >
        With the changes you made, all impacted quotes have been invalidated.
        <p>Please contact your agent to Re-Quote.</p>
      </DialogContent>
      <DialogActions>
        <CbButton
          type="submit"
          buttonText="Ok"
          onClick={close}
          styleName="ctaButton"
        />
      </DialogActions>
    </>
  );
};

export default InvalidateQuotesConfirm;
