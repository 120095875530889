import _ from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import {
  Grid as MuiGrid,
  styled,
  TextField,
  Button,
  Tooltip,
} from '@mui/material';
import Numeral from 'numeral';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { FakeBar } from './fakebar.chart';
import { Modal } from '../../../components/modals/v2/helpers/v2.modal.helpers';
import { setPrime100Ui } from '../../_reducers/prime100.reducer';
import { PubSub } from '../../../utils/eventUtils';
import { AdditionalInsuredModalConfig } from '../../../components/modals/v2/AdditionalInsuredModal';
import TooltipCheckbox from '../../../components/inputs/TooltipCheckbox';

export const CoverageChart = ({
  data = {},
  editable = false,
  options,
  renderSublimit,
  renderRansomlimit,
  renderExtortionLimit,
  renderSocialLimit,
  renderSocialDeductible,
  renderWebsiteLimit,
  social,
  website,
  readOnly,
  endorsement = false,
  coverageEligibility,
}: any) => {
  const classes = styles({
    isRenewal: data.isRenewal,
    isMigration: data.isMigration,
  });

  const aggregateLimit = data.limit;
  const biee = data.businessIncomeCoverage / data.limit;
  const se = social ? data.socialEngLimit / data.limit : 0;
  const rp = data.ransomPaymentLimit / data.limit;

  const wm = website
    ? data.websiteMediaContentLiabilitySubLimit / data.limit
    : 0;
  const prime100Ui = useSelector(({ prime100 }) => prime100.ui);
  const dispatch = useDispatch();

  const handleAISubmit = useAdditionalInsuredSubmission({ dispatch });
  const eligibleOptions = useCoverageEligibility(coverageEligibility, options);

  const grouped = React.useMemo(() => {
    return fp.flow(
      fp.filter((coverage) => coverage),
      fp.sortBy('order'),
      fp.groupBy('groupName')
    )(eligibleOptions);
  }, [eligibleOptions]);

  return (
    <div className={classes.chartContainer}>
      <MuiGrid container>
        {Object.keys(grouped).map((groupName) => {
          return (
            <React.Fragment key={groupName}>
              <GroupHeader>{groupName}</GroupHeader>

              {Object.values(grouped[groupName]).map((option) => {
                const checkboxClasses = classnames({
                  // @ts-expect-error TS(2339): Property 'mandatory' does not exist on type '{ toS... Remove this comment to see the full error message
                  mandatory: option.mandatory,
                });
                // TODO Trevor, we need to clean this.
                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                if (option.id === 'BI_EXTRA_EXPENSE') {
                  // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                  option.percentage = biee;
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                } else if (option.id === 'SOCIAL_ENGINEERING') {
                  // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                  option.percentage = _.isNaN(se) ? 0 : se;
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                } else if (option.id === 'COMPUTER_FUNDS_TRANSFER_FRAUD') {
                  // @ts-expect-error TS(2339): Property 'defaultChecked' does not exist on type '... Remove this comment to see the full error message
                  option.defaultChecked = data.computerFraudEndorsement;
                  if (data.computerFraudEndorsement) {
                    // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                    option.percentage = 1;
                  }
                } else if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'EXTORTION_THREATS_AND_RANSOM_PAYMENT'
                ) {
                  // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                  option.percentage = _.isNaN(rp) ? 0 : rp;
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                } else if (option.id === 'WEBSITE_MEDIA_CONTENT_LIABILITY') {
                  // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                  option.percentage = _.isNaN(wm) ? 0 : wm;
                }

                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                if (option.id === 'sublimit' && _.isFunction(renderSublimit)) {
                  return renderSublimit({ classes });
                }
                if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'socialLimit' &&
                  _.isFunction(renderSocialLimit)
                ) {
                  return renderSocialLimit({ classes });
                }
                if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'socialDeductible' &&
                  _.isFunction(renderSocialDeductible)
                ) {
                  return renderSocialDeductible({ classes });
                }
                if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'ransomLimit' &&
                  _.isFunction(renderRansomlimit)
                ) {
                  return renderRansomlimit({ classes });
                }
                if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'extortionThreatsLimitDetail' &&
                  _.isFunction(renderExtortionLimit)
                ) {
                  return renderExtortionLimit({ classes });
                }
                if (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  option.id === 'websiteLimit' &&
                  _.isFunction(renderWebsiteLimit)
                ) {
                  return renderWebsiteLimit({ classes });
                }
                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                if (option.id === 'ADDITIONAL_INSURED' && !endorsement) {
                  // @ts-expect-error TS(2339): Property 'checked' does not exist on type '{ toStr... Remove this comment to see the full error message
                  const AIcount = _.get(option.checked, 'length', 0);
                  // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ toSt... Remove this comment to see the full error message
                  option.disabled = !!readOnly;

                  return (
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                    <React.Fragment key={option.id}>
                      <MuiGrid item sm={7}>
                        <TooltipCheckbox
                          {...option}
                          // @ts-expect-error TS(2322): Type '{ disabled: any; checked: any; className: an... Remove this comment to see the full error message
                          disabled={
                            readOnly && AIcount > 0
                              ? // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ toSt... Remove this comment to see the full error message
                                !option.disabled
                              : // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ toSt... Remove this comment to see the full error message
                                option.disabled || !editable
                          }
                          checked={
                            readOnly
                              ? AIcount > 0
                              : // @ts-expect-error TS(2339): Property 'checked' does not exist on type '{ toStr... Remove this comment to see the full error message
                                option.checked ||
                                prime100Ui.additionalInsuredList.length
                          }
                          className={checkboxClasses}
                          // @ts-expect-error TS(2339): Property 'mandatory' does not exist on type '{ toS... Remove this comment to see the full error message
                          variant={!option.mandatory ? 'base' : 'filled'}
                        />
                      </MuiGrid>
                      <MuiGrid
                        container
                        item
                        sm={5}
                        justifyContent={
                          readOnly ? 'flex-start' : 'space-evenly'
                        }
                      >
                        {!readOnly && (
                          <MuiGrid item sm={6}>
                            <AddEditButton
                              variant="outlined"
                              size="small"
                              onClick={Modal.deferShow(
                                AdditionalInsuredModalConfig,
                                {
                                  data: {
                                    insureds: prime100Ui.additionalInsuredList,
                                    onSubmit: handleAISubmit,
                                  },
                                }
                              )}
                            >
                              ADD/EDIT
                            </AddEditButton>
                          </MuiGrid>
                        )}
                        <MuiGrid item sm={6} className="flex align-right">
                          <TextField
                            variant="standard"
                            name="additionalInsureds"
                            value={
                              readOnly
                                ? AIcount
                                : prime100Ui.additionalInsuredList.length
                            }
                            classes={{
                              root: classes.inputField,
                            }}
                            InputProps={{
                              classes: { input: classes.inputBase },
                            }}
                            disabled
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </React.Fragment>
                  );
                }

                // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                if (option.id === 'ADDITIONAL_INSURED' && endorsement) {
                  return null;
                }

                return (
                  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ toString: ... Remove this comment to see the full error message
                  <MuiGrid container key={option.id}>
                    <MuiGrid item sm={7} className={classes.gridItem}>
                      <TooltipCheckbox
                        {...option}
                        // @ts-expect-error TS(2322): Type '{ disabled: any; onClick: any; ref: any; cla... Remove this comment to see the full error message
                        disabled={
                          // @ts-expect-error TS(2339): Property 'checked' does not exist on type '{ toStr... Remove this comment to see the full error message
                          readOnly && option.checked
                            ? // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ toSt... Remove this comment to see the full error message
                              !option.disabled
                            : // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ toSt... Remove this comment to see the full error message
                              option.disabled || !editable
                        }
                        onClick={
                          // @ts-expect-error TS(2339): Property 'modalName' does not exist on type '{ toS... Remove this comment to see the full error message
                          option.modalName &&
                          // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{ toStr... Remove this comment to see the full error message
                          option.onClick &&
                          // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{ toStr... Remove this comment to see the full error message
                          option.onClick.bind(this, option.modalName)
                        }
                        // @ts-expect-error TS(2339): Property 'register' does not exist on type '{ toSt... Remove this comment to see the full error message
                        ref={option?.register?.(option.name)}
                        className={checkboxClasses}
                        label={
                          // @ts-expect-error TS(2746): This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
                          <Tooltip title={option.tooltip} disableFocusListener>
                            {/* @ts-expect-error: FIXME */}
                            <span>{option.label}</span>
                          </Tooltip>
                        }
                        // @ts-expect-error TS(2339): Property 'mandatory' does not exist on type '{ toS... Remove this comment to see the full error message
                        variant={!option.mandatory ? 'base' : 'filled'}
                      />
                    </MuiGrid>

                    <MuiGrid item sm={5} className={classes.gridItem}>
                      <FakeBar
                        // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                        value={option.percentage * 100}
                        aggregate={data.limit}
                        // @ts-expect-error TS(2339): Property 'percentage' does not exist on type '{ to... Remove this comment to see the full error message
                        percentage={option.percentage}
                        readOnly={readOnly}
                      />
                    </MuiGrid>

                    <div className={classes.chartAxis}>
                      <i
                        className={`${classes.axis} label-first label-left`}
                        data-label="0"
                      />
                      <i
                        className={`${classes.axis} label-last label-left`}
                        data-label={Numeral(aggregateLimit).format('0a')}
                      />
                    </div>
                  </MuiGrid>
                );
              })}
            </React.Fragment>
          );
        })}
      </MuiGrid>
    </div>
  );
};

const styles = makeStyles(({ config, palette }) => ({
  chartContainer: {
    position: 'relative',
  },

  chartAxis: {
    width: '41.666667%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
  },

  axis: {
    height: 'calc(100% - 1.5rem)',
    position: 'absolute',
    top: '-1.5rem',
    borderRight: '1px dashed #9492A4',
    fontSize: config.textSizes.petite,
    transition: 'left 300ms ease-out',

    '&:before': {
      content: 'attr(data-label)',
      position: 'absolute',
      lineHeight: 0.9,
      right: 5,
    },

    '&.label-first': {
      left: 0,
    },

    '&.label-last': {
      right: 0,
    },
  },

  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },

  inputBase: {
    padding: '0.35rem !important',
    height: '1rem',
    textAlign: 'center',
    background: (props) => {
      // @ts-expect-error TS(2339): Property 'isRenewal' does not exist on type '{}'.
      if (props.isRenewal || props.isMigration) {
        return `${palette.drawer.background} !important`;
      }
    },
  },
  inputField: {
    width: '4rem',
    height: '2rem',
    textAlign: 'center',
    fontSize: '1.167rem',
    color: palette.primary.main,
    marginTop: '0.1rem',
  },
}));

const GroupHeader = styled(MuiGrid)(({ theme }) => ({
  padding: '2rem 0 1rem 2rem',
  color: theme.palette.primary.contrastText,

  fontSize: theme.config.textSizes.tertia,

  fontWeight: theme.config.weights.bold,
}));

const AddEditButton = styled(Button)(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,

  borderColor: palette.common.cowbellBlue,
}));

function useAdditionalInsuredSubmission({ dispatch }: any) {
  return function handleSubmit({ insureds }: any) {
    return new Promise((resolve) => {
      dispatch(
        setPrime100Ui({
          additionalInsuredList: insureds,
        })
      );

      PubSub.publish('change:AI', insureds.length);

      // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
      resolve();
    });
  };
}

const useCoverageEligibility = (coverageEligibility: any, options: any) => {
  if (!coverageEligibility) {
    return options;
  }

  const {
    computerFundsTransfer,
    hardwareReplCostEndorsement,
    postBreachRemediationEndorsement,
    socialEngEndorsement,
    telecomsFraudEndorsement,
    websiteMediaContentLiabilityEndorsement,
  } = coverageEligibility;

  return options.map((option: any) => {
    switch (option.id) {
      case 'COMPUTER_FUNDS_TRANSFER_FRAUD':
        return { ...option, disabled: !computerFundsTransfer.isAvailable };

      case 'SOCIAL_ENGINEERING':
        return { ...option, disabled: !socialEngEndorsement.isAvailable };

      case 'EXTORTION_THREATS_AND_RANSOM_PAYMENT':
        return {
          ...option,
          disabled: true,
        };

      case 'WEBSITE_MEDIA_CONTENT_LIABILITY':
        return {
          ...option,
          disabled: !websiteMediaContentLiabilityEndorsement.isAvailable,
        };

      case 'HARDWARE_REPLACEMENT_COSTS':
        return {
          ...option,
          disabled: !hardwareReplCostEndorsement.isAvailable,
        };

      case 'POST_BREACH_REMEDIATION':
        return {
          ...option,
          disabled: !postBreachRemediationEndorsement.isAvailable,
        };

      case 'TELECOM_FRAUD':
        return { ...option, disabled: !telecomsFraudEndorsement.isAvailable };

      default:
        return option;
    }
  });
};
