import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getAllMessages, getLabels, getMessageByMsgId } from '../api/cmail.api';
import { getData } from '../utils/functional/fp';

export const cmail = createQueryKeys('cmail', {
  detail: (msgId: string) => ({
    queryKey: ['message-details', msgId],
    queryFn: () =>
      getMessageByMsgId({ params: { msgId } }).then((resp) => {
        return resp.data;
      }),
  }),

  list: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => getAllMessages({ params: filters }),
  }),

  labels: () => ({
    queryKey: ['labels'],
    queryFn: () => getLabels().then(getData),
  }),
});
