import React from 'react';
import { TextareaAutosize } from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';

export const TextAreaBase = ({
  error = undefined,
  className = '',
  blueText = false,
  editing = false,
  fullWidth = false,
  blueBackground = true,
  ...props
}) => {
  const myClasses = styles({ blueBackground });
  const classNames = classnames(myClasses.root, className, {
    [myClasses.blueText]: blueText,
    [myClasses.contrastText]: editing === true,
    [myClasses.fullWidth]: fullWidth,
  });
  const id = props.id || props.name;

  return (
    <TextareaAutosize
      className={classNames}
      id={id}
      error={Boolean(error)}
      {...props}
      // @ts-expect-error TS(2322): Type '{ inputProps: any; className: any; id: any; ... Remove this comment to see the full error message
      inputProps={{ ...props.inputProps, error: Boolean(error) }}
    />
  );
};

const styles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 5,
    fontSize: '1rem',
    lineHeight: '1.33',
    color: palette.primary.main,
    width: '35rem',
    height: '9.5rem',
    border: `0.8px solid ${palette.text.secondary}`,
    background: (props) =>
      // @ts-expect-error TS(2339): Property 'blueBackground' does not exist on type '... Remove this comment to see the full error message
      props.blueBackground
        ? palette.background.darkerBlue
        : palette.background.active,
  },

  fullWidth: {
    width: '100%',
  },

  blueText: {
    color: `${palette.tabs.profile} !important`,
  },

  contrastText: {
    color: `${palette.primary.contrastText} !important`,
  },
}));
