import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import PublicComponentWrapper from '../../wrappers/PublicComponentWrapper';
import { checkSignupEmailToken } from '../../../api/SignupService';
import { submitJoinExistingAccount } from '../../../users/UserActions';
import SignupEmailExisting from './SignupEmailExisting';
import SignupEmailNew from './SignupEmailNew';

/* Components */

class SignupEmailLanding extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      badToken: false,
      errorStatus: null,
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
      match: { params },
    } = this.props;

    checkSignupEmailToken(params.token)
      .then((resp: any) => {
        this.setState({ data: resp.data });
      })
      .catch((error: any) => {
        if (error?.response?.status) {
          this.setState({ badToken: true, errorStatus: error.response.status });
        }
      });
  }

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
      match: { params },
    } = this.props;

    // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { data } = this.state;

    const tokenType = _.get(data, 'tokenType', undefined);
    const redirectUrl = _.get(data, 'redirectURL');
    return (
      <PublicComponentWrapper>
        {/* @ts-expect-error TS(2339): Property 'badToken' does not exist on type 'Readon... Remove this comment to see the full error message */}
        {this.state.badToken && this.state.errorStatus && (
          // @ts-expect-error TS(2339): Property 'errorStatus' does not exist on type 'Rea... Remove this comment to see the full error message
          <div>{handleErrorMessage(this.state.errorStatus)}</div>
        )}
        {tokenType === 'NEW_ACCOUNT' && (
          <SignupEmailNew
            token={params.token}
            accountType={data.accountType}
            // @ts-expect-error TS(2339): Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
            history={this.props.history}
          />
        )}
        {tokenType === 'EXISTING_ACCOUNT' && (
          <SignupEmailExisting
            // @ts-expect-error TS2322: Type '{ token: any; data: any; submitJoinExistingAccount: any; }' is not assignable to...
            token={params.token}
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
            data={this.state.data}
            // @ts-expect-error TS(2339): Property 'submitJoinExistingAccount' does not exis... Remove this comment to see the full error message
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
          />
        )}
        {tokenType === 'EXISTING_USER' &&
          // @ts-expect-error TS(2339): Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.props.history.push({
            pathname: '/login',
            state: { existingUser: true },
          })}
        {tokenType === 'QUOTE_SENT_NEW_USER' && (
          <SignupEmailExisting
            // @ts-expect-error TS2322: Type '{ token: any; data: any; submitJoinExistingAccount: any; }' is not assignable to...
            token={params.token}
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
            data={this.state.data}
            // @ts-expect-error TS(2339): Property 'submitJoinExistingAccount' does not exis... Remove this comment to see the full error message
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
            redirectUrl={redirectUrl}
          />
        )}
        {tokenType === 'QUOTE_SENT_EXISTING_USER' &&
          // @ts-expect-error TS(2339): Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.props.history.push({
            pathname: '/login',
            state: { existingUserForQuote: true, redirectUrl },
          })}
        {tokenType === 'QUOTE_LINK_NEW_AGENT' && (
          <SignupEmailExisting
            // @ts-expect-error TS2322: Type '{ token: any; data: any; submitJoinExistingAccount: any; }' is not assignable to...
            token={params.token}
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
            data={this.state.data}
            // @ts-expect-error TS(2339): Property 'submitJoinExistingAccount' does not exis... Remove this comment to see the full error message
            submitJoinExistingAccount={this.props.submitJoinExistingAccount}
            redirectUrl={_.isUndefined(redirectUrl) ? null : redirectUrl}
          />
        )}
        {tokenType === 'QUOTE_LINK_EXISTING_AGENT' &&
          // @ts-expect-error TS(2339): Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.props.history.push({
            pathname: '/login',
            state: { existingAgentForQuote: true, redirectUrl },
          })}
      </PublicComponentWrapper>
    );
  }
}

const handleErrorMessage = (status: any) => {
  if (status === 400 || status === 404) {
    return (
      <div>
        <Typography variant="body1" align="center" color="error">
          Link Expired/Not Found!
        </Typography>
        <div>
          <Typography variant="body1" color="inherit" align="center">
            To get a new link , please contact Cowbell Support Team.
            <br />
            If you already have an account? <Link to="/login">Login here</Link>
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1" align="center">
        Opps! {'\n'} Looks like your credentials have expired. <br />
        Contact your account admin to get a new link.
      </Typography>
      <div className="main-entry-signuplanding-error-link">
        <Typography variant="body1" color="inherit" align="center">
          If not an existing user,
          <Link to="/signup" style={{ color: 'inherit' }}>
            {' '}
            signup here
          </Link>
        </Typography>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ submitJoinExistingAccount }, dispatch);
};

export default connect(null, mapDispatchToProps)(SignupEmailLanding);
