import { useDispatch } from 'react-redux';
import { createAction } from 'redux-actions';

// @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
const toggleModal = createAction('UI_TOGGLE_MODAL', (key, content, config) => ({
  key,
  content,
  config,
}));

/**
 * useToggleModal
 * @deprecated use new modal system Modal.show
 */
export const useToggleModal = () => {
  const dispatch = useDispatch();

  return {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    direct: (...args: any[]) => dispatch(toggleModal(...args)),
    deferred:
      (...args: any[]) =>
      () =>
        // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
        dispatch(toggleModal(...args)),
  };
};
