import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { delayedEvent } from '../../utils/eventUtils';
import { togglePayments } from '../../console/admin/pay/PaymentService';
import CbButton from '../Buttons/CbButton';
import { REDACTED_FIELD } from '../../config/logrocket';

export const AdminToggleAgencyPayments = ({ close, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { agencyId, agencyName, pausedPayment } = data;
  const actionText = `${pausedPayment ? 'Resume' : 'Pause'} Payments`;

  const onSubmit = () => {
    togglePayments(agencyId)
      .then(() => {
        enqueueSnackbar(`${actionText} successful.`, { variant: 'success' });
        delayedEvent('table-refetch', 500, 'outstandingbalances');
        close();
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(error.response, 'data', 'Something went wrong while saving.')
          )
        );
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <div className="modal-title">
          {`Are you sure you want to ${actionText} for `}
          <span className={`${REDACTED_FIELD} modal-blue-text`}>
            {agencyName}
          </span>{' '}
          ?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText={actionText}
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export default AdminToggleAgencyPayments;
