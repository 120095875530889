import React from 'react';
import {
  EVENT_BUS_ACCOUNT_SWITCH_REQUESTED,
  EVENT_BUS_JUMP_REQUESTED,
  JumpEventBus,
} from '../_events/jump.bus';

import type { Persona } from '../console/_statics/persona.statics';
import { PERSONA_TYPES } from '../console/_statics/persona.statics';
import type { Languages } from '../i18n/i18n.language-config';
import { languages } from '../i18n/i18n.language-config';
import { usePlatformLanguage } from './hooks/usePlatformLanguage';

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export function jumpRequested(
  accountType: Persona,
  accountId: string,
  accountName?: string
) {
  JumpEventBus.publish(EVENT_BUS_JUMP_REQUESTED, {
    accountType,
    accountId,
    accountName,
  });
}

export function onJumpRequested(
  ...args: [Persona, string, string | undefined]
) {
  return () => jumpRequested(...args);
}

export function accountSwitchRequested(
  accountType: Persona,
  accountId: string,
  accountName?: string
) {
  JumpEventBus.publish(EVENT_BUS_ACCOUNT_SWITCH_REQUESTED, {
    accountType,
    accountId,
    accountName,
  });
}

export function onAccountSwitchRequested(
  ...args: [Persona, string, string | undefined]
) {
  return () => accountSwitchRequested(...args);
}

/**
 * @name exchangePersonaForPath
 * @description Instead of defining many apis for jump token exchange, we defined one and swap a path parameter
 */
export function exchangePersonaForPath(persona: Persona) {
  switch (persona) {
    case PERSONA_TYPES.AGENCY:
      return 'admin/agencies';
    case PERSONA_TYPES.CUSTOMER:
      return 'accounts';
    case PERSONA_TYPES.DIGITAL:
      return 'da';
    case PERSONA_TYPES.MSSP:
      return 'mssp';
    case PERSONA_TYPES.CARRIER:
      return 'carrier';
    case PERSONA_TYPES.VENDOR:
      return 'vendor';
    case PERSONA_TYPES.CLUSTER:
      return 'cluster';
    case PERSONA_TYPES.RE_INSURER:
      return 'reinsurer';
    case PERSONA_TYPES.PREMIUM_PROCESSOR:
      return 'premiumprocessor';
    default:
      return null;
  }
}

//TODO Make a hoc for class based component
export const getPlatformRegion = (): Languages => {
  const url = new URL(window.location.href);

  if (url.hostname.endsWith('.co.uk')) {
    return languages['en-GB'];
  }

  return languages['en-US'];
};

export const withPlatformRegion = <P extends object>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    const region = usePlatformLanguage();

    return <Component {...props} region={region} />;
  };
};

export const getIsUsPlatform = (region: Languages) =>
  region === languages['en-US'];

export function isPromise<T = unknown>(
  val: Promise<T> | unknown
): val is Promise<T> {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  return typeof val?.then == 'function';
}
