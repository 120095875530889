import { delayedEvent } from '../utils/eventUtils';
import { retrieveAuthTokens } from '../utils/next/auth.utils';
import WebWorker from './workerSetup';

const INTERVAL_SECONDS = 60;

const versionWorker = () => {
  onmessage = (event) => {
    // @ts-expect-error TS(2339): Property 'origin' does not exist on type 'EventTar... Remove this comment to see the full error message
    const { origin } = event.target;

    const options = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${event.data}`,
      },
    };

    fetch(`${origin}/api/console-version`, options)
      .then((response) => response.json())
      .then((data) => {
        postMessage(data);
      })
      .catch(console.error.bind(console));
  };
};

const VersionLayer = {
  init() {
    const worker = new WebWorker(versionWorker);
    const tokenInterval = setInterval(() => {
      const { accessToken } = retrieveAuthTokens();

      if (!accessToken) {
        // @ts-expect-error TS(2339): Property 'terminate' does not exist on type 'WebWo... Remove this comment to see the full error message
        worker.terminate();
        clearInterval(tokenInterval);
        return;
      }

      // @ts-expect-error TS(2339): Property 'postMessage' does not exist on type 'Web... Remove this comment to see the full error message
      worker.postMessage(accessToken);
    }, INTERVAL_SECONDS * 1000);

    // @ts-expect-error TS(2339): Property 'onmessage' does not exist on type 'WebWo... Remove this comment to see the full error message
    worker.onmessage = (event: any) => {
      const { v } = event.data;
      if (v === 'SERVER_DOWN') {
        delayedEvent('server-down', 100, v);
      } else {
        delayedEvent('version-changed', 100, v);
      }
    };
  },
};

export default VersionLayer;
