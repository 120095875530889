import { StrictMode } from 'react';
import './config/init.config';

import { createRoot } from 'react-dom/client';

import GlobalProviders from './providers/GlobalProviders';
import { GlobalErrorBoundary } from './components/error-boundaries/GlobalErrorBoundary';

import { BrowserUnsupported } from './components/main/common/BrowserUnsupported';
import { BrowserMappings } from './components/main/common/BrowserMappings';

import './_lib/validations/string.validations';
import './config/numeral/Numeral';

import App from './App';
import 'typeface-oswald';
import 'typeface-titillium-web';
import './i18n/i18n';

const $root = document.getElementById('root') as HTMLElement;

const browser = BrowserMappings();
if (browser === 'Microsoft Internet Explorer') {
  createRoot($root).render(
    <StrictMode>
      <GlobalProviders>
        <BrowserUnsupported />
      </GlobalProviders>
    </StrictMode>
  );
} else {
  createRoot($root).render(
    <GlobalProviders>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </GlobalProviders>
  );
}
