import React from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import Highlight from 'react-highlighter';
// components
import { makeStyles } from '@mui/styles';
import { TypeAheadBase } from '../../components/inputs/autocomplete';
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';
// services
import { getAutocompleteAccounts } from '../AccountService';

const defaultLabelProps = {
  style: { padding: 0, fontSize: '1rem' },
};

export const AccountLookupField = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'show' does not exist on type '{}'.
    show = true,
    // @ts-expect-error TS(2339): Property 'defaultOptions' does not exist on type '... Remove this comment to see the full error message
    defaultOptions,
    // @ts-expect-error TS(2339): Property 'state' does not exist on type '{}'.
    state,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'labelProps' does not exist on type '{}'.
    labelProps = defaultLabelProps,
    ...props
  }) => {
    if (!show) {
      return null;
    }
    const classes = useStyles();
    const inputClasses = classnames({
      [classes.inline]: labelProps.inline,
    });

    const { setValue } = useFormContext();
    const [options, setOptions] = React.useState(defaultOptions);

    function handleChange(newValue: any) {
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
      setValue(props.name, newValue);
    }

    const onFetch = React.useCallback(
      ({ input }: any, callback: any) => {
        getAutocompleteAccounts({ state, search: input }).then(
          ({ data }: any) => {
            callback(
              data.content.map((account: any) => ({
                label: `${account.name} (${account.address1}, ${account.state})`,
                value: account.name,
                meta: account,
              }))
            );
          }
        );
      },
      [state]
    );

    function AccountOption({ option, state: { inputValue } }: any) {
      const class2 = useOptionStyles();

      if (typeof option === 'string') {
        return null;
      }

      return (
        <div
          style={{ width: '100%', position: 'relative', paddingBottom: '10' }}
        >
          <p
            // @ts-expect-error TS(2339): Property 'option' does not exist on type 'ClassNam... Remove this comment to see the full error message
            className={class2.option}
            style={{ margin: '0', fontWeight: 'bold' }}
          >
            <Highlight search={inputValue}>{option.meta.name}</Highlight>
          </p>
          <p style={{ margin: 0 }} className={class2.optionText}>
            {option.meta.address1} {option.meta.city} {option.meta.state}
          </p>
        </div>
      );
    }

    function onOptionsChange(newOptions: any) {
      setOptions(newOptions);
    }

    return (
      <>
        <InputLabelBase
          indent
          // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
          htmlFor={props.name}
          // @ts-expect-error TS(2339): Property 'required' does not exist on type '{}'.
          required={props.required}
          {...labelProps}
        >
          {label || 'Name'}
        </InputLabelBase>
        <TypeAheadBase
          id="accountName"
          options={options}
          onChange={handleChange}
          onOptionsChange={onOptionsChange}
          onFetch={onFetch}
          className={inputClasses}
          renderOption={(
            optionProps: any,
            option: any,
            typeAheadState: any
          ) => (
            <li {...optionProps}>
              <AccountOption option={option} state={typeAheadState} />
            </li>
          )}
          {...props}
        />
      </>
    );
  }
);

const useStyles = makeStyles(() => ({
  inline: {
    '& input': {
      padding: '6px !important',
    },
  },
}));

const useOptionStyles = makeStyles(({ palette }) => ({
  optionTitle: {
    color: palette.primary.contrastText,
    fontSize: '1.1666666667rem',
    fontWeight: 400,
    margin: 0,
  },
  optionText: {
    display: 'block',
    fontSize: '1rem',
  },
}));
