import React from 'react';
import { Box } from '@mui/material';

import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

const CBCopyToClipboard = ({ classes, text }: any) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let lastSnackBarKey: any;

  const copyToClipboard = () => {
    const input = document.getElementById(text);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    input.select();
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    input.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand('copy');
    closeSnackbar(lastSnackBarKey);
    lastSnackBarKey = enqueueSnackbar('Copied to Clipboard Successfully', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  return (
    <Box className={classes.mainContainer} onClick={copyToClipboard}>
      <div className={classes.innerSquare} />
      <input
        type="text"
        value={text}
        className={classes.hiddenInput}
        id={text}
      />
    </Box>
  );
};

const styles = ({ palette }: any) => {
  return {
    hiddenInput: {
      position: 'absolute',
      left: '-999px',
    },
    mainContainer: {
      width: '1.2rem',
      height: '1.2rem',
      borderLeft: `2px solid ${palette.background.button}`,
      borderTop: `2px solid ${palette.background.button}`,
      cursor: 'pointer',
    },
    innerSquare: {
      width: '1.208rem',
      height: '1.208rem',
      margin: '0.2rem',
      border: `2px solid ${palette.background.button}`,
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { hiddenIn... Remove this comment to see the full error message
export default withStyles(styles)(CBCopyToClipboard);
