import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';

import CBButton from '../Buttons/CbButton';
import { useToggleModal } from '../../utils/modal.utils';

export const SecurityQuestionsUnansweredAlert = ({ data }: any) => {
  const toggleModal = useToggleModal();

  return (
    <div className="modal-title">
      <DialogContent>
        You have not answered a few additional questions for some of the
        coverages that you selected. Please click ‘Next’ to continue
      </DialogContent>
      <DialogActions>
        <CBButton
          type="submit"
          styleName="ctaButton"
          buttonText="Next"
          onClick={toggleModal.deferred('SecurityQuestions', data, {
            title: 'Security Questions',
            maxWidth: 'md',
            scroll: 'body',
          })}
        />
      </DialogActions>
    </div>
  );
};

export default SecurityQuestionsUnansweredAlert;
