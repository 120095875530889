import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { PubSub } from '../../utils/eventUtils';

const styles = ({ config, palette }: any) => {
  return {
    ctaButton: {
      fontSize: '0.875rem',
      minHeight: '2.5rem',
      fontFamily: config?.fonts?.stackSans,
      color: palette?.button?.text2,
      backgroundColor: palette?.common?.cowbellBlue,
      borderRadius: 5,
      '&:hover, &:focus': {
        backgroundColor: palette?.background?.darkerBlue,
      },
      '&:disabled': {
        backgroundColor: palette?.button?.disabledBackground,
        color: palette?.button?.disabledText,
      },
    },
    cancel: {
      fontSize: '0.875rem',
      minHeight: '2.5rem',
      fontFamily: config?.fonts?.stackSans,
      color: palette?.button?.text,
      borderRadius: 5,
      border: `1px solid ${palette?.button?.border}`,
      backgroundColor: palette?.button?.background2,
      marginRight: 10,

      'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
      '&:hover, &:focus': {
        backgroundColor: palette?.button?.background,
      },
      '&:disabled': {
        backgroundColor: palette?.button?.disabledBackground1,
        color: palette?.button?.disabledText,
        border: `1px solid ${palette?.button?.disabledBorder}`,
      },
    },
    error: {
      fontSize: '0.875rem',
      fontFamily: config?.fonts?.stackSans,
      color: palette?.button?.text,
      borderRadius: 5,
      backgroundColor: config?.colors?.textRed,
      marginRight: 10,
      lineHeight: 1.5,
      'box-shadow': '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
      '&:hover, &:focus': {
        backgroundColor: config?.colors?.textRed,
      },
      '&:disabled': {
        backgroundColor: palette?.button?.disabledBackground1,
        color: palette?.button?.disabledText,
      },
    },
    large: {
      width: '21rem',
      maxWidth: '100%',
      padding: '0.5rem',
    },
    medium: {
      width: '13rem',
      maxWidth: '100%',
      padding: '0.5rem',
    },
    small: {
      width: '8rem',
      maxWidth: '100%',
      padding: '0.5rem',
    },
    'x-small': {
      width: '2.667rem',
      maxWidth: '100%',
    },
    'fit-content': {
      padding: '0.5rem',
      maxWidth: '100%',
    },
    MuiButtonBase: {
      disableRipple: true,
    },

    menu: {
      marginLeft: 8,
      textTransform: 'none',
    },
    root: {
      textTransform: 'none',
      minWidth: 'inherit',
    },
    icon: {
      marginRight: 5,
      fontSize: `${config?.textSizes?.primer}px !important`,
    },
    primary: {
      background: palette?.common?.cowbellBlue,
      color: palette?.secondary?.contrastText,

      '&:hover': {
        background: palette?.common?.darkBlue,
      },
    },
    loadingIconRoot: {
      width: '15px !important',
      height: '15px !important',
      position: 'absolute',
      left: 10,
    },
  };
};

const CBButton = ({
  action,
  buttonText,
  children,
  classes,
  isShowing,
  styleName,
  icon,
  loading,
  size,
  ...props
}: any) => {
  const [button, setButton] = useState(buttonText);

  useEffect(() => {
    const loadingSub = PubSub.subscribe('updateButtonText', (data: any) => {
      const { buttonId } = props;
      if (buttonId === data.buttonId) {
        setButton(data.buttonText);
      }
    });

    return () => {
      loadingSub.remove();
    };
  }, [props]);

  if (!isShowing) {
    return null;
  }

  const _styles = Object.assign(
    {},
    props.fullWidth && {
      marginLeft: 0,
      textTransform: 'none',
    },
    props.styles
  );

  return (
    <Button
      variant="contained"
      className={`${classes[styleName]} ${classes[size || 'medium']} ${
        props.customClass || ''
      }`}
      onClick={action}
      style={_styles}
      disableRipple
      data-qa={`BUTTON:${typeof children === 'string' ? children : buttonText}`}
      {...props}
    >
      {loading && (
        <CircularProgress classes={{ root: `${classes.loadingIconRoot}` }} />
      )}
      <span>{button || children}</span>
    </Button>
  );
};

CBButton.defaultProps = {
  styles: {},
  isShowing: true,
  icon: {
    elem: null,
  },
};

CBButton.propTypes = {
  action: PropTypes.func,
  onClick: PropTypes.func,
  styleName: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  addIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  styles: PropTypes.object,
  isShowing: PropTypes.bool,
  icon: PropTypes.node,
};

// @ts-expect-error TS(2345): Argument of type '({ config, palette }: any) => { ... Remove this comment to see the full error message
export default withStyles(styles)(CBButton);
