import { createAction } from 'redux-actions';

import store from '../config/storeConfig';

// @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
const toggleModal = createAction('UI_TOGGLE_MODAL', (key, content, config) => ({
  key,
  content,
  config,
}));

/**
 * toggleModalDirect
 * @deprecated use new modal system Modal.show
 * if you must use old modal system, utilize the useToggleModal hook to avoid creating a dependency cycle
 */
export const toggleModalDirect = (...args: any[]) => {
  // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
  store.dispatch(toggleModal(...args));
};

/**
 * toggleModalDiffered
 * @deprecated use new modal system Modal.differShow
 */
export const toggleModalDiffered = (...args: any[]) => {
  return () => {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    return store.dispatch(toggleModal(...args));
  };
};

export const navigateTo = (incomingUrl: any) => {
  return () => {
    let url = incomingUrl;

    if (incomingUrl) {
      url = `${window.location.protocol}//${window.location.host}${url}`;
      const win = window.open(url, '_blank');
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      win.focus();
    }
  };
};
