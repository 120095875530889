import * as Ramda from 'ramda';
import { useFeature } from '@growthbook/growthbook-react';
import type { valueOf } from '../common/utility';
import { FEATURE_FLAG_KEYS } from '../../config/growthbook';

export const primePlus = 'PRIME_PLUS';
export const prime_tech_plus = 'PRIME_TECH_PLUS';
export const p_one_plus = 'P_ONE_PLUS';

export const ProductTypes = {
  p100: 'P100',
  p250: 'P250',
  primecloud: 'PRIME_CLOUD',
  notSupported: 'NotSupported',
  p250S: 'P250_S',
  p100_pro: 'P100_PRO',
  prime_one: 'P_ONE',
  p_plus: 'P_PLUS',
  prime_tech_plus,
  prime_tech: 'PRIME_TECH',
  p_one_plus,
  prime_one_tech: 'PRIME_ONE_TECH',
  zsp: 'ZSP',
} as const;

export type ProductType = (typeof ProductTypes)[keyof typeof ProductTypes];

export const PrimeXProductTypes = {
  p100_pro: ProductTypes.p100_pro,
  prime_one: ProductTypes.prime_one,
  prime_tech: ProductTypes.prime_tech,
  prime_one_tech: ProductTypes.prime_one_tech,
  zsp: ProductTypes.zsp,
} as const satisfies Partial<typeof ProductTypes>;

export const RATER_X_PRODUCT_TYPES = {
  prime_tech: ProductTypes.prime_tech,
  prime_one_tech: ProductTypes.prime_one_tech,
};

export type PrimeXProductType =
  (typeof ProductTypes)[keyof typeof PrimeXProductTypes];

export const isPrimeX = (product: string) =>
  Object.values(PrimeXProductTypes).some(Ramda.equals(product));

export const isRaterXProduct = (productType: string) => {
  return Object.values(RATER_X_PRODUCT_TYPES).some(Ramda.equals(productType));
};

export const useIsRaterXProduct = (productType: ProductType) => {
  const p100ProXFeature = useFeature(FEATURE_FLAG_KEYS.P100_PRO_X);
  const primeOneXFeature = useFeature(FEATURE_FLAG_KEYS.PRIME_ONE_X);

  return (
    isRaterXProduct(productType) ||
    (p100ProXFeature.on && productType === ProductTypes.p100_pro) ||
    (primeOneXFeature.on && productType === ProductTypes.prime_one)
  );
};

export const isLedgerStyleProduct = (productType: ProductType) => {
  return [
    ProductTypes.prime_one,
    ProductTypes.prime_tech,
    ProductTypes.prime_one_tech,
  ].some(Ramda.equals(productType));
};

export const isUWSupportedProduct = (productType: ProductType) => {
  return [ProductTypes.prime_one].some(Ramda.equals(productType));
};

export const isPxUWSupportedProduct = (productType: ProductType) =>
  isPrimeX(productType) && isUWSupportedProduct(productType);

export const isEndorsableProductType = (product: string) =>
  [
    ProductTypes.p100,
    ProductTypes.p250,
    ProductTypes.p100_pro,
    ProductTypes.prime_one,
  ].some(Ramda.equals(product));

export enum ProductLinesOfBusiness {}

export const EXCESS_FLOW = {
  primePlus,
  p_one_plus,
  prime_tech_plus,
} as const;

export enum EXCESS_POLICY_TITLE {
  PRIME_PLUS = 'Prime Plus',
  PRIME_TECH_PLUS = 'Prime Tech Plus',
  P_ONE_PLUS = 'Prime One Plus',
}

export type ExcessFlow = valueOf<typeof EXCESS_FLOW>;
