import { Api } from '../../../config/axiosConfig';
import { ServiceHandlerFactory } from '../../../utils/serviceUtils';

export const applyPayment = ServiceHandlerFactory({
  axios: {
    url: '/api/commissions/v1/payment',
    method: 'post',
  },
});
export function togglePayments(agencyId: any) {
  return Api.put(`/api/commissions/v1/toggle/${agencyId}`);
}
