import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import { PubSub } from '../../utils/eventUtils';
import { deleteApiKey } from '../../api/AuthService';
import { toggleModalDirect } from '../../utils/storeUtils';

const DeleteKey = ({ data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData: any) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const onSubmit = () => {
    deleteApiKey({ keyId: id })
      .then(() => {
        enqueueSnackbar('Successfully removed the API Key', {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'apiKeys');
        toggleModalDirect('DeleteKey', false);
      })
      .catch((error: any) => {
        console.error(error);
        toggleModalDirect('DeleteKey', false);
      });
  };

  return (
    <DialogContent>
      <section className="modal-title">
        Are you sure you want to delete the API Access Key? <br />
        You will no longer have access once deleted.
      </section>
    </DialogContent>
  );
};

const styles = ({ palette }: any) => {
  return {
    fieldContainer: {
      width: '100%',
      marginBottom: '0.5rem',
      fontSize: '1.33rem',
      lineHeight: 1.13,
      textAlign: 'center',
      color: palette.text.primary,
    },
    container: {
      width: '40rem',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'left',
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { fieldCon... Remove this comment to see the full error message
export default withStyles(styles)(DeleteKey);
