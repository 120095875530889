import React from 'react';

// components
import { TypeAheadBase } from './TypeAheadBase';

/**
 * @name ManagedTypeAheadBase
 * @description Manages the values on your behalf, has the disadvantage of not being programatically clearable.
 */
export const ManagedTypeAheadBase = ({
  defaultValue = '',
  defaultOptions = [],
  onChange,
  onInputChange,
  ...props
}: any) => {
  const [value, setValue] = React.useState(
    typeof defaultValue === 'number' ? `${defaultValue}` : defaultValue
  );
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(defaultOptions);

  const handleChange = React.useCallback(
    (newValue: any) => {
      if (typeof onChange === 'function') {
        onChange(newValue);
      }

      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
    },
    [options, onChange, setOptions, setValue]
  );

  const handleInputChange = React.useCallback(
    (newInputValue: any) => {
      if (typeof onInputChange === 'function') {
        onInputChange(newInputValue);
      }

      setInputValue(newInputValue);
    },
    [onInputChange]
  );

  const handleOptionChange = React.useCallback((newOptions: any) => {
    setOptions(newOptions);
  }, []);

  return (
    <TypeAheadBase
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onOptionsChange={handleOptionChange}
      inputValue={inputValue}
      value={value}
      {...props}
    />
  );
};
