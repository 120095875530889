import React from 'react';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

// components
import { InputBase, InputAdornment, useTheme } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import SimpleTooltip from '../SimpleTooltip';
import { InputErrorPlace } from './InputErrorPlace';
import { InputLabelBase } from './InputLabelBase';

// Services
import { validateDomainName } from '../../accounts/AccountService';

export const DomainField = ({
  InputProps = {},
  ErrorProps = { renderErrorPlaceholder: false },
  error,
  label,
  InputLabelProps = {},
  value,
  isMulti = false,
  onInputChange,
  ...props
}: any) => {
  const classes = styles();
  const [currentVal, setCurrentVal] = React.useState('');
  const [domainStatus, setDomainStatus] = React.useState();

  React.useEffect(() => {
    if (value) {
      const cleanedDomain = removeProtocol(value);
      validateDomainName({ domain: cleanedDomain })
        .then(() => {
          // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
          setDomainStatus(true);
        })
        .catch(() => {
          // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
          setDomainStatus(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const handleInputChange = _.debounce((val) => {
    // split away any additonal domains and removeProtocol
    const cleanedDomains = val
      .split(',')
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      .map((domain: any) => removeProtocol(domain).trim());
    setCurrentVal(cleanedDomains.join(','));
    // confirm if multipleDomains are being validated
    if (isMulti === false && cleanedDomains.length > 1) {
      // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
      setDomainStatus(false);
      return;
    }

    if (typeof props.onChange === 'function') {
      props.onChange(cleanedDomains);
    }

    // mapping over and validating the domains
    Promise.all(
      cleanedDomains.map((domain: any) => validateDomainName({ domain }))
    )
      .then(() => {
        // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        setDomainStatus(true);
        if (typeof onInputChange === 'function') {
          onInputChange(val, cleanedDomains.join(','), true);
        }
      })
      .catch(() => {
        // notify the user which domain was broken
        // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        setDomainStatus(false);
        if (typeof onInputChange === 'function') {
          onInputChange(val, cleanedDomains.join(','), false);
        }
      });
  }, 200);

  const { text, color } = useAdornmentStyles({
    searchInput: currentVal,
    isMulti,
    isValid: domainStatus,
  });

  return (
    <>
      {label && (
        <InputLabelBase
          indent
          className={classes.label}
          required={props.required}
          {...InputLabelProps}
        >
          {label}
        </InputLabelBase>
      )}
      <InputBase
        defaultValue={value}
        inputProps={{ ...InputProps, error: Boolean(error) }}
        error={Boolean(error)}
        endAdornment={
          <InputAdornment classes={{ root: classes.searchIcon }} position="end">
            <SearchAdornment
              text={text}
              color={color}
              validDomain={domainStatus}
            />
          </InputAdornment>
        }
        {...props}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <InputErrorPlace error={error} {...ErrorProps} />
    </>
  );
};

export const RegisteredDomainField = (props: any) => {
  const { setValue, clearErrors, setError } = useFormContext();
  function handleInputChange(original: any, cleaned: any, domainStatus: any) {
    if (domainStatus || original === '') {
      clearErrors(props.name);
      setValue(props.name, cleaned);
    } else {
      setError(props.name, {
        type: 'manual',
        message: 'Only valid domains are accepted.',
      });
      setValue(props.name, null);
    }
  }

  return <DomainField onInputChange={handleInputChange} {...props} />;
};

// Assigns color and valid tooltip icon based on search value & useState hook
const SearchAdornment = (props: any) => {
  const { color, text } = props;

  return (
    <SimpleTooltip title={text}>
      {!props.validDomain ? (
        <CancelIcon style={{ color }} />
      ) : (
        <CheckCircleIcon style={{ color }} />
      )}
    </SimpleTooltip>
  );
};

// return text and color for SearchAdornment
function useAdornmentStyles({ searchInput = '', isMulti, isValid }: any) {
  const theme = useTheme();
  // if valid
  if (isValid) {
    return { text: 'Valid domain found!', color: theme.palette.primary.green };
  }

  // else assign default values

  let color = theme.palette.primary.grey;
  let text = 'Please enter a domain name.';

  let isEmpty = searchInput === '';
  // corner case if someone types and untypes everything check if length isEmpty
  if (isEmpty === false) {
    isEmpty = searchInput.length === 0;
  }

  // Assign values for invalid domain
  if (isEmpty === false) {
    // @ts-expect-error TS(2339): Property 'red' does not exist on type 'PaletteColo... Remove this comment to see the full error message
    color = theme.palette.primary.red;
    text = 'Invalid Domain! Try a different domain name.';
  }

  // Check for multi-case
  if (isMulti === false && searchInput.split(',').length > 1) {
    text = 'Only enter one domain';
  }

  return { text, color };
}

// removes any http protocol
function removeProtocol(rawDomain: any) {
  if (typeof rawDomain !== 'string') {
    throw new Error(`${rawDomain} is not a string`);
  }

  return rawDomain.split('//').pop();
}

const styles = makeStyles(() => ({
  label: {
    paddingTop: 0,
  },
  searchIcon: {
    height: '90%',
    maxHeight: 'initial',
    width: 40,
    padding: '0 5px',
    marginRight: 1,
    marginLeft: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',

    '& svg': {
      height: 22,
      width: 22,
    },

    '.action-bar & svg': {
      height: 18,
      width: 18,
    },
  },
}));
