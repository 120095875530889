import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { delayedEvent, PubSub } from '../../utils/eventUtils';
import { refreshCustomerFirmographicData } from '../../accounts/AccountService';
import { refreshAgencyFirmographicData } from '../../agencies/AgencyService';

import { withQueryClient } from '../hocs/withQueryClient';
import { withToggleModal } from '../hocs/withToggleModal';
import { queries } from '../../queries';

class RefreshFirmographic extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      accountName: '',
      accountId: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      props: { data },
    } = this;
    this.setState({
      accountName: data.name,
      accountId: data.id,
    });

    this.subscription = PubSub.subscribe('foot-button-one', (subData: any) => {
      if (subData) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { data, queryClient } = this.props;

    if (data.isAgency) {
      // @ts-expect-error TS(2339): Property 'accountId' does not exist on type 'Reado... Remove this comment to see the full error message
      refreshAgencyFirmographicData(this.state.accountId)
        .then(() => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
          this.props.toggleModal.direct('RefreshFirmographic', false);
          // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
          this.props.enqueueSnackbar('Data Refreshed Successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'agencies');
        })
        .catch((error) => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.setState({ error: error.response.data });
        });
    } else {
      // @ts-expect-error TS(2339): Property 'accountId' does not exist on type 'Reado... Remove this comment to see the full error message
      refreshCustomerFirmographicData(this.state.accountId)
        .then(() => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
          this.props.toggleModal.direct('RefreshFirmographic', false);
          // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
          this.props.enqueueSnackbar('Data Refreshed Successfully!', {
            variant: 'success',
          });

          queryClient.invalidateQueries(queries.accounts.list._def);
        })
        .catch((error) => {
          PubSub.publish('loading', false);
          PubSub.publish('disabled', false);
          this.setState({ error: error.response.data });
        });
    }
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to refresh data for
          {/* @ts-expect-error TS(2339): Property 'accountName' does not exist on type 'Rea... Remove this comment to see the full error message */}
          <span className="modal-blue-text"> {this.state.accountName}</span>?
        </div>
        <FormHelperText className="api-text-error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
RefreshFirmographic.defaultProps = {
  data: {},
};

export default compose(
  withQueryClient,
  withSnackbar,
  withToggleModal
)(RefreshFirmographic);
