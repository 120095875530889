import _ from 'lodash';
import type { UsePersonaReturn } from '../../components/hooks/usePersona';
import type { CreateSubmissionPayload } from '../../api/submissions/submissions.api.types';
import type { UseActorReturn } from '../../components/hooks/useActor';
import { COUNTRIES } from '../../i18n/i18n.language-config';

export const buildCreateAccountPayload = (
  formData: Record<string, unknown>,
  persona: UsePersonaReturn
) => {
  let payload = _.omit(formData, ['selectedAgency', 'agency', 'team', 'agent']);

  if (!persona.isMssp) {
    payload = {
      ...payload,
      teams: [
        {
          name: _.get(formData.team, 'label', ''),
          id: _.get(formData.team, 'value', ''),
        },
      ],
    };
  }

  if (persona.isCowbell) {
    payload = {
      ...payload,
      agencyId: _.get(formData, 'agency.value'),
      agentFirstName: _.get(formData, 'agent.meta.firstName'),
      agentLastName: _.get(formData, 'agent.meta.lastName'),
      agentEmail: _.get(formData, 'agent.meta.email'),
      agentPhone: _.get(formData, 'agent.meta.phone'),
    };
  }

  return payload;
};

//todo: fix formData type safety
const getIndustryCode = (formData: any) => {
  switch (formData.country) {
    case COUNTRIES.UK:
      return { sicCode: formData.sicCode };
    default:
      return { naicsCode: formData.naicsCode };
  }
};

export const buildCreateSubmissionPayload = (
  formData: any,
  persona: UsePersonaReturn,
  actor: UseActorReturn
) => {
  const address = {
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    zipCode: formData.zipCode,
    state: formData.state,
    country: formData.country,
  };

  const firmographics = {
    name: formData.name,
    dunsNumber: formData.dunsNumber,
    noOfEmployeesAll: formData.noOfEmployeesAll,
    yearEstablished: formData.yearEstablished,
    ownershipType: formData.ownershipType,
    entityType: formData.entityType,
    ...getIndustryCode(formData),
    phoneNumber: formData.phoneNumber,

    // TODO: Needs discussed... Flow does not currently collect policy contact info
    policyContact: {},

    domesticRevenue: formData.revenue,
    domainName: formData.domainName,

    otherDomains: formData.domains?.split?.(',') ?? [],
    url: formData.url,
    isFranchise: formData.isFranchise,

    address,
    physicalAddress: address,

    mailingAddress: {
      address1: formData.mailingAddress1,
      address2: formData.mailingAddress2,
      city: formData.mailingCity,
      zipCode: formData.mailingZipCode,
      state: formData.mailingState,
      country: formData.mailingCountry,
    },
  };

  const selectedAgentTeams = [
    {
      teamName: formData.team?.label ?? '',
      teamId: formData.team?.value ?? '',
    },
  ];

  const agencyDataForCowbellFlow = {
    agencyId: formData.agency?.value,
    // todo: Discuss. Not avail in flow
    agencyName: '',

    agent: {
      firstName: formData.agent?.meta?.firstName,
      lastName: formData.agent?.meta?.lastName,
      emailAddress: formData.agent?.meta?.email,
      phoneNumber: formData.agent?.meta?.phone,
      teams: selectedAgentTeams,
    },
  };

  const agencyDataForNonCowbellFlow = {
    agencyId: actor.account.id,
    agencyName: actor.account.name,
    agent: {
      firstName: actor.firstName,
      lastName: actor.lastName,
      emailAddress: actor.email,
      phoneNumber: actor.phone,
      teams: !persona.isMssp ? selectedAgentTeams : undefined,
    },
  };

  return {
    cbid: formData.cbid,
    firmographics,
    agency: persona.isCowbell
      ? agencyDataForCowbellFlow
      : agencyDataForNonCowbellFlow,
  } satisfies CreateSubmissionPayload;
};
