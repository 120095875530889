import React, { useContext } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Draggable } from 'react-beautiful-dnd';
import { DragHandle as DragHandleIcon } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { TableContext } from '../context.table';

import CheckBoxBase from '../../../../inputs/Checkbox';

export const ColumnItemList = ({ items }: any) => {
  return items.map((item: any, idx: any) =>
    item.canToggle === false ? null : (
      // @ts-expect-error TS(2322): Type '{ item: any; index: any; key: any; }' is not... Remove this comment to see the full error message
      <ColumnItem item={item} index={idx} key={item.id} />
    )
  );
};

const itemStyles = () => ({
  item: {
    display: 'flex',
    minWidth: 200,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
export const ColumnItem = withStyles(itemStyles)(({ classes, item, index }) => {
  // @ts-expect-error TS(2339): Property 'toggleColumnVisibility' does not exist o... Remove this comment to see the full error message
  const { toggleColumnVisibility } = useContext(TableContext);
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.item}
        >
          <CheckBoxBase
            name={item.id}
            defaultChecked={
              typeof item.show === 'undefined' || item.show === true
            }
            label={item.content}
            onChange={toggleColumnVisibility}
          />
          <DragHandleIcon />
        </div>
      )}
    </Draggable>
  );
});
