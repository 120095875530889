import { useForm } from 'react-hook-form';
import Moment from 'moment';
import { DialogActions } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { setPrime250Ui } from '../../../../_reducers/prime250.reducer';

export const UWSwitchingSurplus = ({ data, ...props }: any) => {
  const { classes, isSurplus, dispatch } = data;
  const { handleSubmit } = useForm();
  const line1 = isSurplus
    ? `Are you sure you want to switch this quote to Admitted?`
    : `Are you sure you want to switch this quote to Surplus?`;

  const onSubmit = () => {
    toggleModalDirect('UWSwitchingSurplus', false);
    const obj = { isSurplus: !isSurplus, shouldRecalculate: Moment().unix() };
    dispatch(setPrime250Ui(obj));
  };

  const handleCancel = () => {
    toggleModalDirect('UWSwitchingSurplus', false);
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={classes.description}>
          {line1}
          <br />
          All your changes to the quote will be lost after the switch.
        </p>
        <DialogActions>
          <CBButton
            action={handleCancel}
            styleName="cancel"
            buttonText="CANCEL"
          />
          <CBButton type="submit" styleName="ctaButton" buttonText="SWITCH" />
        </DialogActions>
      </form>
    </section>
  );
};
