import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { noop } from 'lodash';

class RadioButtons extends React.PureComponent {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  handleChange = (event: any) => {
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
    const { disabled } = this.props;
    if (!disabled) {
      // @ts-expect-error TS(2339): Property 'checkError' does not exist on type 'Read... Remove this comment to see the full error message
      this.props.checkError(true);
      this.setState({ value: getVal(event.target.value) });
      // @ts-expect-error TS(2339): Property 'infunc' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.infunc(event.target.value);
    }
  };

  render() {
    const { props, state } = this;
    // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value } = state;
    if (!value) {
      return '';
    }

    // @ts-expect-error TS(2339): Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
    const radios = props.values.map((radio: any, idx: any) => {
      return (
        <FormControlLabel
          key={radio.value}
          value={radio.value}
          control={<Radio color="primary" inputProps={{ required: true }} />}
          label={radio.label}
          labelPlacement="end"
          // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
          disabled={props.disabled}
          className={`${
            // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
            idx === 0 ? props.classes.leftButton : props.classes.rightButton
          }`}
        />
      );
    });

    return (
      <FormControl
        variant="standard"
        required
        component="fieldset"
        // @ts-expect-error TS(2339): Property 'classContainer' does not exist on type '... Remove this comment to see the full error message
        className={props.classContainer}
      >
        <RadioGroup
          aria-label="position"
          // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          name={props.name}
          value={value.value}
          onClick={this.handleChange}
          // @ts-expect-error TS(2322): Type '{ children: any; "aria-label": string; name:... Remove this comment to see the full error message
          required
          row
          // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
          className={props.classes.radioGroupContainer}
        >
          {radios}
        </RadioGroup>
      </FormControl>
    );
  }
}

const getVal = (val: any) => {
  if (val === 'true') {
    return { value: true, label: 'Yes' };
  }
  return { value: false, label: 'No' };
};

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
RadioButtons.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object,
  values: PropTypes.array,
  infunc: PropTypes.func,
  checkError: PropTypes.func,
  classContainer: PropTypes.string,
  name: PropTypes.string,
};

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
RadioButtons.defaultProps = {
  disabled: false,
  value: null,
  values: [],
  infunc: noop,
  checkError: noop,
  classContainer: '',
  name: 'position',
};

const styles = {
  radioGroupContainer: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftButton: {
    paddingRight: '1rem',
    margin: '-1px 0 -1px -1px',
    borderTop: '1px solid transparent',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  rightButton: {
    paddingRight: '1rem',
    margin: '-1px 0 -1px -1px',
    borderTop: '1px solid transparent',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
};

// @ts-expect-error TS(2345): Argument of type '{ radioGroupContainer: { width: ... Remove this comment to see the full error message
export default withStyles(styles)(RadioButtons);
