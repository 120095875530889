import React from 'react';

import { isEmpty } from 'lodash';

import InfoIcon from '@mui/icons-material/Info';

import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { PasswordPopper } from './PasswordPopper';
import { PasswordField } from './PasswordField';

export const CreatePasswordField = React.forwardRef(
  // @ts-expect-error TS(2339): Property 'errors' does not exist on type '{}'.
  ({ errors = [], onChange, onError, onValidate, ...props }, forwardedRef) => {
    const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);

    function handleInfoClick(event: any) {
      // toggle popper
      if (popperAnchorEl) {
        setPopperAnchorEl(null);
        return;
      }

      setPopperAnchorEl(event.target);
    }

    async function handleChange(e: any) {
      const { value } = e.target;
      onChange(value);

      if (typeof onChange === 'function') {
        try {
          if (typeof onValidate === 'function') {
            const remoteErrors = await onValidate(value);
            onError(remoteErrors);
          }
        } catch (error) {
          console.error(error);
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          onError(error.response.data);
        }
      }
    }

    const isErrors = !isEmpty(errors);

    return (
      <>
        <Box>
          <PasswordField
            {...props}
            // @ts-expect-error TS(2322): Type '{ indent: true; ref: ForwardedRef<unknown>; ... Remove this comment to see the full error message
            indent
            ref={forwardedRef}
            error={isErrors}
            onChange={handleChange}
          />

          <PasswordConstraintInfoAlert
            isAlert={isErrors}
            onInfoClick={handleInfoClick}
          />
        </Box>

        <PasswordPopper
          errors={errors}
          $anchorElem={popperAnchorEl}
          onClose={() => setPopperAnchorEl(null)}
        />
      </>
    );
  }
);

const InfoAlertIconButton = (props: any) => (
  <IconButton {...props} size="large">
    <InfoIcon />
  </IconButton>
);

const PasswordConstraintInfoAlert = styled(({ onInfoClick, ...props }) => (
  <Box {...props}>
    <InfoAlertIconButton
      onClick={onInfoClick}
      classes={{ color: 'inherit' }}
      size="small"
    />
    <span>View password requirements</span>
  </Box>
))((props) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '2px',
  color: props.isAlert ? props.theme.palette.error.light : '',
  zIndex: 999,

  '& button': {
    color: 'inherit',
    marginRight: '4px',
  },
}));
