import { Box, styled, Skeleton } from '@mui/material';

export function ConsoleLayoutLoading(props: any) {
  return (
    <Container>
      <ViewPaper
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="4.66rem"
        marginBottom="1rem"
      >
        <Box display="flex">
          <Logo src="logos/CowbellLogo2.svg" />
        </Box>
        <Box display="flex">
          <Skeleton width="100px" style={{ marginRight: '1rem' }} />
          <Skeleton variant="circular" width="40px" height="40px" />
        </Box>
      </ViewPaper>
      <Box display="flex" flexDirection="row" height="100%">
        <ViewPaper width="11.6666666667rem">
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
        </ViewPaper>
        <Box width="calc(100% - 11.6666666667rem)" paddingLeft="1rem">
          <ViewPaper height="100%">
            <Skeleton width="10%" height="40px" />
            <Skeleton width="40%" />
            <Skeleton width="35%" />

            <Box mt={2}>
              <Skeleton width="10%" height="40px" />
              <Skeleton width="30%" />
              <Skeleton width="20%" />
              <Skeleton width="25%" />
            </Box>

            <Box mt={2}>
              <Skeleton width="10%" height="40px" />
              <Skeleton width="30%" />
              <Skeleton width="20%" />
              <Skeleton width="25%" />
            </Box>

            <MessageContainer>
              <Message>{props.message}</Message>
            </MessageContainer>
          </ViewPaper>
        </Box>
      </Box>
    </Container>
  );
}

const Logo = styled('img')({
  display: 'flex',
  width: '8rem',
});

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem 1rem 1rem',
  background: theme.palette.background.default,
}));

const ViewPaper = styled(Box)(({ theme }) => ({
  padding: '1rem',
  background: theme.palette.background.paper,
  position: 'relative',
}));

const MessageContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const Message = styled(Box)(() => ({
  fontSize: '1.5rem',
  textAlign: 'center',
}));
