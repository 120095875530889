import { PubSub } from '../../../../utils/eventUtils';

/**
 * Example of use
 *
 * Modal.deferShow(TestModalConfig, {
 *   data: { custom: 'data' },
 * });
 *
 */
export const Modal = {
  /**
   * @name show
   * @description immediately show a modal
   * @param nextModal: config object of the modal to be injected
   * @param modalMeta: inline configuration overrides
   */
  show(nextModal: any, modalMeta = {}) {
    const { component, config: defaultConfig = {} } =
      deriveModalConfig(nextModal);

    // @ts-expect-error TS(2339): Property 'config' does not exist on type '{}'.
    const { config: inlineConfig = {}, ...metaRest } = modalMeta;

    PubSub.publish('modal:show', {
      config: { ...defaultConfig, ...inlineConfig },
      ...metaRest,
      component,
    });
  },

  /**
   * @name hide
   * @description hide the modal
   */
  hide() {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    PubSub.publish('modal:hide');
  },

  deferShow(nextModal: any, modalMeta: any) {
    return () => {
      this.show(nextModal, modalMeta);
    };
  },

  deferHide() {
    return () => {
      this.hide();
    };
  },
};

const deriveModalConfig = (nextModal: any) => {
  // remove intermediate layer from modal config object
  const [nextModalConfig] = Object.keys(nextModal).map((key) => nextModal[key]);
  return nextModalConfig;
};
