import React from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
// material ui
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
// helpers
import { delayedEvent } from '../../utils/eventUtils';
// components
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import CbButton from '../Buttons/CbButton';
// actions
import { closePremFinance } from '../../console/customers/_services/BillingService';

const TextField = withFormController(TextFieldBase);

export const ClosePremiumFinancedTransaction = ({ close, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    // @ts-expect-error TS(2345): Argument of type '{ defaultValue: { description: s... Remove this comment to see the full error message
    defaultValue: {
      description: '',
    },
  });

  const onSubmit = (formData: any) => {
    const payload = {
      policyId: data.policyId,
      description: formData.description,
    };
    return closePremFinance({}, payload)
      .then(() => {
        close();
        enqueueSnackbar('Transaction Closed Successfully!', {
          variant: 'success',
        });
        delayedEvent('table-refetch', 500, 'transactions');
      })
      .catch((error: any) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong.Please try again later!'
            )
          )
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent className="modal-title" style={{ textAlign: 'left' }}>
        <TextField
          label="Comments"
          name="description"
          multiline
          required
          minRows={4}
          // @ts-expect-error TS(2322): Type '{ control: Control<FieldValues, any>; }' is ... Remove this comment to see the full error message
          controllerProps={{ control }}
          placeholder="Details of payment"
          inputProps={{ required: true }}
        />
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
        <CbButton
          type="submit"
          styleName="ctaButton"
          buttonText="Close Payment"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </DialogActions>
    </form>
  );
};

export default ClosePremiumFinancedTransaction;
