import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { refreshRiskData } from '../../../../../api/p250.service';

export const UWUpdateRiskConfirmation = ({ data, ...props }: any) => {
  const { classes } = data;
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    const { account, enqueueSnackbar } = data;
    setLoading(true);

    return refreshRiskData(
      { accountId: account.id },
      {
        quoteId: data.data.id,
        agentEmail: data.data.agentEmail,
        product: data.data.product,
      }
    )
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          enqueueSnackbar('Risk reassessed!', { variant: 'success' });
          toggleModalDirect('UWUpdateRiskConfirmation', false);
          replace(`/admin/requestQuote`);
          setTimeout(() => replace(`${pathname}`), 0);
        }, 5000);
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Failed to reassess risk!', { variant: 'error' });
        toggleModalDirect('UWUpdateRiskConfirmation', false);
      });
  };

  const handleCancel = () => {
    toggleModalDirect('UWUpdateRiskConfirmation', false);
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={classes.description}>
          Are you sure you want to reassess the risk for this account?
          <br />
          You will lose unsaved changes and will not be able to revert back
          after the update!
        </p>
        <DialogActions>
          <CBButton action={handleCancel} styleName="cancel">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            loading={isSubmitting || loading}
            disabled={isSubmitting || loading}
            styleName="ctaButton"
            buttonText="Reassess"
          />
        </DialogActions>
      </form>
    </section>
  );
};
