/* eslint-disable react/jsx-no-bind */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';

// components
import {
  IconButton as MuiIconButton,
  Slide as MuiSlide,
  Dialog,
  DialogTitle,
} from '@mui/material';

import CBButton from '../Buttons/CbButton';
import { PubSub } from '../../utils/eventUtils';
import { iconMappings } from '../../console/_global/navigation/IconsMappings';
import { useToggleModal } from '../../utils/modal.utils';

const scrollableStyles = makeStyles(({ palette }) => ({
  scrollableRoot: {
    overflowY: 'scroll',
  },

  scrollableContainer: {
    height: 'unset',
  },

  scrollableScrollPaper: {
    alignItems: 'unset',
  },

  scrollablePaperScrollPaper: {
    maxHeight: 'unset',
    backgroundColor: palette.background.modal,
    borderRadius: 5,
  },
}));

const Transition = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2741): Property 'children' is missing in type '{ directio... Remove this comment to see the full error message
  return <MuiSlide direction="up" ref={ref} {...props} />;
});

const GenericModal = ({
  children,
  classes,
  title,
  icon,
  maxWidth,
  buttonSize,
  headerButtons,
  footerButtons,
  scrollable,
  iconComponent,
  showClose = true,
  noCloseIcon,
  ...props
}: any) => {
  const toggleModal = useToggleModal();

  let paperStyles = '';
  if (maxWidth === 'md') {
    paperStyles = classes.setWidth;
  } else if (maxWidth === 'lg') {
    paperStyles = classes.setLarge;
  }

  const modalClasses = scrollable
    ? { ...classes, ...scrollableStyles }
    : {
        ...classes,
        root: props.fullScreen ? classes.fullScreen : null,
        paper: `${paperStyles} ${classes.paper}`,
      };
  let Icon = iconComponent || (
    <img
      src="images/Cowbell.svg"
      alt="webhook"
      className={classes.icon}
      style={{ width: '2.25rem' }}
    />
  );

  Object.entries(iconMappings).forEach(() => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (icon !== undefined && iconMappings[icon] !== undefined) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      Icon = iconMappings[icon].iconImport;
    }
  });

  function publishEvents(actionToCall: any) {
    if (actionToCall === 'cancel') {
      toggleModal.direct(props.modalKey, false);
    }
    PubSub.publish(actionToCall, true);
  }

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setLoading(false);
    setDisabled(false);
    const loadingSub = PubSub.subscribe('loading', (data: any) => {
      setLoading(data);
    });

    const disabledSub = PubSub.subscribe('disabled', (data: any) => {
      setDisabled(data);
    });

    return () => {
      loadingSub.remove();
      disabledSub.remove();
    };
    // eslint-disable-next-line
  }, []);

  const buttons = (buttonsArray: any) => {
    return buttonsArray.map(
      ({
        action,
        showLoading,
        showDisabled,
        buttonId,
        buttonText,
        ...value
      }: any) => {
        return (
          <span className={classes.margins} key={action}>
            <CBButton
              size={buttonSize}
              loading={showLoading ? loading : false}
              disabled={showDisabled ? disabled : false}
              action={publishEvents.bind(this, action)}
              styleName={action === 'cancel' ? 'cancel' : 'ctaButton'}
              buttonText={buttonText || props.buttonText}
              buttonId={buttonId || ''}
              {...value}
              data-qa={buttonText || props.buttonText}
            />
          </span>
        );
      }
    );
  };

  if (props.modalkey === 'SignQuote') {
    return (
      <Dialog
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        {...props}
        TransitionComponent={Transition}
        classes={modalClasses}
      >
        {children}
      </Dialog>
    );
  }

  const titleStyle = {};

  if (maxWidth === 'md') {
    // @ts-expect-error TS(2339): Property 'maxWidth' does not exist on type '{}'.
    titleStyle.maxWidth = '40rem';
  } else if (maxWidth === 'lg') {
    // @ts-expect-error TS(2339): Property 'maxWidth' does not exist on type '{}'.
    titleStyle.maxWidth = '60rem';
  } else {
    // @ts-expect-error TS(2339): Property 'maxWidth' does not exist on type '{}'.
    titleStyle.maxWidth = '27rem';
  }

  return (
    <Dialog
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      {...props}
      TransitionComponent={Transition}
      classes={modalClasses}
      maxWidth={maxWidth}
    >
      <div className={classes.flexbox}>
        {Icon}
        {!isEmpty(title) && (
          <DialogTitle style={titleStyle}>{title}</DialogTitle>
        )}
        {headerButtons && (
          <div className={classes.headerButtonContainer}>
            {buttons(headerButtons)}
          </div>
        )}
        {showClose && (
          <MuiIconButton
            className={classes.iconBody}
            onClick={toggleModal.deferred(props.modalKey, false)}
          >
            <img src="images/Close-Modal.svg" alt="modal-close" />
          </MuiIconButton>
        )}
      </div>
      <span className={classes.bodyContainer}>{children}</span>
      {footerButtons && (
        <div className={classes.footerButtonContainer}>
          {buttons(footerButtons)}
        </div>
      )}
    </Dialog>
  );
};

GenericModal.defaultProps = {
  data: {},
  scrollable: false,
  maxWidth: 'sm',
  buttonSize: 'medium',
  buttonText: 'Save',
};

const styles = ({ config, palette }: any) => ({
  bodyContainer: {
    '& .headerButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: '20px 0px 20px 0px',
      margin: '30px 0 0 0',
      borderTop: `1px solid ${config.colors.faintBrown}`,
    },
    '& .buttonMargins': {
      marginRight: 20,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  '@global': {
    '.api-text-error': {
      textAlign: 'center',
      maxWidth: '600px',
      fontFamily: config.fonts.stackSans,
      fontSize: config.textSizes.normal,
    },
    '.modal-title': {
      fontFamily: config.fonts.stackSans,
      fontSize: config.textSizes.tertia,
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: palette.text.text8,
    },
    '.modal-blue-text': {
      fontFamily: config.fonts.stackSans,
      color: palette.primary.title,
    },
    '.greenText': {
      color: palette.text.text4,
    },
    '.boldText': {
      fontWeight: 600,
    },
    '.contrastText': {
      color: palette.primary.contrastText,
    },
    '.iconBody': {
      padding: 0,
      width: '25px',
      top: 10,
      right: 12,
      // margin: '0.3rem 2rem',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 100,
      color: config.colors.darkgreySoft,
      '& img': {
        maxWidth: '100%',
      },
    },
  },
  paper: {
    backgroundColor: palette.background.modal,
    borderRadius: 5,
    boxShadow: '3px 3px 6px 0 rgba(0, 0, 0, 0.5)',
  },

  setWidth: {
    minWidth: '50rem',
  },

  setLarge: {
    minWidth: '75rem',
  },

  fullScreen: {
    padding: 20,
  },

  paperFullScreen: {
    padding: 10,
  },
  icon: {
    margin: '1.667rem 1.25rem',
    '&:hover': {
      cursor: 'initial',
      color: 'inherit',
    },
  },
  iconBody: {
    padding: 0,
    width: '25px',
    height: '25px',
    right: 25,
    margin: '25px 0px',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 100,
    color: config.colors.darkgreySoft,
    '& img': {
      maxWidth: '100%',
    },
  },
  flexbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '0px 20px 30px 20px',
    borderBottom: `1px solid ${palette.primary.border}`,
  },
  footerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px 0px 20px 0px',
    margin: '0px 20px 0px 20px',
    borderTop: `1px solid ${palette.primary.border}`,
  },
  headerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '25px 45px 0px 90px',
    position: 'absolute',
    right: '1.67rem',
  },
  margins: {
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

// @ts-expect-error TS(2345): Argument of type '({ config, palette }: any) => { ... Remove this comment to see the full error message
export default withStyles(styles)(GenericModal);
