const ADMIN_UPDATE_BILLING = 'admin-update-billing-compliance';
const AGENCY_DASHBOARD_PREREQ = 'agency_dashboard_prereq';
const AWS_FLAG = 'aws_flag';
const CLAIMS_QUESTIONS = 'claims_questions';
const CLIENT_PROFILE = 'client_profile';
const CLOUDFLARE = 'cloudflare';
const CMAIL = 'cmail';
const COWBELL_BIND_QUOTE_WORKFLOW = 'cowbell_bind_quote_workflow_ff';
const COWBELL_FACTORS_DECLINE = 'cowbell_factors_decline';
const COWBELL_FACTORS_V2 = 'cowbell-factors-v2';
const COWBELL_GENERATE_RENEWAL_QUOTE = 'cowbell_generate_renewal_quote';
const ELIGIBLE_PRODUCTS = 'eligible_products';
const EXCESS_FLOW = 'excess_flow';
const FLAT_CANCEL = 'flat_cancel';
const KRISTA_CHAT_BOT = 'krista_chat_bot';
const LANGUAGE_SELECTOR = 'language_selector';
const MAINTENANCE_MODE = 'maintenance-mode';
const MAINTENANCE_MODE_US = 'maintenance-mode-us';
const MAINTENANCE_MODE_UK = 'maintenance-mode-uk';
const MARSH_AGENCY = 'marsh_agency';
const MMA_ENDORSEMENT = 'mma_endorsement';
const NOTES_REDESIGN = 'notes_redesign';
const OPT_IN_NEW_AGENT_EXPERIENCE = 'opt_in_new_agent_experience';
const P100_RENEWAL_BIND_FLOW = 'p100-renewal-bind-flow';
const P250_ENDORSEMENT_BYPASS_RULES = 'p250-endorsement-bypass-rules';
const PRIME_ONE = 'prime_one';
const PRIME_ONE_UW = 'prime_one_uw';
const PRIME_TECH_PLUS = 'prime-tech';
const PRODUCT_SELECTOR = 'product_selector';
const REMEMBER_DEVICE = 'remember_device';
const RETREADS = 'retreads';
const SPOTLIGHT = 'spotlight';
const UNDO_CANCELLED_POLICY = 'undo-cancelled-policy';
const PRIME_TECH = 'prime_tech';
const P_ONE_PLUS = 'p_one_plus';
const UK_ENVIRONMENT = 'uk_environment';
const SUBMISSIONS_VIEW = 'submissions_view';
const AGENCY_COMMISSION_ENHANCEMENTS = 'agency_commission_enhancements';
const CLAIMS_DIARY = 'claims_diary';
const BUSINESS_TYPE_IN_CARRIER_RISK_PROFILE =
  'business_type_in_carrier_risk_profile';
const SUBMISSIONS_MAILING_ADDRESS = 'submissions_mailing_address';
const OWNERSHIP_TYPE = 'ownership_type';
const BROKER_LOSSRUNS = 'broker_lossruns';
const PRIME_TECH_CANCEL = 'prime_tech_cancel';
const P100_PRO_X = 'p100_pro_x';
const OBD_TREATY_ENHANCEMENT = 'obd_treaty_enhancement';
const REBUILD_RENEWALS = 'rebuild_renewals';
const SCBFE_FINANCIALS = 'scbfe_financials';
const PRIME_ONE_X = 'prime_one_x';
const IS_START_DATE_FREE_SELECTION = 'allow_uw_mte_start_date_free_selection'; // PS-5194 P250 only
const IS_END_DATE_FREE_SELECTION = 'allow_uw_mte_end_date_free_selection'; // PS-5194 P250 only
const PRIME_ONE_DYNAMIC_FIELDS = 'prime_one_x_dynamic_fields';
const P100_PRO_X_DYNAMIC_FIELDS = 'p100_pro_x_dynamic_forms';
const ENDORSEMENTS_X = 'endorsements_x';
const LEEN_CONNECTORS_V2 = 'leen_connectors_enhancement';
const CRS_POLICYHOLDER_NAVBAR = 'crs_policyholder_navbar';
const CRS_CATALOG = 'crs_catalog';
const REVERT_REVISION = 'revert_revision';
const ZSP_PRODUCT = 'zsp_product';
const PRIME_ONE_TECH = 'prime_one_tech';
const ONE_ACCT_MULTI_LINE = 'account_multi_line';
const EDIT_QUOTE = 'edit_quote';
const EDIT_VIEW_SECURITY_QUESTIONS_MODAL = 'edit_view_security_questions_modal';
const UW_VIEW_SECURITY_QUESTIONS_MODAL = 'uw_view_security_questions_modal';

export const FEATURE_FLAG_KEYS = {
  ADMIN_UPDATE_BILLING,
  AGENCY_DASHBOARD_PREREQ,
  AWS_FLAG,
  CLAIMS_QUESTIONS,
  CLIENT_PROFILE,
  CLOUDFLARE,
  CMAIL,
  COWBELL_BIND_QUOTE_WORKFLOW,
  COWBELL_FACTORS_DECLINE,
  COWBELL_FACTORS_V2,
  COWBELL_GENERATE_RENEWAL_QUOTE,
  ELIGIBLE_PRODUCTS,
  EXCESS_FLOW,
  FLAT_CANCEL,
  KRISTA_CHAT_BOT,
  LANGUAGE_SELECTOR,
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_US,
  MAINTENANCE_MODE_UK,
  MARSH_AGENCY,
  MMA_ENDORSEMENT,
  NOTES_REDESIGN,
  OPT_IN_NEW_AGENT_EXPERIENCE,
  P100_RENEWAL_BIND_FLOW,
  P250_ENDORSEMENT_BYPASS_RULES,
  P_ONE_PLUS,
  PRIME_ONE_UW,
  PRIME_ONE,
  PRIME_TECH,
  PRIME_TECH_PLUS,
  PRODUCT_SELECTOR,
  REMEMBER_DEVICE,
  RETREADS,
  SPOTLIGHT,
  UNDO_CANCELLED_POLICY,
  UK_ENVIRONMENT,
  SUBMISSIONS_VIEW,
  AGENCY_COMMISSION_ENHANCEMENTS,
  CLAIMS_DIARY,
  BUSINESS_TYPE_IN_CARRIER_RISK_PROFILE,
  SUBMISSIONS_MAILING_ADDRESS,
  OWNERSHIP_TYPE,
  BROKER_LOSSRUNS,
  PRIME_TECH_CANCEL,
  P100_PRO_X,
  OBD_TREATY_ENHANCEMENT,
  REBUILD_RENEWALS,
  SCBFE_FINANCIALS,
  PRIME_ONE_X,
  IS_START_DATE_FREE_SELECTION,
  IS_END_DATE_FREE_SELECTION,
  PRIME_ONE_DYNAMIC_FIELDS,
  P100_PRO_X_DYNAMIC_FIELDS,
  ENDORSEMENTS_X,
  LEEN_CONNECTORS_V2,
  CRS_POLICYHOLDER_NAVBAR,
  CRS_CATALOG,
  REVERT_REVISION,
  ZSP_PRODUCT,
  PRIME_ONE_TECH,
  ONE_ACCT_MULTI_LINE,
  EDIT_QUOTE,
  EDIT_VIEW_SECURITY_QUESTIONS_MODAL,
  UW_VIEW_SECURITY_QUESTIONS_MODAL,
} as const;
