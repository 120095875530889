import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
// actions
import { compose } from 'redux';
import { DialogContent, FormHelperText } from '@mui/material';
import { deleteAccountAgency } from '../../accounts/AccountService';
// platform helpers
import { PubSub } from '../../utils/eventUtils';
import { withToggleModal } from '../hocs/withToggleModal';
import { withQueryClient } from '../hocs/withQueryClient';
import { AGENCY_ACCOUNTS } from '../tables/table_constants';

class AccountsDeleteConfirm extends Component {
  subscription: any;

  constructor(props: any) {
    super(props);
    this.state = {
      accountName: '',
      accountId: '',
      error: '',
    };
  }

  componentDidMount() {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      props: { data },
    } = this;
    this.setState({
      accountName: data.name,
      accountId: data.id,
    });
    this.subscription = PubSub.subscribe(
      'foot-button-one',
      (eventData: any) => {
        if (eventData) {
          this.handleSubmit();
        }
      }
    );
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  handleSubmit = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    // @ts-expect-error TS(2339): Property 'accountId' does not exist on type 'Reado... Remove this comment to see the full error message
    deleteAccountAgency(this.state.accountId)
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        // @ts-expect-error TS(2339): Property 'toggleModal' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.toggleModal.direct('AccountsDeleteConfirm', false);
        // @ts-expect-error TS(2339): Property 'enqueueSnackbar' does not exist on type ... Remove this comment to see the full error message
        this.props.enqueueSnackbar('Account Deleted Successfully!', {
          variant: 'success',
        });
        // @ts-expect-error TS(2339): Property 'queryClient' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
      })
      .catch((error) => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        this.setState({ error: error.response.data });
      });
  };

  render() {
    return (
      <DialogContent>
        <div className="modal-title">
          Are you sure you want to delete the account
          {/* @ts-expect-error TS(2339): Property 'accountName' does not exist on type 'Rea... Remove this comment to see the full error message */}
          <span className="modal-blue-text"> {this.state.accountName}</span>?
        </div>
        <FormHelperText className="api-text-error">
          {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.error}
        </FormHelperText>
      </DialogContent>
    );
  }
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
AccountsDeleteConfirm.defaultProps = {
  data: {},
};

export default compose(
  withSnackbar,
  withToggleModal,
  withQueryClient
)(AccountsDeleteConfirm);
