import { trimAndAddAsterisk } from '../../utility/QueryUtility';

export const fetchBlackListedDomainsParamsMapping = [
  {
    path: 'pageIndex',
    paramKey: 'page',
    transform: ({ pageIndex }: any) => Number(pageIndex),
  },
  {
    path: 'pageSize',
    paramKey: 'size',
    transform: ({ pageSize }: any) => {
      return Number(pageSize) === 10 ? Number(25) : Number(pageSize);
    },
  },
  {
    path: 'search',
    transform: ({ search }: any) =>
      search ? trimAndAddAsterisk(search) : null,
  },

  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: any) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }

      return null;
    },
  },
];
