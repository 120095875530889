import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DialogContent, DialogActions, FormHelperText } from '@mui/material';
import { syncLeads } from '../../accounts/AccountService';
import CbButton from '../Buttons/CbButton';
import { queries } from '../../queries';

export const AccountSyncLeads = ({ close, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const [error, setError] = useState('');
  const { id, name } = data;

  const onSubmit = () => {
    syncLeads(id)
      .then(() => {
        enqueueSnackbar(`Sync Lead initiated for ${name}.`, {
          variant: 'success',
        });

        close();
        queryClient.invalidateQueries(queries.accounts.list._def);
      })
      .catch((error) => {
        setError(
          _.get(
            error.response,
            'data.message',
            _.get(
              error.response,
              'data',
              'Something went wrong while syncing leads.'
            )
          )
        );
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <div className="modal-title" style={{ paddingTop: '5px' }}>
          Are you sure you want to synchronize leads for{' '}
          <span className="modal-blue-text">{data.name}</span> ?
        </div>
        {error && (
          <FormHelperText className="api-text-error">{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <CbButton styleName="cancel" onClick={close}>
          Cancel
        </CbButton>
        <CbButton
          loading={isSubmitting}
          styleName="ctaButton"
          buttonText="Sync Leads"
          type="submit"
        />
      </DialogActions>
    </form>
  );
};

export const AccountSyncLeadsConfig = {
  AccountSyncLeads: {
    component: AccountSyncLeads,
    config: {
      minWidth: 'md',
      title: 'Sync Leads',
    },
  },
};
export default AccountSyncLeads;
