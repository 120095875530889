import { applyQueryConfig, ServiceHandler } from '../../utils';

import type { PageableResponse } from '../serializers';
import { standardQuerySerializer } from '../serializers';
import { SUBMISSIONS_QUERY_PARAM_MAPPING } from './submissions.param-mapping';

import type {
  CreateSubmissionPayload,
  DeclineSubmissionPayload,
  ISubmissionMetricsResponse,
  SubmissionDetailsDto,
  SubmissionEligibleProductsResponse,
  SubmissionFirmographics,
  SubmissionTableRow,
} from './submissions.api.types';
import type { SelectOption } from '../../console/agencies/quotes/PrimeX/customizeQuote/reducer/types';
import type { P100SecurityQuestions } from '../../types';
import type { FieldValue } from '../../form-engine/types';

export const searchSubmissions = ServiceHandler<
  unknown,
  PageableResponse<SubmissionTableRow>
>({
  url: '/api/account/v1/submission/search',
  method: 'GET',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(SUBMISSIONS_QUERY_PARAM_MAPPING, params)
    ),
});

export const fetchSubmissionsMetrics = ServiceHandler<
  unknown,
  ISubmissionMetricsResponse
>({
  url: '/api/account/v1/submission/stats',
  method: 'GET',
});

/**
 * Utilized to create submission records within our platform.
 * Creates an account if one does not exist with the passed firmographic data.
 * Implicitly utilizes agency information from the user's JWT when that data is not passed within the request body.
 *
 * @date 2/26/2024 - 1:02:33 PM
 *
 * @type {*}
 */
export const createSubmission = ServiceHandler<
  CreateSubmissionPayload,
  SubmissionDetailsDto
>({
  url: '/api/account/v1/submission',
  method: 'POST',
});

export const fetchSubmissionsStatuses = ServiceHandler<
  unknown,
  SelectOption<string>[]
>({
  url: '/api/account/v1/submission/options/submission-status',
});

export const fetchOperationalStatuses = ServiceHandler<
  unknown,
  SelectOption<string>[]
>({
  url: '/api/account/v1/submission/options/operational-status',
});

export const bulkAssignAssignee = ServiceHandler({
  url: '/api/account/v1/submission/bulk-assign',
  method: 'PUT',
});

type GetSubmissionsUsersResponse = {
  firstName: string;
  lastName: string;
  id: string;
}[];

export const getSubmissionsUsers = ServiceHandler<
  unknown,
  GetSubmissionsUsersResponse
>({
  url: '/api/auth/v1/users/submissions',
});

type UpdateSubmissionDto = {
  assignee?: string;
  needBy?: string;
  operationalStatus?: string;
  firmographics?: Partial<SubmissionFirmographics>;
  dynamicFields?: Record<string, FieldValue>;
};

export const updateSubmission = ServiceHandler<
  UpdateSubmissionDto,
  SubmissionDetailsDto
>({
  url: '/api/account/v1/submission/:submissionId',
  method: 'PUT',
});

export const getSubmissionById = ServiceHandler<unknown, SubmissionDetailsDto>({
  url: '/api/account/v1/submission/:id',
  method: 'GET',
});

export const getSubmissionByMsgId = ServiceHandler({
  url: '/api/account/v1/submission/msgId/:msgId',
});

export const getSubmissionEligibleProducts = ServiceHandler<
  unknown,
  SubmissionEligibleProductsResponse
>({
  url: '/api/account/v1/submission/eligible-products/:id',
});

export const certifySubmission = ServiceHandler({
  url: '/api/account/v1/submission/:id/certify',
  method: 'POST',
});

export const declineSubmission = ServiceHandler<
  DeclineSubmissionPayload,
  unknown
>({
  url: '/api/account/v1/submission/decline/:id',
  method: 'PUT',
});

export type P250SecurityAnswers = {
  claimHistory: number;
  isSecurityOfficer: boolean;
  isSecurityTraining: boolean;
  p250Details: {
    backupFrequency: string;
    civilOrCriminalAction: boolean;
    defaultAggLimit: number;
    lossInBusinessIncome: boolean;
    pastCyberIncident: boolean;
    patchingFrequency: string;
    pendingLitigation: boolean;
    referralReason?: string;
    securityBreachRequiresNotification: boolean;
    uwReferral: boolean;
  };
  securityAnswersDto: {
    backupFrequency: string;
    patchingFrequency: string;
    dmzSeparation?: boolean;
    isAuthenticatingFundTransferRequest?: boolean;
    isPreventingUnauthorizedWireTransfers?: boolean;
    isVerifyingBankAccount?: boolean;
    mfaCloudDeployments?: boolean;
    mfaEmail?: boolean;
    mfaRemoteAccess?: boolean;
    testedFullFailover?: boolean;
    thirdPartySecurityAgreement?: boolean;
  };
  useCloudStorage: boolean;
  useEncryption: boolean;
};

export type LegacySecurityQuestionsDtoResponse = {
  p100SecurityAnswers: P100SecurityQuestions;
  p250SecurityAnswers: P250SecurityAnswers;
};

export const getDynamicFormsLegacyAdapter = ServiceHandler<
  unknown,
  LegacySecurityQuestionsDtoResponse
>({
  url: '/api/account/v1/questions/adapt/:productType',
  method: 'POST',
});

export const getQuoteCartListForP250Submissions = ServiceHandler({
  url: '/api/quote/v1/P250/cart/submission/:submissionId',
  method: 'GET',
});

export const addQuoteToCartForP250Submissions = ServiceHandler({
  url: '/api/quote/v1/P250/cart',
  method: 'POST',
});

export const bulkCreateP250Quotes = ServiceHandler({
  url: '/api/quote/v1/P250/bulk',
  method: 'POST',
});

export const createP250QuoteForSubmission = ServiceHandler({
  url: '/api/quote/v1/P250/submission/:submissionId',
  method: 'POST',
});

export const getActiveSubmissionsForAccount = ServiceHandler<
  unknown,
  PageableResponse<SubmissionTableRow>
>({
  url: '/api/account/v1/submission/active/:accountId',
  method: 'GET',
});

export const updateNonViableSubmission = ServiceHandler<
  CreateSubmissionPayload,
  unknown
>({
  url: '/api/account/v1/submission/non-viable/:submissionId',
  method: 'PUT',
});

export const updateOperationalStatusToCompleted = ServiceHandler({
  url: '/api/account/v1/submission/complete/:submissionId',
  method: 'PUT',
});

export const updateAssigneeOnSubmission = ServiceHandler({
  url: '/api/account/v1/submission/update-assignee/:submissionId',
  method: 'PUT',
});

export const getSubmissionsByAccountId = ServiceHandler<
  unknown,
  SubmissionDetailsDto[]
>({
  url: '/api/account/v1/submission/by-account/:accountId',
});
