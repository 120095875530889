import React from 'react';
import clsx from 'classnames';

// components
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const InputErrorPlace = ({ error, renderErrorPlaceholder }: any) => {
  const styles = useStyles();
  const classes = clsx(styles.root, {
    [styles.placeholder]: renderErrorPlaceholder,
  });

  return <Box className={classes}>{error}</Box>;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.text.text9,
  },

  placeholder: {
    minHeight: '1.66rem',
  },
}));
