import { Api } from '../config/axiosConfig';

/**
 * @deprecated
 */
export const postLoginCredentials = (credentials: any) => {
  return Api.post(`/api/auth/v1/login`, {
    username: credentials.email,
    password: credentials.password,
  });
};

/**
 * @deprecated
 */
export const postLoginCredentialsWithMfa = (credentials: any) => {
  return Api.post(`/api/auth/v1/login`, {
    username: credentials.email,
    password: credentials.password,
    mfaType: credentials.mfaType,
    mfaCode: credentials.mfaCode,
  });
};

export const validatePassword = (params: any, payload: any) => {
  return Api.post('/api/auth/v1/validate-password', payload, { params });
};

export const submitSignupNewAccount = (credentials: any, token: any) => {
  return Api.post(
    `api/auth/v1/signup?validationToken=${token}`,
    JSON.stringify(credentials)
  );
};

export const checkPasswordResetValToken = (token: any) => {
  return Api.get(`/api/auth/v1/checkPasswordReset/${token}`);
};

export function postPasswordResetValToken(valToken: any, passwords: any) {
  return Api.post(`/api/auth/v1/validatePasswordReset/${valToken}`, passwords);
}

export const submitSignupEmail = (payload: any, captcha: any) => {
  return Api.post(`api/auth/v1/email?g-recaptcha-response=${captcha}`, payload);
};

export const checkSignupEmailToken = (token: any) => {
  return Api.get(`api/auth/v1/validationTokens/${token}`);
};

export const joinExistingAccount = (payload: any, token: any) => {
  return Api.post(
    `api/auth/v1/join?validationToken=${token}`,
    JSON.stringify(payload)
  );
};

export const submitNewAccountValToken = (credentials: any, token: any) => {
  return Api.post(
    `api/auth/v1/signup?validationToken=${token}`,
    JSON.stringify(credentials)
  ).catch(() => {
    // console.log('error: ', error.response);
  });
};

export const checkUserInvitedValToken = (token: any) => {
  return Api.get(`/api/auth/v1/checkUserInvited/${token}`);
};

export function postUserInvitedValToken(token: any, passwords: any) {
  return Api.post(`/api/auth/v1/validateUserInvited/${token}`, passwords);
}

export function resendValEmail(email: any) {
  return Api.post(`api/auth/v1/resendValidationEmail/${email}`);
}

export function requestPasswordReset(email: any, captcha: any) {
  return Api.post(
    `api/auth/v1/passwords/${email}/reset?g-recaptcha-response=${captcha}`
  );
}
