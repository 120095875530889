import React from 'react';

import clsx from 'classnames';
// components
import { Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InputLabelBase } from '../InputLabelBase';
import { withShowable } from '../../../console/_global/lib/withShowable';
import { InputErrorPlace } from '../InputErrorPlace';
import { REDACTED_FIELD } from '../../../config/logrocket';

export const SimpleSelect = withShowable(
  ({
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'.
    error,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'helpText' does not exist on type '{}'.
    helpText,
    // @ts-expect-error TS(2339): Property 'offset' does not exist on type '{}'.
    offset = false,
    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    options = [],
    // @ts-expect-error TS(2339): Property 'renderOption' does not exist on type '{}... Remove this comment to see the full error message
    renderOption,
    // @ts-expect-error TS(2339): Property 'variant' does not exist on type '{}'.
    variant = 'default',
    // @ts-expect-error TS(2339): Property 'InputLabelProps' does not exist on type ... Remove this comment to see the full error message
    InputLabelProps = {},
    // @ts-expect-error TS(2339): Property 'isRedacted' does not exist on type '{}'.
    isRedacted = false,
    // @ts-expect-error TS(2339): Property 'ErrorProps' does not exist on type '{}'.
    ErrorProps = { renderErrorPlaceholder: false },
    ...props
  }) => {
    const { offsetStyles } = useCommonStyles();
    const variantClasses = useVariantStyles(variant);

    const classes = clsx('normal-text', className, {
      [offsetStyles]: offset,
    });

    if (label) {
      return (
        <>
          {/* @ts-expect-error TS(2339): Property 'required' does not exist o */}
          <InputLabelBase indent required={props.required} {...InputLabelProps}>
            {label}
          </InputLabelBase>
          <Select
            variant="standard"
            className={classes}
            classes={variantClasses}
            inputProps={{
              // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
              'data-qa': `SELECT_INPUT:${props.name}`,
              // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
              className: props.className ?? '',
            }}
            {...props}
          >
            {renderOptions(options, renderOption, isRedacted)}
          </Select>
          {helpText && !error && <span>{helpText}</span>}
          <InputErrorPlace error={error} {...ErrorProps} />
        </>
      );
    }

    return (
      <Select
        variant="standard"
        className={classes}
        classes={variantClasses}
        {...props}
      >
        {renderOptions(options, renderOption, isRedacted)}
      </Select>
    );
  }
);

function renderOptions(options: any, renderOption = null, isRedacted = false) {
  return options.map((option: any) =>
    typeof renderOption === 'function' ? (
      // @ts-expect-error TS(2349): This expression is not callable.
      renderOption(option)
    ) : (
      <MenuItem
        className={isRedacted ? REDACTED_FIELD : ''}
        key={option.value}
        {...option}
      >
        {option?.label}
      </MenuItem>
    )
  );
}

const useVariantStyles = (variant: any) => {
  if (variant === 'naked') {
    return nakedVariant();
  }

  if (variant === 'contained') {
    return containedStyles();
  }

  return {};
};

const useCommonStyles = makeStyles(() => ({
  offsetStyles: {
    '& $icon': {
      transform: 'translate(18px, -50%)',
    },
    '& $select': {
      transform: 'translateX(18px)',
    },
  },
}));

const containedStyles = makeStyles(({ palette, shape }) => ({
  select: {
    borderRadius: shape.borderRadius,

    '&.MuiInputBase-input': {
      background: palette.background.details,
    },

    '&:focus': {
      backgroundColor: palette.background.details,
    },
  },

  // select: {
  //   border: 'none',
  // },

  disabled: {
    backgroundColor: palette.background.disabled,
  },
}));

const nakedVariant = makeStyles(() => ({
  icon: {
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },

  select: {
    fontSize: 'inherit',
    justifyContent: 'flex-end',
    padding: 0,
    paddingRight: '18px !important',
    border: 'none',
    background: 'inherit',
  },
}));
