import React, {
  cloneElement,
  createElement,
  isValidElement,
  useContext,
} from 'react';

import {
  TableFooter as MuiTableFooter,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import { TablePagination } from './pagination.table';
import { TableContext } from './context.table';

const styles = ({ palette }: any) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    background: palette.background.lighter,
  },

  rowRoot: {
    background: palette.background.lighter,
  },
});

export const TableFooter = withStyles(styles)(
  ({ render, children, classes, show = true, ...props }: any) => {
    const {
      // @ts-expect-error TS(2339): Property 'onPageChange' does not exist on type '{ ... Remove this comment to see the full error message
      onPageChange,
      // @ts-expect-error TS(2339): Property 'page' does not exist on type '{ onSort: ... Remove this comment to see the full error message
      page,
      // @ts-expect-error TS(2339): Property 'rowsPerPage' does not exist on type '{ o... Remove this comment to see the full error message
      rowsPerPage,
      // @ts-expect-error TS(2339): Property 'rowsPerPageOptions' does not exist on ty... Remove this comment to see the full error message
      rowsPerPageOptions,
      // @ts-expect-error TS(2339): Property 'changePageSize' does not exist on type '... Remove this comment to see the full error message
      changePageSize,
      // @ts-expect-error TS(2339): Property 'totalRows' does not exist on type '{ onS... Remove this comment to see the full error message
      totalRows,
    } = useContext(TableContext);

    if (!show) {
      return null;
    }

    if (typeof render === 'function') {
      return (
        <MuiTableFooter>
          <MuiTableRow className={classes.row}>
            <MuiTableCell>{render(props)}</MuiTableCell>
          </MuiTableRow>
        </MuiTableFooter>
      );
    }

    return (
      <MuiTableFooter>
        <MuiTableRow className={classes.rowRoot}>
          <MuiTableCell>
            <div className={classes.row}>
              {React.Children.map(children, (child) =>
                isValidElement(child)
                  ? // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
                    cloneElement(child, { ...child.props, ...props })
                  : createElement('div', { ...child.props, ...props }, child)
              )}
            </div>
          </MuiTableCell>
          <MuiTableCell>
            <TablePagination
              // @ts-expect-error TS(2322): Type '{ onPageChange: any; changePageSize: any; pa... Remove this comment to see the full error message
              onPageChange={onPageChange}
              changePageSize={changePageSize}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              totalRows={totalRows}
            />
          </MuiTableCell>
        </MuiTableRow>
      </MuiTableFooter>
    );
  }
);

// @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'JSX... Remove this comment to see the full error message
TableFooter.displayName = 'TableFooter';
