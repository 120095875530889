import React from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ContentCopyIcon from '@mui/icons-material/FilterNone';

// components
import { InputAdornment, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import { noop } from 'lodash';
import TextField from './TextField';

const CopyField = (props: any) => {
  return (
    <TextField
      {...props}
      disabled
      inputProps={{
        classes: {
          input: props.classes.input,
        },
      }}
      endAdornment={
        <InputAdornment
          classes={{ root: props.classes.adornmentRoot }}
          position="end"
        >
          <CopyToClipboard text={props.value} onCopy={props.onCopy}>
            <IconButton size="large">
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </InputAdornment>
      }
    />
  );
};

CopyField.defaultProps = {
  onCopy: noop,
};

const styles = () => {
  return {
    input: {
      textOverflow: 'ellipsis',
      paddingRight: 40,
    },
  };
};

export default withStyles(styles)(CopyField);
