import * as Sentry from '@sentry/react';

import _ from 'lodash';

import { getEnvironment } from '../../api/AuthService';
import { randomBoolean } from '../../utils/appUtils';

const UK_STAGING_DOMAIN = 'cowshavebells.co.uk';

export async function initializeSentry() {
  try {
    const { data: env } = await getEnvironment();
    const isUKStaging = new URL(window.location.href).hostname.endsWith(
      UK_STAGING_DOMAIN
    );

    Sentry.init({
      dsn: 'https://b09ef71d535d45539516a26c13d72f06@o279010.ingest.sentry.io/5471177',
      enabled: env.prod && !isUKStaging,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: true,
          blockAllMedia: false,
          networkDetailAllowUrls: ['api'],
        }),
      ],
      environment: env.envName == 'prod' ? 'production' : env.envName,
      // * currently only defined in the build for non-development environments

      release: import.meta.env.REACT_APP_SENTRY_RELEASE,
      tracesSampleRate: 0.1,

      // * non-error related replay capture rate
      replaysSessionSampleRate: 0,

      // * error related replay capture rate
      replaysOnErrorSampleRate: 1.0,

      ignoreErrors: [
        '401',
        '400',
        '403',
        '404',
        '500',
        '502',
        '503',
        '504',
        'Request Cancelled',
        'Request aborted',
        'Request failed with status code 524',
        'Request failed with status code 409',
        'Network Error',
        // Chunk loading errors
        'Loading chunk',
        'Failed to fetch dynamically imported module',
        // Google Tag (Benign)
        'Failed to fetch',
        // Error related to Microsoft Outlook
        'Non-Error promise rejection captured with value: undefined',
        // Chrome extension error that manages to bubble up to Sentry
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
        // Benign chrome bug
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'timeout exceeded',
      ],

      // filter or mutate breadcrumbs and events before they are sent
      beforeBreadcrumb: callAllAsMiddleware(
        omitMuiErrors,
        limitXhrErrorsByStatus
      ),
      beforeSend: callAllAsMiddleware(
        omitCancelledErrors,
        omitGoogleRecaptchaException
      ),
    });
  } catch (err) {
    console.error('Failed to initialize Sentry');
    console.error(err);
  }
}

// beforeBreadcrumb middleware
function omitMuiErrors(breadcrumb: any) {
  const isMuiError = breadcrumb?.message?.includes?.('Material-UI');
  return isMuiError ? null : breadcrumb;
}

function limitXhrErrorsByStatus(breadcrumb: any, hint: any) {
  const statusesToLimit = [401, 403, 404, 500, 502, 503];
  const breadcrumbStatus =
    breadcrumb?.data?.status_code ||
    hint?.xhr?.status ||
    hint?.input?.[0]?.response?.status;

  if (statusesToLimit.includes(breadcrumbStatus)) {
    const shouldSendEvent = randomBoolean(10);
    return shouldSendEvent ? breadcrumb : null;
  }
  return breadcrumb;
}

// beforeSend middleware
function omitCancelledErrors(event: any, hint: any) {
  const errorMsg = _.get(hint, 'originalException.message');
  if (errorMsg === 'cancelled') {
    return null;
  }
  return event;
}

// Someone decided to reject a promise with a primtive value in the Recaptcha package :|
// Dropping so that it does not fill our quota
function omitGoogleRecaptchaException(event: any, hint: any) {
  if (hint.originalException === 'Timeout') {
    return null;
  }
  return event;
}

function callAllAsMiddleware(...fns: any[]) {
  return (exception: any, hint: any) => {
    return fns
      .filter((fn) => typeof fn === 'function')
      .reduce((pipedException, fn) => {
        if (pipedException == null) {
          return pipedException;
        }

        return fn(pipedException, hint);
      }, exception);
  };
}
