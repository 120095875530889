import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Box, Container, Divider, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';

import { SimpleFooter } from '../../components/entry/SimpleFooter';
import PublicComponentWrapper from '../../components/wrappers/PublicComponentWrapper';
import CbButton from '../../components/Buttons/CbButton';
import { PubSub } from '../../utils/eventUtils';
import withRouter from '../../components/hocs/withRouter';
// services
import { getPolicyDetailsWithFirmo } from '../../policies/PolicyService';
import { toggleModalDirect } from '../../utils/storeUtils';
import {
  loadOneIncByPolicyNumber,
  moneyFormat2Decimals,
  useJQuery,
} from '../../utils/appUtils';
import {
  getBalanceOnPolicy,
  getPaymentMethods,
  getPaymentStatus,
  getTitleDesc,
  getXpressStatus,
  paymentComplete,
  savePayment,
} from '../../console/customers/_services/BillingService';
import { MultiSelect } from '../../components/Selects';
import { useActor } from '../../components/hooks/useActor';
import { toUniversalUtcDate } from '../../utils/date.utils';
import { manageAPIError } from '../../utils';

const styles = ({ config, palette, ...theme }: any) => {
  return {
    heading1: {
      margin: 0,
      marginTop: '2.5rem',
      fontSize: config.textSizes.canon,
      lineHeight: 1,
      textAlign: 'center',
      color: 'white',
    },
    main: {
      backgroundColor: palette.background.lighter,
      padding: '2.1667rem 6.083rem',
    },
    bodyContainer: {
      padding: '2.5rem 0',
      color: palette.primary.main,
      marginBottom: '2.5rem',
      display: 'flex',
    },
    horizontalLine: {
      borderBottom: `1px solid ${config.colors.cowbellBlue}`,
    },
    optionContainer: {
      width: '24.5rem',
    },
    optionContentContainer: {
      textAlign: 'center',
      padding: '2rem 0 1rem 0',
      height: '9.75rem',

      '&:hover': {
        cursor: 'pointer',
        color: palette.primary.contrastText,
        fontWeight: config.weights.bold,
        backgroundColor: palette.background.green5,
      },
    },
    optionContentContainer1: {
      textAlign: 'center',
      padding: '2rem 0 1rem 0',
      height: '9.75rem',

      '&:hover': {
        cursor: 'pointer',
        color: palette.primary.contrastText,
        fontWeight: config.weights.bold,
        backgroundColor: palette.background.lightNavy,
      },
    },
    icon: {
      width: '3rem',
      height: '3rem',
    },
    optionName: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      margin: '0 !important',
      color: config.colors.white,
    },
    optionName1: {
      fontSize: '0.833rem',
      lineHeight: 1.5,
      margin: '0 !important',
      color: config.colors.white,
    },
    option1: {
      border: `2px solid ${palette.primary.border7}`,
      borderRadius: 5,
      color: palette.primary.contrastText,
    },
    option2: {
      color: palette.primary.contrastText,
      border: `2px solid ${palette.primary.border2}`,
      borderRadius: 5,
    },
    optionDetails: {
      color: config.colors.white,
      fontSize: '1rem',
      lineHeight: 1.33,
      padding: '0 0.7rem',
      textAlign: 'center',
    },
    emailContainer: {
      display: 'flex',
    },
    emailIcon: {
      width: '2rem',
      height: '2rem',
    },
    emailText: {
      fontSize: '1.167rem',
      lineHeight: 1.5,
      fontStyle: 'italic',
      margin: '0 !important',
      color: palette.primary.contrastText,
    },
    clickHere: {
      fontWeight: config.weights.bold,
      color: palette.primary.title,
      cursor: 'pointer',
    },
    paymentsGroup: {
      width: '100%',
    },
    group1: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      height: '26.8333rem',
      float: 'left',
      margin: '0 2rem',
    },
    divider1: {
      height: '18.8333rem',
      float: 'left',
      margin: '0 2rem',
    },
    rightGroup: {
      float: 'left',
      width: '24.5rem',
    },
    leftGroup: {
      float: 'left',
      width: '51rem',
      display: 'flex',
      flexDirection: 'column',
    },
    labelClass: {
      margin: '0 !important',
    },
    marginTop1: {
      marginTop: '8rem',
    },
    marginTop2: {
      marginTop: '3rem',
    },
    sectionHeader: {
      width: '100%',
      height: '3.33rem',
      lineHeight: '3.33rem',
      backgroundColor: '#0a263e',
      marginTop: '2rem',
      padding: '0 1rem',
      color: config.colors.white,
      fontSize: config.textSizes.paragon,
      display: 'flex',
      justifyContent: 'space-between',

      '& span': {
        fontWeight: config.weights.bold,
      },
    },
    textHeader: {
      borderColor: `${config.colors.cowbellBlue} !important`,
      borderStyle: 'solid',
      borderWidth: '1px 0',
      borderBottom: 'unset',
      padding: '2rem 0',
      color: config.colors.white,
    },
    backdrop: {
      zIndex: `${theme.zIndex.drawer + 1}`,
      color: palette.text.link,
    },
    failContainer: {
      paddingTop: 20,
      whiteSpace: 'nowrap',
    },
  };
};

let type = '';
let tokenId: any = null;

export const AccountantPaymentConfirm = withRouter(
  // @ts-expect-error TS(2345): Argument of type '({ config, palette, ...theme }: ... Remove this comment to see the full error message
  withStyles(styles)(({ classes, match }) => {
    const [data, setData] = useState(null);
    const [id] = useState(match.params.id);
    const [pNumber, setNumber] = useState('');
    const [titleData, setTitleData] = useState('');
    const [downPayment, setPayment] = useState(0);
    const [balance, setBalance] = useState(0);
    const [error, setError] = useState('');
    const [xpressEnabled, setEnabled] = useState(true);

    const [redirect, setRedirect] = useState(false);
    const [payComplete, setPay] = useState(false);
    const [status, setStatus] = useState('');
    const actor = useActor();

    const titleInfo = () => {
      getTitleDesc({ policyId: id }).then((resp: any) => {
        setTitleData(resp.data);
      });
    };

    const policyBalance = React.useCallback(() => {
      getBalanceOnPolicy({ policyId: id })
        .then((resp: any) => {
          setBalance(resp.data);
        })
        .catch((error: any) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong. Try again later.'
              )
            )
          );
        });
    }, [id]);

    const checkForPayment = React.useCallback(() => {
      getPaymentStatus({ policyId: id })
        .then((resp: any) => {
          setPay(resp.data.isPaid);
          setStatus(resp.data.status);
          setNumber(resp.data.policyNumber);
          if (resp.data.isPaid) {
            setRedirect(true);
            policyBalance();
          } else {
            setRedirect(false);
          }
        })
        .catch(() => {
          setRedirect(false);
        });
    }, [id, policyBalance]);

    const { isLoaded } = useJQuery();
    useEffect(() => {
      policyBalance();
      const sub = PubSub.subscribe('financing:accpeted', () => {
        titleInfo();
        policyBalance();
      });

      getPaymentStatus({ policyId: id })
        .then((resp: any) => {
          setPay(resp.data.isPaid);
          setStatus(resp.data.status);
          setNumber(resp.data.policyNumber);
          if (resp.data.isPaid) {
            setRedirect(true);
          } else {
            setRedirect(false);
          }
        })
        .catch(() => {
          setRedirect(false);
        });

      titleInfo();
      getXpressStatus()
        .then((resp: any) => {
          setEnabled(resp.data);
        })
        .catch((error: any) => {
          const errorMessage = manageAPIError(
            error,
            'Something went wrong. Try again later.'
          );

          setError(errorMessage);
        });

      return () => {
        sub.remove();
      };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const cust = _.isUndefined(actor.account);
      const title = _.isEmpty(titleData);

      if (!cust && !title && isLoaded) {
        // @ts-expect-error TS(2339): Property 'productType' does not exist on type 'str... Remove this comment to see the full error message
        getPolicyDetailsWithFirmo(id, titleData.productType)
          .then((policyResponse) => {
            return policyResponse.data;
          })
          .then((policy) => {
            return getPaymentMethods().then((payment: any) => {
              const pay = payment.data;
              return [policy, pay];
            });
          })
          .then((arrayResponse) => {
            loadOneIncByPolicyNumber(arrayResponse[0].policyNumber).then(
              (keyResp: any) => {
                arrayResponse.push(keyResp.data);
                setData(arrayResponse);
                const policy = arrayResponse[0];

                const brokerFee = _.isUndefined(policy.brokerFee)
                  ? 0
                  : policy.brokerFee;
                const mgaFee = _.isUndefined(policy.mgaFee) ? 0 : policy.mgaFee;

                if (balance) {
                  setPayment(
                    (
                      (balance - brokerFee - mgaFee) * 0.2 +
                      brokerFee +
                      mgaFee
                    ).toFixed(2)
                  );
                }

                const typeMappings = {
                  'Credit Card': 'creditCard',
                  'Bank Account': 'bankAccount',
                };

                const payments = arrayResponse[1].map((item: any) => {
                  return {
                    label: item.title,
                    value: {
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      ptype: typeMappings[item.type],
                      token: item.tokenId,
                    },
                  };
                });

                const oneIncKey = arrayResponse[2];
                // @ts-expect-error TS(2345): Argument of type '{ policy: any; payments: any; on... Remove this comment to see the full error message
                setData({ policy, payments, oneIncKey });
              }
            );
          })
          .catch((error) => {
            const errorMessage = manageAPIError(
              error,
              'Something went wrong. Try again later.'
            );

            setError(errorMessage);
          });
      }
    }, [
      actor,
      titleData,
      isLoaded,
      id,
      balance,
      policyBalance,
      checkForPayment,
    ]);

    const optionSelect = () => {
      // @ts-expect-error TS(2339): Property 'policy' does not exist on type 'null'.
      const { policy } = data;

      if (type === 'ACH') {
        const Icon = (
          <img
            src="images/Quotes.svg"
            style={{
              width: '2.75rem',
              height: '2.75rem',
              margin: '1.667rem 1.25rem',
            }}
            alt="Quote icon"
          />
        );
        toggleModalDirect(
          'ACHWireTransfer',
          { policyNumber: policy.policyNumber, ...policy.firmographicData },
          {
            maxWidth: 'md',
            title: `Pay ${moneyFormat2Decimals(
              balance
            )} with ACH or Wire Transfer`,
            buttonText: 'OK',
            iconComponent: Icon,
          }
        );
        return null;
      }

      if (type === 'EXC') {
        const Icon = (
          <img
            src="images/financingPlan.svg"
            style={{
              width: '2.75rem',
              height: '2.75rem',
              margin: '1.667rem 1.25rem',
            }}
            alt="Financing Plan Icon"
          />
        );
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        toggleModalDirect('InstallmentPlan', data.policy, {
          maxWidth: 'md',
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          title: `INSTALLMENT PLAN for Policy# ${data.policy.policyNumber}`,
          buttonText: 'OK',
          iconComponent: Icon,
        });
        return null;
      }

      let paymentCategory: any;
      if (type === 'creditCard') {
        paymentCategory = 'CreditCard';
      } else if (type === 'bankAccount') {
        paymentCategory = 'ECheck';
      }

      window.$('#portalOneContainer').portalOne();
      const xhttp = new XMLHttpRequest();
      xhttp.overrideMimeType('application/json');
      xhttp.onreadystatechange = function () {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
          const resp = JSON.parse(xhttp.responseText);
          const sessionID = resp.PortalOneSessionKey;
          window

            .$('#portalOneContainer')
            .data('portalOne')
            .makePayment({
              paymentCategory,
              feeContext: 'PaymentWithFee',
              amountContext: 'AmountDue',
              minAmountDue: balance,
              accountBalance: '0',
              // @ts-expect-error TS(2531): Object is possibly 'null'.
              accountGroupCode: data.oneIncKey.authCode,
              billingZip: '',
              billingAddressStreet:
                '6800 Koll Center Pkwy. Suite 250, Pleasanton, CA',
              policyHolderName: actor.fullName,
              referenceNumber: '',
              saveOption: 'UserSelect',
              clientReferenceData1: policy.policyNumber,
              clientReferenceData2: moment(policy.created)
                .add(7, 'days')
                .format('L'),
              // @ts-expect-error TS(2339): Property 'premiumFinanced' does not exist on type ... Remove this comment to see the full error message
              clientReferenceData3: titleData.premiumFinanced
                ? 'true'
                : 'false',
              clientReferenceData4: policy.id,
              clientReferenceData5: 'POL-12345-ClientReferenceData5',
              sessionId: sessionID,
              displayMode: 'modal',
              confirmationDisplay: 'true',
              ...(tokenId && { token: tokenId }),
            });

          // clearing stale listeners

          window.$('#portalOneContainer').off('portalOne.paymentComplete');

          window.$('#portalOneContainer').off('portalOne.unload');

          window.$('#portalOneContainer').off('portalOne.saveComplete');

          // setting new listeners
          window

            .$('#portalOneContainer')
            .on('portalOne.paymentComplete', function (e: any, d: any) {
              if (d.acknowledge) {
                d.acknowledge();
              }

              const payload = {
                transactionId: d.transactionId,
                transactionDate: moment(d.transactionDate).valueOf(),
                paymentAmount: d.paymentAmount,
                totalPaymentAmount: d.totalPaymentAmount,
                policyId: policy.id,
                policyNumber: policy.policyNumber,
                lastFourDigits: _.get(d, 'transactions[0].lastFourDigits', ''),
                customerEmail: policy.customerEmail,
                quoteId: policy.quoteId,
                agencyId: policy.agencyId,
                policyCreated: policy.created,
                bankName: d.bankName || '',
                cardType: d.cardType || '',
                // @ts-expect-error TS(2339): Property 'premiumFinanced' does not exist on type ... Remove this comment to see the full error message
                isDownPayment: titleData.premiumFinanced,
              };

              paymentComplete({}, payload)
                .then()
                .catch((error: any) => {
                  const errorMessage = manageAPIError(
                    error,
                    'Something went wrong. Try again later.'
                  );

                  setError(errorMessage);
                });
            });

          window.$('#portalOneContainer').on('portalOne.unload', function () {
            checkForPayment();
          });

          window

            .$('#portalOneContainer')
            .on('portalOne.saveComplete', function (e: any, d: any) {
              if (type === 'creditCard') {
                const payload = {
                  tokenId: d.tokenId,
                  paymentCategory: d.paymentCategory,
                  customerEmail: actor.email,
                  expirationMonth: d.cardExpirationMonth,
                  expirationYear: d.cardExpirationYear,
                  creditCardType: d.cardType,
                  last4Digits: d.lastFourDigits,
                };

                savePayment({}, payload).catch((error: any) => {
                  const errorMessage = manageAPIError(
                    error,
                    'Something went wrong. Try again later.'
                  );

                  setError(errorMessage);
                });
              } else if (type === 'bankAccount') {
                const payload = {
                  tokenId: d.tokenId,
                  paymentCategory: d.paymentCategory,
                  customerEmail: policy.customerEmail,
                  last4Digits: d.lastFourDigits,
                  bankAccountType: d.accountType,
                  bankName: d.bankName,
                };

                savePayment({}, payload).catch((error: any) => {
                  const errorMessage = manageAPIError(
                    error,
                    'Something went wrong. Try again later.'
                  );

                  setError(errorMessage);
                });
              }
            });
        }
      };
      xhttp.open(
        'GET',
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        `${data.oneIncKey.url}/Api/Api/Session/Create?portalOneAuthenticationKey=${data.oneIncKey.key}`,
        true
      );
      xhttp.send();
    };

    const handleSelectSavedPayment = (name: any, selection: any) => {
      const { value } = selection;
      type = value.ptype;
      tokenId = value.token;
      optionSelect();
    };

    const failedPayment = () => {
      setStatus('Unpaid');
    };

    if (!data) {
      return (
        <section>
          <div id="portalOneContainer" />
        </section>
      );
    }

    // @ts-expect-error TS(2339): Property 'policy' does not exist on type 'never'.
    const policyNo = _.isUndefined(data.policy)
      ? pNumber
      : // @ts-expect-error TS(2339): Property 'policy' does not exist on type 'never'.
        data.policy.policyNumber;

    if (redirect && (payComplete || status === 'Paid')) {
      return (
        <PublicComponentWrapper>
          <h1 className={classes.heading1} style={{ lineHeight: 1.3 }}>
            Thank you for making payment for Policy# {policyNo}.
          </h1>
        </PublicComponentWrapper>
      );
    }

    if (redirect && status === 'Processed') {
      return (
        <PublicComponentWrapper>
          <h1 className={classes.heading1} style={{ lineHeight: 1.3 }}>
            Payment is being processed for Policy# {policyNo}.
          </h1>
        </PublicComponentWrapper>
      );
    }

    if (status === 'Failed') {
      return (
        <PublicComponentWrapper>
          <h1 className={classes.heading1} style={{ lineHeight: 1.3 }}>
            Your payment failed for <br />
            Policy# {policyNo} .
          </h1>
          <div className={classes.failContainer}>
            <p className="modal-title">
              For more information on the transaction, please contact
              support@cowbellcyber.ai.
            </p>
            <p className="modal-title">
              If you wish to continue with payment please click the link below
            </p>
          </div>
          <CbButton
            action={failedPayment}
            styleName="ctaButton"
            buttonText="Make Payment"
            style={{ marginTop: 20 }}
          />
        </PublicComponentWrapper>
      );
    }

    return (
      (status === 'Unpaid' || status === 'Partially Paid') && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Container maxWidth="false" style={{ padding: '0 13rem' }}>
          <Box margin="2.5rem auto" maxWidth="13.9166666667rem">
            <img src="/logos/logo@3x.png" alt="cowbell logo" />
          </Box>
          <h1 className={classes.heading1}>
            Welcome {actor.flowUserFirstName} to Cowbell Cyber
            <p style={{ margin: 10, fontWeight: 600 }}>
              {actor.flowAccountName}
            </p>
          </h1>

          <section
            className="page-container"
            // @ts-expect-error TS(2322): Type '{ height: string; } | null' is not assignabl... Remove this comment to see the full error message
            style={titleData.premiumFinanced ? { height: 'unset' } : null}
          >
            <div id="portalOneContainer2" />

            <section
              className="main-container"
              style={{
                height: 'unset',
                backgroundColor: 'unset',
                padding: '2.1667rem 6.083rem',
              }}
            >
              <section className={`${classes.textHeader} header-container `}>
                {/* @ts-expect-error: FIXME */}
                <p className="header-title">{titleData.description} </p>
                <p className="header-title">
                  Due Date: {/* @ts-expect-error: FIXME */}
                  {toUniversalUtcDate(titleData.dueDate)}
                </p>
              </section>
              {/* @ts-expect-error: FIXME */}
              {!titleData.premiumFinanced && (
                <section className={classes.sectionHeader}>
                  PAY IN FULL
                  {/* @ts-expect-error: FIXME */}
                  <span>{moneyFormat2Decimals(titleData.dueAmount)}</span>
                </section>
              )}
              <section className={classes.bodyContainer}>
                <section className={classes.paymentsGroup}>
                  <section className={classes.leftGroup}>
                    {/* @ts-expect-error: FIXME */}
                    {data.payments && data.payments.length > 0 && (
                      <MultiSelect
                        labelClass={classes.labelClass}
                        label="Select Payment Method"
                        // @ts-expect-error TS(2339): Property 'payments' does not exist on type 'never'... Remove this comment to see the full error message
                        options={data.payments}
                        onChange={handleSelectSavedPayment}
                        isMulti={false}
                        fullWidth
                        className={classes.select}
                        data-qa="ownershipType"
                      />
                    )}
                    <section className={classes.group1}>
                      <section
                        className={`${classes.optionContainer} ${
                          // @ts-expect-error TS(2339): Property 'payments' does not exist on type 'never'... Remove this comment to see the full error message
                          data.payments && data.payments.length > 0
                            ? classes.marginTop2
                            : ''
                        }`}
                      >
                        <Box
                          className={`${classes.optionContentContainer} ${classes.option1}`}
                          onClick={() => {
                            type = 'bankAccount';
                            tokenId = null;
                            optionSelect();
                          }}
                        >
                          <img
                            alt=""
                            src="images/PayByBankAccount.svg"
                            className={classes.icon}
                          />
                          <p className={classes.optionName}>PAY BY e-CHECK</p>
                        </Box>
                        <p className={classes.optionDetails}>
                          If you want to avoid the credit card processing fees,
                          please use ‘Pay by e-Check to make payment.
                        </p>
                      </section>
                      <section
                        className={`${classes.optionContainer} ${
                          // @ts-expect-error TS(2339): Property 'payments' does not exist on type 'never'... Remove this comment to see the full error message
                          data.payments && data.payments.length > 0
                            ? classes.marginTop2
                            : ''
                        }`}
                      >
                        <Box
                          className={`${classes.optionContentContainer} ${classes.option1}`}
                          onClick={() => {
                            type = 'creditCard';
                            tokenId = null;
                            optionSelect();
                          }}
                        >
                          <img
                            alt=""
                            src="images/PayByCreditCard.svg"
                            className={classes.icon}
                          />
                          <p className={classes.optionName}>
                            PAY BY CREDIT CARD
                          </p>
                          <p className={classes.optionName1}>
                            Using{' '}
                            <span style={{ fontWeight: 600 }}>One Inc</span>, a
                            third party payment processor
                          </p>
                        </Box>
                        <p className={classes.optionDetails}>
                          I understand and agree that by clicking ‘Pay by Credit
                          Card’ I am leaving the Cowbell Cyber platform to make
                          a payment in a One Inc payment input form. One Inc is
                          the third-party payment solutions provider that will
                          make a payment to Cowbell Cyber on my behalf.
                        </p>
                      </section>
                    </section>
                  </section>
                  <Divider
                    orientation="vertical"
                    flexItem
                    classes={{
                      root:
                        // @ts-expect-error TS(2339): Property 'payments' does not exist on type 'never'... Remove this comment to see the full error message
                        data.payments && data.payments.length > 0
                          ? classes.divider
                          : classes.divider1,
                    }}
                  />
                  <section
                    className={`${classes.rightGroup} ${
                      // @ts-expect-error TS(2339): Property 'payments' does not exist on type 'never'... Remove this comment to see the full error message
                      data.payments && data.payments.length > 0
                        ? classes.marginTop1
                        : ''
                    }`}
                  >
                    <Box
                      className={`${classes.optionContentContainer1} ${classes.option2}`}
                      onClick={() => {
                        type = 'ACH';
                        optionSelect();
                      }}
                    >
                      <img
                        alt=""
                        src="images/PayByACH_Wire.svg"
                        className={classes.icon}
                      />
                      <p className={classes.optionName}>ACH/WIRE TRANSFER</p>
                    </Box>
                    <p className={classes.optionDetails}>
                      If you would like to directly make the payment to Cowbell
                      Cyber’s bank account, use this option to get the routing
                      number, account number and bank details.
                    </p>
                  </section>
                </section>
              </section>
              {error && (
                <FormHelperText
                  className="api-text-error"
                  style={{ textAlign: 'end' }}
                >
                  {error}
                </FormHelperText>
              )}
              {/* @ts-expect-error: FIXME */}
              {xpressEnabled && !titleData.premiumFinanced && (
                <>
                  <section className={classes.sectionHeader}>
                    INSTALLMENT PLAN{' '}
                    <span>
                      Down Payment: {moneyFormat2Decimals(downPayment)}
                    </span>
                  </section>
                  <section
                    className={`${classes.bodyContainer} ${classes.horizontalLine}`}
                  >
                    <section className={classes.paymentsGroup}>
                      <section className={`${classes.rightGroup}`}>
                        <Box
                          className={`${classes.optionContentContainer1} ${classes.option2}`}
                          onClick={() => {
                            type = 'EXC';
                            optionSelect();
                          }}
                        >
                          <img
                            alt=""
                            src="images/financingPlan.svg"
                            className={classes.icon}
                          />
                          <p className={classes.optionName}>INSTALLMENT PLAN</p>
                          <p className={classes.optionName1}>
                            Using Xpress Capital
                          </p>
                        </Box>
                        <p className={classes.optionDetails}>
                          If you would like to use premium financing to make
                          payments in 10 installments, use this option
                        </p>
                      </section>
                    </section>
                  </section>
                </>
              )}
            </section>
            <SimpleFooter />
          </section>
        </Container>
      )
    );
  })
);
