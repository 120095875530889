import { useQuery } from '@tanstack/react-query';
import { getMe } from '../../api/UserService';

useMeQuery.QUERY_KEY = 'me';
export function useMeQuery(options = {}) {
  return useQuery(
    [useMeQuery.QUERY_KEY],
    () => getMe().then((resp: any) => resp.data),
    options
  );
}
