import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';
import CBButton from '../../../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../../../utils/storeUtils';
import { PubSub } from '../../../../../utils/eventUtils';

export const UWSwitchingTabs = ({ data, ...props }: any) => {
  const { classes, tabId } = data;
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    toggleModalDirect('UWSwitchingTabs', false);
  };

  const handleSwitch = () => {
    toggleModalDirect('UWSwitchingTabs', false);
    PubSub.publish('CBTabsComponent-switchingTabs', tabId);
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={classes.description}>
          Are you sure you want to switch to a different tab?
          <br />
          There are unsaved changes which will be lost.
          <br />
          Go back to save changes!
        </p>
        <DialogActions>
          <CBButton action={handleSwitch} styleName="cancel">
            SWITCH
          </CBButton>
          <CBButton type="submit" styleName="ctaButton" buttonText="GO BACK" />
        </DialogActions>
      </form>
    </section>
  );
};
