import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { DialogContent } from '@mui/material';

import { withStyles } from '@mui/styles';
import { PubSub } from '../../utils/eventUtils';
import { regenerateSecret } from '../../api/AuthService';
import { toggleModalDirect } from '../../utils/storeUtils';

const RegenKey = ({ classes, data }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData: any) => {
      if (eventData) {
        onSubmit();
      }
    });

    return () => {
      sub.remove();
    };
  });

  const onSubmit = () => {
    regenerateSecret({ keyId: id })
      .then((resp: any) => {
        toggleModalDirect('RegenKey', false);
        enqueueSnackbar('Successfully regenerated the API Key', {
          variant: 'success',
        });
        PubSub.publish('table-refetch', 'apiKeys');
        const Icon = (
          <img
            src="images/Quotes.svg"
            style={{
              width: '2.75rem',
              height: '2.75rem',
              margin: '1.667rem 1.25rem',
            }}
            alt="Quotes icon"
          />
        );
        const d = _.get(resp, 'data', { id: '', secret: '' });
        toggleModalDirect('KeyGenerated', d, {
          title: 'API Access Key Regenerated',
          maxWidth: 'md',
          footerButtons: [{ buttonText: 'Close', action: 'cancel' }],
          iconComponent: Icon,
        });
      })
      .catch((error: any) => {
        console.error(error);
        toggleModalDirect('RegenKey', false);
      });
  };

  return (
    <DialogContent className={classes.container}>
      <section className={classes.fieldContainer}>
        Are you sure you want to regenerate the API Access Key? <br /> If you
        regenerate, the previous access key will become invalid.
      </section>
    </DialogContent>
  );
};

const styles = ({ palette }: any) => {
  return {
    fieldContainer: {
      width: '100%',
      marginBottom: '0.5rem',
      fontSize: '1.33rem',
      lineHeight: 1.13,
      textAlign: 'center',
      color: palette.text.primary,
    },
    container: {
      width: '40rem',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'left',
    },
  };
};

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { fieldCon... Remove this comment to see the full error message
export default withStyles(styles)(RegenKey);
