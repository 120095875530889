import React from 'react';
import { compose } from 'redux';

import { BaseTable } from '../base/base.table';
import { withReorderColumns } from './enhancements';

export const EnhancedTable = compose(withReorderColumns)(
  ({ children, context, ...props }: any) => {
    let onSelectAll = () => null;

    if (context.createOnSelectAll) {
      onSelectAll = context.createOnSelectAll(props.data);
    }

    return (
      <BaseTable
        {...props}
        context={{
          onSelectAll,
          ...context,
        }}
      >
        {children}
      </BaseTable>
    );
  }
);
