import _ from 'lodash';
import Axios from 'axios';
import qs from 'qs';

import { calculateDuration } from './RangeUtils';
import { Api } from '../config/axiosConfig';

// uses a mapping / config file that knows how to
// transform data and map the final params
export function formatFinalParams(MAP: any, data: any) {
  const params = {};

  for (let i = 0; i < MAP.length; i += 1) {
    const config = MAP[i];

    // key in the final payload
    let paramKey = config.path;
    if (config.paramKey) {
      paramKey =
        typeof config.paramKey === 'function'
          ? config.paramKey(data)
          : config.paramKey;
    }

    // final value, transformed or not
    const value =
      typeof config.transform === 'function'
        ? config.transform(data)
        : data[config.path];

    // gives us a way to omit undefineds from final params
    if (value || _.isNumber(value)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      params[paramKey] = value;
    }
  }

  return params;
}

export function prepFilterParams({ f }: any) {
  if (!f) {
    return {};
  }

  const nextFilters = Object.keys(f).reduce(
    (acc, key) => {
      const column = key.replace('-', '.');
      let filterValue = f[key];

      if (Array.isArray(filterValue.in)) {
        filterValue = { in: filterValue.in.join(',') };
      }

      if (Array.isArray(filterValue.ni)) {
        filterValue = { ni: filterValue.ni.join(',') };
      }

      return {
        ...acc,
        filter: {
          ...acc.filter,
          [column]: filterValue,
        },
      };
    },
    { filter: {} }
  );

  return nextFilters;
}

const ServiceHandler = {
  // holds the cancelable call back provided by axios
  // when the request is cancelable
  cancelRequest: null,

  // sets instance default options
  options: {
    // axios object can take anything the underlying axios
    // configuration object can accept (e.g. headers)
    axios: {
      method: 'get',
    },
    // axios instance
    Service: Api,
    // service mapping
    paramSchema: {},
    // mapping for final payload
    payloadMap: null,
    // flag to enable auto cancelable
    isCancelable: false,
  },

  init() {
    return this.makeRequest.bind(this);
  },

  /**
   * Make Request
   * @param {object} _params hold both query params and path param replacements
   * @param {object} data payload data for post, put, patch calls
   *
   * Special Note:
   * _params object takes both path param replacements & normal query params.
   * It is worth noting that since this object combines both path and query params,
   * that path params (e.g. /therats/:threatId) should be uniquly named and must match
   * the path param sepecified in the url, in the corresponding service file. Lastly
   * keep in mind that if you use path params with query params and provide a paramSchema,
   * the formatFinalParams utility will throw away properties not defined in your schema.
   */
  makeRequest(_params = {}, payload = {}) {
    const { options } = this;
    let params = _params;
    let cancelToken;
    let data = payload;
    // @ts-expect-error TS(2339): Property 'url' does not exist on type '{ method: s... Remove this comment to see the full error message
    let { url } = options.axios;

    if (this.cancelRequest) {
      // @ts-expect-error TS(2349): This expression is not callable.
      this.cancelRequest('cancelled');
    }

    /* eslint-disable */
    // @ts-expect-error TS(2339): Property 'url' does not exist on type '{ method: s... Remove this comment to see the full error message
    if (!options.axios.url) {
      throw new Error('No url provided to ');
    }
    /* eslint-enable */

    // prepare query params,
    if (!_.isEmpty(options.paramSchema)) {
      params = this.prepQueryString(options.paramSchema, params);
    }

    // setup the cancelable callback to be called if this same request
    // is made again before completing
    if (options.isCancelable) {
      cancelToken = new Axios.CancelToken((cancelFunc) => {
        // @ts-expect-error TS(2322): Type 'Canceler' is not assignable to type 'null'.
        this.cancelRequest = cancelFunc;
      });
    }

    // replace path params & remove values from passed params
    // so that we don't attach them as query params
    // @ts-expect-error TS(2339): Property 'url' does not exist on type '{ method: s... Remove this comment to see the full error message
    if (options.axios.url.indexOf(':') !== -1) {
      const rx = new RegExp(/:[a-z]+/gi);
      const pathParamMatches = url.match(rx) || [];
      const queryParamKeys = _.difference(
        Object.keys(params),
        pathParamMatches.map((dirtyMatch: any) => dirtyMatch.substring(1))
      );

      // update url
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      url = url.replace(rx, (match: any) => _params[match.substring(1)]);
      // remove matches from query params
      params = _.pick(params, queryParamKeys);
    }

    if (!_.isEmpty(data) && _.isFunction(options.payloadMap)) {
      // @ts-expect-error TS(2349): This expression is not callable.
      data = options.payloadMap(data);
    }

    return options.Service({
      cancelToken,
      paramsSerializer: (query: any) => {
        return qs.stringify(query, { encode: true });
      },
      ...options.axios,
      url,
      params,
      data,
    });
  },

  prepQueryString(schema: any, params: any) {
    const duration =
      (params.duration && calculateDuration(Number(params.duration))) || {};
    const filters = prepFilterParams(params);

    return {
      ...formatFinalParams(schema, {
        ...params,
        ...duration,
      }),
      ...filters,
    };
  },
};

function createServiceHandler(options: any) {
  // create a new instance of a service handler returning
  // the makeRequest method
  return Object.create(ServiceHandler, {
    options: {
      value: _.merge({}, ServiceHandler.options, options),
    },
  });
}

export function ServiceHandlerFactory(options = {}) {
  return createServiceHandler(options).init();
}

export function transformParams(callbacks = [], stringifyOptions = {}) {
  return (params: any) => {
    const nextParams = callbacks.reduce((acc, callback) => {
      // @ts-expect-error TS(2349): This expression is not callable.
      return callback(acc);
    }, params);

    return qs.stringify(nextParams, stringifyOptions);
  };
}
