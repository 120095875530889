import _ from 'lodash';

export const trimAndAddAsterisk = (searchStr: string[] | string) => {
  const search = _.isArray(searchStr) ? searchStr.join(' ') : searchStr || '';
  return `*${search.trim()}*`;
};

export const trim = (Str: string[] | string) => {
  const finalStr = _.isArray(Str) ? Str.join(',') : Str || '';
  return `${finalStr.trim()}`;
};
