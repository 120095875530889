import React from 'react';
import { Typography } from '@mui/material';
import PublicComponentWrapper from '../wrappers/PublicComponentWrapper';

const SignUpSubmitted = () => (
  <PublicComponentWrapper>
    <Typography variant="body2" align="center">
      Thank you for signing up!
    </Typography>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Typography variant="subheading" align="center">
      Please check your email and confirm your account.
    </Typography>
  </PublicComponentWrapper>
);

export default SignUpSubmitted;
