import _ from 'lodash';

import {
  PLATFORM_LANGUAGE_UPDATED,
  REHYDRATE_FROM_PERSONA,
  TOGGLE_DYNAMIC_FORMS_ALL_PRODUCTS,
  TOGGLE_FEATURE_FLAGS,
  TOGGLE_SUBMISSIONS_VIEW,
  TOGGLE_UK_ENV,
  USER_PERSONA_DISCOVERED,
} from '../store/actions';
import { GROWTHBOOK_FEATURE_SETTINGS } from '../store/consts/ui-settings.store.consts';
import { getPlatformRegion } from '../utils';

const TOGGLE_THEME = 'TOGGLE_THEME';
const SET_THEME = 'SET_THEME';
const NOTIFICATIONS = 'NOTIFICATIONS';
const CREATION_TEAMS = 'CREATION_TEAMS';
const UPDATE_UI_SETTINGS = 'UPDATE_UI_SETTINGS';
const SET_UI_VERSION = 'SET_UI_VERSION';
const SET_REGION = 'SET_REGION';
const SAVE_TABLE_SETTINGS = 'SAVE_TABLE_SETTINGS';
const SAVE_TABLE_SETTINGS_V2 = 'SAVE_TABLE_SETTINGS_V2';
const CLEAR_FILTERS = 'CLEAR_FILTERS';
const SET_NEW_AGENT_EXPERIENCE_ATTRIBUTES =
  'SET_NEW_AGENT_EXPERIENCE_ATTRIBUTES';

// storeIds under uiSettings

export const AGENCY_POLICIES_LISTING_STORE_ID = 'agencyPolicies';
export const AGENCY_QUOTES_LISTING_STORE_ID = 'agencyInbox';
export const AGENCY_BINDERS_LISTING_STORE_ID = 'agencyBinders';
export const CARRIER_BINDERS_LISTING_STORE_ID = 'carrierBinders';
export const CLUSTER_BINDERS_LISTING_STORE_ID = 'clusterBinders';
export const DA_BINDERS_LISTING_STORE_ID = 'daBinders';
export const AGENCY_COMMISSIONS_LISTING_STORE_ID = 'commissions';
export const ADMIN_INVOICES_STORE_ID = 'invoices';
export const ADMIN_COMMISSIONS_STORE_ID = 'commissionstatements';
export const ADMIN_OUTSTANDING_STORE_ID = 'outstandingbalances';
export const ADMIN_AGENCYBILL_STORE_ID = 'agencyBills';

export const LAYOUT_COMPRESSED = 'compressed';
export const LAYOUT_EXPANDED = 'expanded';

export const uiSettingsDefaults = {
  theme: 'light',
  version: '0.0.1',
  persona: null,
  new_agent_experience: {
    layout: LAYOUT_COMPRESSED,
    modal_seen: false,
  },
  team: {
    accountCreation: {},
  },
  dashboard: {
    duration: 5,
    cbId: null,
  },
  growthBook: {
    [GROWTHBOOK_FEATURE_SETTINGS.FEATURE_FLAGS_ENABLED]: false,
    [GROWTHBOOK_FEATURE_SETTINGS.NEW_AGENT_EXPERIENCE]: false,
    [GROWTHBOOK_FEATURE_SETTINGS.PRIME_ONE_DYNAMIC_FIELDS]: false,
    [GROWTHBOOK_FEATURE_SETTINGS.SUBMISSIONS_VIEW]: false,
  },
  platformLanguage: getPlatformRegion(),
};

export const toggleTheme = () => {
  return {
    type: TOGGLE_THEME,
  };
};

export const setTheme = (themeType: any) => {
  return {
    type: SET_THEME,
    payload: themeType,
  };
};

export const muteNotifications = (muteValue: any) => {
  return {
    type: NOTIFICATIONS,
    payload: muteValue,
  };
};

export const accountCreationTeams = (payload: any) => {
  return {
    type: CREATION_TEAMS,
    payload,
  };
};

export const updateUISettings = ({ domain, update }: any) => {
  return {
    type: UPDATE_UI_SETTINGS,
    payload: { domain, update },
  };
};

export const setUIVersion = (payload: any) => {
  return {
    type: SET_UI_VERSION,
    payload,
  };
};

export const setNewAgentExperienceAttributes = (payload: any) => {
  return {
    type: SET_NEW_AGENT_EXPERIENCE_ATTRIBUTES,
    payload,
  };
};

export const saveTableSettings = (payload: any) => {
  return {
    type: SAVE_TABLE_SETTINGS,
    payload,
  };
};

export const saveTableSettingsV2 = (payload: any) => {
  return {
    type: SAVE_TABLE_SETTINGS_V2,
    payload,
  };
};

export const clearFilters = (payload: any) => ({
  type: CLEAR_FILTERS,
  payload,
});

export const uiSettings = (state = uiSettingsDefaults, action: any) => {
  switch (action.type) {
    case REHYDRATE_FROM_PERSONA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SAVE_TABLE_SETTINGS: {
      const { columns, domain, ...newState } = action.payload;

      if (!_.isEmpty(columns)) {
        return {
          ...state,
          [domain]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state[domain],
            columns: columns.map(({ name, show, width }: any) => ({
              name,
              show,
              width,
            })),
          },
        };
      }

      return {
        ...state,
        [domain]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[domain],
          ...newState,
        },
      };
    }
    case SAVE_TABLE_SETTINGS_V2: {
      const { columns, domain, ...newState } = action.payload;

      if (!_.isEmpty(columns)) {
        return {
          ...state,
          [domain]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state[domain],
            columns,
          },
        };
      }

      return {
        ...state,
        [domain]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[domain],
          ...newState,
        },
      };
    }

    case CLEAR_FILTERS: {
      const { domain } = action.payload;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const { f, ...rest } = state[domain];
      return {
        ...state,
        [domain]: rest,
      };
    }

    case UPDATE_UI_SETTINGS: {
      if (!_.has(state, action.payload.domain)) {
        return state;
      }

      const modifiedState = {
        ...state,
        [action.payload.domain]: {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[action.payload.domain],
          ...action.payload.update,
        },
      };

      return modifiedState;
    }

    case SET_UI_VERSION: {
      return {
        ...state,
        version: { ...action.payload },
      };
    }

    case SET_REGION: {
      return {
        ...state,
        region: action.payload,
      };
    }

    case USER_PERSONA_DISCOVERED: {
      const { persona } = action.payload;
      return { ...state, persona };
    }

    case TOGGLE_THEME:
      return state.theme === 'dark'
        ? { ...state, theme: 'light' }
        : { ...state, theme: 'dark' };

    case SET_THEME:
      return { ...state, theme: action.payload };

    case TOGGLE_FEATURE_FLAGS: {
      const flag =
        action.payload || GROWTHBOOK_FEATURE_SETTINGS.FEATURE_FLAGS_ENABLED;

      return {
        ...state,
        growthBook: {
          ...state.growthBook,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [flag]: !state.growthBook[flag],
        },
      };
    }

    case TOGGLE_UK_ENV: {
      return {
        ...state,
        growthBook: {
          ...state.growthBook,
          [GROWTHBOOK_FEATURE_SETTINGS.UK_ENVIRONMENT_ENABLED]:
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            !state.growthBook[
              GROWTHBOOK_FEATURE_SETTINGS.UK_ENVIRONMENT_ENABLED
            ],
        },
      };
    }

    case TOGGLE_DYNAMIC_FORMS_ALL_PRODUCTS: {
      return {
        ...state,
        growthBook: {
          ...state.growthBook,
          [GROWTHBOOK_FEATURE_SETTINGS.PRIME_ONE_DYNAMIC_FIELDS]:
            !state.growthBook[
              GROWTHBOOK_FEATURE_SETTINGS.PRIME_ONE_DYNAMIC_FIELDS
            ],
        },
      };
    }

    case TOGGLE_SUBMISSIONS_VIEW: {
      return {
        ...state,
        growthBook: {
          ...state.growthBook,
          [GROWTHBOOK_FEATURE_SETTINGS.SUBMISSIONS_VIEW]:
            !state.growthBook[GROWTHBOOK_FEATURE_SETTINGS.SUBMISSIONS_VIEW],
        },
      };
    }

    case PLATFORM_LANGUAGE_UPDATED: {
      return {
        ...state,
        platformLanguage: action.payload,
      };
    }

    case NOTIFICATIONS:
      return { ...state, mute: action.payload };

    case CREATION_TEAMS:
      return {
        ...state,
        team: {
          ...state.team,
          // @ts-expect-error TS(2339): Property 'accountCreation' does not exist on type ... Remove this comment to see the full error message
          accountCreation: { ...state.accountCreation, ...action.payload },
        },
      };

    case SET_NEW_AGENT_EXPERIENCE_ATTRIBUTES:
      return {
        ...state,
        new_agent_experience: {
          ...state.new_agent_experience,
          ...action.payload,
        },
      };

    default:
      if (state) {
        return state;
      }
      return uiSettingsDefaults;
  }
};
export default uiSettings;
