export const formatAsPhone = (string = '') => {
  if (typeof string !== 'string') {
    return string;
  }

  // remove non-digit characters
  const sanitized = string.replace(/[^\d]/g, '');

  if (sanitized.length === 10) {
    return string.replace(/(\d{3})?(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return string;
};

export const truncateString = (str = '', n: any) => {
  return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const titleCase = (string: any) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export function getFileName(string: any) {
  const parts = string.split('.');
  parts.pop();
  return parts.join('.');
}

export function getFileExtension(string: any) {
  const parts = string.split('.');
  return parts.pop();
}
