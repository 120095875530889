import { withStyles } from '@mui/styles';

// import { Redirect } from 'react-router-dom';
// import { hasUrl } from '../../utils/appUtils';

// components
import BrowserUnsupportedWithNoStyles from '../main/common/BrowserUnsupported';
import Footer from '../entry/Footer';
import LogoHeader from '../entry/LogoHeader';

import { BrowserMappings } from '../main/common/BrowserMappings';

function PublicComponentWrapper({ classes, ...props }: any) {
  const browserName = BrowserMappings();

  if (browserName === 'Microsoft Internet Explorer') {
    return <BrowserUnsupportedWithNoStyles />;
  }

  return (
    <main className={`${classes.publicContentWrapper}`}>
      <div className={`${classes.publicContentContainer} ${props.class}`}>
        <LogoHeader />
        <section className={classes.publicSection}>{props.children}</section>
      </div>
      <Footer />
    </main>
  );
}

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;

const backgroundImages = {
  dark: `logos/login_background_dark.${isSafari ? 'jpg' : 'webp'}`,
  light: `logos/login_background_light.${isSafari ? 'jpg' : 'webp'}`,
  serverDown: `logos/404Screen.${isSafari ? 'jpg' : 'webp'}`,
};

const styles = ({ palette }: any) => ({
  publicBackground: {},
  background: {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    backgroundImage: `url(${backgroundImages[palette.mode]})`,
    backgroundSize: 'cover',
  },
  serverDownBackground: {
    backgroundImage: `url(${backgroundImages.serverDown})`,
    backgroundSize: 'cover',
  },
  publicContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',

    '& .public-logo': {
      marginBottom: 40,
      maxWidth: 400,
      width: 250,
    },
  },

  publicContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'auto',
  },

  publicSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '32rem',
    minWidth: 360,
    color: palette.text.title,

    '& a': {
      textDecoration: 'underline',
      color: palette.text.title,
    },
    '& p': {
      marginTop: 5,
      marginBottom: 5,
    },
  },
});

// @ts-expect-error TS(2345): Argument of type '({ palette }: any) => { publicBa... Remove this comment to see the full error message
export default withStyles(styles)(PublicComponentWrapper);
