import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
// material ui
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// helpers
import { accountReasons } from '../../console/_statics/decline.statics';
import { _isOneOf } from '../../utils/data.utils';

// componenets
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { withShowable } from '../../console/_global/lib/withShowable';

// actions
import { declineAccount } from '../../console/agencies/_services/accounts.service';

// constants
import { queries } from '../../queries';

const TextField = withFormController(TextFieldBase);
const ShowGrid = withShowable(MuiGrid);

const styles = ({ palette, config }: any) => {
  return {
    header: {
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
      '& p': {
        margin: 0,
      },
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: '1.5rem 0 0.5rem 1rem',
      fontSize: config.textSizes.normal,
    },
    declineButton: {
      border: 'none',
      backgroundColor: palette.background.red,
    },
  };
};

const schema = Yup.object().shape({
  reasonSummary: Yup.string().ensure().required().label('Declaration Reason'),
  reason: Yup.string().required().label('Reason Description'),
  reasonFillIn: Yup.string().when(['reasonSummary'], {
    is: (reasonSummary) => _isOneOf(reasonSummary, ['Fill-in option']),
    then: Yup.string().ensure().required().label('Fill-In text'),
    otherwise: Yup.string(),
  }),
});

export const DeclineAccountConfirm = withStyles(styles)(
  // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');
    const [showFillIn, setShowFillIn] = useState(false);

    const queryClient = useQueryClient();

    const {
      handleSubmit,
      getValues,
      setValue,
      register,
      formState: { isSubmitting, errors },
      ...methods
    } = useForm({
      defaultValues: {
        reason: '',
        reasonSummary: '',
        reasonFillIn: '',
      },
      resolver: yupResolver(schema),
    });

    const values = getValues();

    const handleReasonType = (event: any) => {
      setValue('reasonSummary', event.target.value);
      if (event.target.value === accountReasons[4].value) {
        setShowFillIn(true);
        values.reasonFillIn = '';
      } else {
        setShowFillIn(false);
        values.reasonFillIn = event.target.value;
      }
    };

    const renderOptions = ({ label, value }: any) => {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    };

    const onSubmit = (formData: any) => {
      const reasonSummary = showFillIn
        ? formData.reasonFillIn
        : formData.reasonSummary;

      const payload = {
        productType: data.product,
        reason: formData.reason,
        reasonSummary,
      };

      return declineAccount({ id: data.id }, payload)
        .then(() => {
          close();
          enqueueSnackbar('Account Declined Successfully!', {
            variant: 'success',
          });

          queryClient.invalidateQueries(queries.accounts.list._def);
        })
        .catch((error: any) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Please try again later'
              )
            )
          );
        });
    };

    return (
      // @ts-expect-error TS(2739): Type '{ children: Element; watch: UseFormWatch<{ r... Remove this comment to see the full error message
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ textAlign: 'left', padding: '2rem 5rem' }}>
            <div className={classes.header}>
              <p>Are you sure you want to decline the account?</p>
              <p>Please provide a reason for the declination below.</p>
            </div>

            <MuiGrid style={{ paddingBottom: '1rem' }}>
              <InputLabelBase required className={classes.label}>
                Reason for Declination
              </InputLabelBase>

              <MuiSelect
                {...register('reasonSummary')}
                variant="standard"
                name="reasonSummary"
                required
                onChange={handleReasonType}
                values={values.reasonSummary}
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                placeholder="please select a reason"
                // @ts-expect-error TS(2322): Type 'false | FieldWithPossiblyUndefined<FieldErro... Remove this comment to see the full error message
                error={
                  !values.reasonSummary &&
                  _.get(errors, 'reasonSummary.message')
                }
              >
                {accountReasons.map(renderOptions)}
              </MuiSelect>
              {!values.reasonSummary && (
                <FormHelperText className="api-text-error">
                  {_.get(errors, 'reasonSummary.message')}
                </FormHelperText>
              )}
            </MuiGrid>

            <ShowGrid show={showFillIn}>
              <TextField name="reasonFillIn" required />
            </ShowGrid>

            <MuiGrid>
              <TextField
                label="Declination Description"
                name="reason"
                multiline
                required
                minRows={4}
                maxRows={4}
              />
            </MuiGrid>
            {error && (
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
            <CbButton
              type="submit"
              styleName="ctaButton"
              buttonText="Decline"
              loading={isSubmitting}
              disabled={isSubmitting}
              customClass={classes.declineButton}
            />
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);

export const DeclineAccountConfirmConfig = {
  DeclineAccountConfirm: {
    component: DeclineAccountConfirm,
    config: {
      fullWidth: false,
      maxWidth: 'md',
      title: 'Decline Account',
    },
  },
};

export default DeclineAccountConfirm;
