import { useToggleModal } from '../../utils/modal.utils';

export function withToggleModal(C: any) {
  const Wrapper = (props: any) => {
    const toggleModal = useToggleModal();

    return <C toggleModal={toggleModal} {...props} />;
  };

  Wrapper.displayName = 'withToggleModal';

  return Wrapper;
}
