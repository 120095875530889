import { useActor } from '../hooks/useActor';

export const withActor = (Component: any) => {
  const Wrapper = (props: any) => {
    const actor = useActor();

    return <Component actor={actor} {...props} />;
  };

  Wrapper.displayName = `withActor${Component.displayName || Component.name}`;

  return Wrapper;
};
