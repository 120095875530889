import React from 'react';

import {
  Popper as MuiPopper,
  Paper as MuiPaper,
  List,
  ListItem,
  styled,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import {
  Cancel,
  Brightness1 as CircleIcon,
  CheckCircle,
} from '@mui/icons-material';

import { getPasswordConstraints } from '../../../../api/auth/auth.api';

export const PasswordPopper = ({
  errors,
  $anchorElem,
  onClose,
  ...props
}: any) => {
  const [constraints, setPasswordConstraints] = React.useState<any>([]);
  const ERROR_MAP = React.useMemo(() => {
    return errors.reduce(
      // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
      (acc, error) => ({
        ...acc,
        [error.code]: error.detail,
      }),
      {}
    );
  }, [errors]);

  React.useEffect(() => {
    getPasswordConstraints()
      .then((resp: any) => {
        setPasswordConstraints(
          resp.data.map((data: any) => ({
            ...data,
            code: data.errorCode,
            detail: data.displayText,
          }))
        );
      })
      .catch(console.error.bind(console));
  }, []);

  return (
    <Popper
      show
      open={$anchorElem}
      anchorEl={$anchorElem}
      placement="right"
      onClick={onClose}
      {...props}
    >
      <Paper elevation={4}>
        <CloseIconButton size="small" onClick={onClose} />

        <List dense style={{ maxHeight: 'unset' }}>
          {constraints.map((constraint: any) => (
            <ListItem key={constraint.code}>
              <ListItemIcon>
                <PasswordIcons
                  isValid={!ERROR_MAP[constraint.code]}
                  initialIcon={props.initial}
                />
              </ListItemIcon>
              {ERROR_MAP[constraint.code] ? (
                <ListItemText primary={ERROR_MAP[constraint.code]} />
              ) : (
                <ListItemText primary={constraint.detail} />
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
    </Popper>
  );
};

const PasswordIcons = ({ isValid, initialIcon = false }: any) => {
  if (initialIcon) {
    return <CircleIcon color="secondary" />;
  }

  if (!isValid) {
    return <CancelIcon />;
  }

  return <CheckCircleIcon />;
};

const Popper = styled(MuiPopper)({
  maxWidth: 500,
  zIndex: 999,

  '& li': {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const Paper = styled(MuiPaper)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.background.paper,

  '&::before': {
    content: '""',
    width: '1.25rem',
    height: '1.35rem',
    position: 'absolute',
    left: '94%',
    top: '50%',
    background: theme.palette.background.paper,
    transform: 'translate(0.825rem, -50%) rotate(45deg)',
    borderRadius: 3,
  },
}));

const CheckCircleIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.common.cowbellGreen2,

  '&:hover': {
    color: theme.palette.common.cowbellGreen2,
    cursor: 'default',
  },
}));

const CancelIcon = styled(Cancel)(({ theme }) => ({
  color: theme.palette.common.tomato,

  '&:hover': {
    color: theme.palette.common.tomato,
    cursor: 'default',
  },
}));

const CloseIconButton = styled<any>((props: any) => (
  <IconButton {...props} size="large">
    <Cancel />
  </IconButton>
))({
  position: 'absolute',
  left: '100%',
  transform: 'translateX(-26px) translateY(2px)',
  zIndex: 1,
});
