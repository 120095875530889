import React from 'react';
import { SvgIcon } from '@mui/material';

export default function YellowWarningIcon(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        id="prefix__Path_2068"
        fill="none"
        d="M0 0h15v15H0z"
        data-name="Path 2068"
      />
      <path
        id="prefix__Path_2069"
        fill="#fbc000"
        d="M6.95 4.75h1.1v1.1h-1.1zm0 2.2h1.1v3.3h-1.1zM7.5 2A5.5 5.5 0 1 0 13 7.5 5.5 5.5 0 0 0 7.5 2zm0 9.9a4.4 4.4 0 1 1 4.4-4.4 4.406 4.406 0 0 1-4.4 4.4z"
        data-name="Path 2069"
      />
    </SvgIcon>
  );
}
