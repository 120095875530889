import _ from 'lodash';
import React from 'react';

import { TablePagination as MuiTablePagination } from '@mui/material';

export const TablePagination = React.memo(
  ({
    // @ts-expect-error TS(2339): Property 'onPageChange' does not exist on type '{}... Remove this comment to see the full error message
    onPageChange,
    // @ts-expect-error TS(2339): Property 'changePageSize' does not exist on type '... Remove this comment to see the full error message
    changePageSize,
    // @ts-expect-error TS(2339): Property 'page' does not exist on type '{}'.
    page,
    // @ts-expect-error TS(2339): Property 'rowsPerPage' does not exist on type '{}'... Remove this comment to see the full error message
    rowsPerPage,
    // @ts-expect-error TS(2339): Property 'rowsPerPageOptions' does not exist on ty... Remove this comment to see the full error message
    rowsPerPageOptions,
    // @ts-expect-error TS(2339): Property 'totalRows' does not exist on type '{}'.
    totalRows,
  }) => {
    return (
      <MuiTablePagination
        count={totalRows}
        rowsPerPage={Number(rowsPerPage)}
        page={Number(page)}
        onPageChange={onPageChange}
        onRowsPerPageChange={changePageSize}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    );
  },
  (prev, next) => {
    const pickKeys = ['page', 'size'];
    // @ts-expect-error TS(2339): Property 'q' does not exist on type 'object'.
    const _prev = _.pick(prev.q, pickKeys);
    // @ts-expect-error TS(2339): Property 'q' does not exist on type 'object'.
    const _next = _.pick(next.q, pickKeys);

    if (_.isEqual(_prev, _next)) {
      return false;
    }

    // @ts-expect-error TS(2339): Property 'totalRows' does not exist on type 'objec... Remove this comment to see the full error message
    if (_.eq(prev.totalRows, next.totalRows)) {
      return false;
    }

    // will not update
    return true;
  }
);

TablePagination.displayName = 'TablePagination';

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'Na... Remove this comment to see the full error message
TablePagination.defaultProps = {
  page: 0,
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  totalRows: 0,
  onPageChange: () => null,
  handleChangeRowsPerPage: () => null,
};
