import _ from 'lodash';
import React, { useCallback, useState } from 'react';

import { Box, Card, Grid, useTheme } from '@mui/material';
import { Checkbox } from '../../../components/inputs';
import CbButton from '../../../components/Buttons/CbButton';

import { certifyAccount } from '../../../accounts/AccountService';
import { toggleModalDiffered } from '../../../utils/storeUtils';
import { _isOneOf } from '../../../utils/data.utils';

import {
  INDICATION_STATUS,
  READY_STATUS,
} from '../../../console/_statics/quote.statics';
import { PubSub } from '../../../utils/eventUtils';
import { getAgentAttestLabelText } from '../../../accounts/accountUtils';
import { toUniversalDate, toUniversalUtcDate } from '../../../utils/date.utils';

export const AttestRequest = ({ parentClasses, account, quote }: any) => {
  const { palette } = useTheme();
  const [isVerifiedByAgent, setIsVerifiedByAgent] = useState(
    account.isVerifiedByAgent
  );
  const [verifiedDate, setVerifiedDate] = useState(() =>
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    toUniversalUtcDate(account.verifiedDate, { placeholder: null })
  );

  const handleAttestment = useCallback(() => {
    if (isVerifiedByAgent) {
      return null;
    }

    const payload = _.pick(account, [
      'name',
      'naicsCode',
      'revenue',
      'noOfEmployeesAll',
      'yearEstablished',
      'ownershipType',
      'isFranchise',
      'domainName',
      'domains',
      'hostname',
      'phoneNumber',
      'address1',
      'state',
      'city',
      'zipCode',
      'incidentResponsePlan',
    ]);

    certifyAccount({ accountId: account.id, payload }).then(({ data }) => {
      if (!data.error) {
        setIsVerifiedByAgent(true);
        setVerifiedDate(toUniversalDate(new Date()));
      }
    });
  }, [account, isVerifiedByAgent]);

  if (!_isOneOf(quote.agencyStatus, [INDICATION_STATUS, READY_STATUS])) {
    return null;
  }

  return (
    <Card className={parentClasses.card}>
      <Grid container>
        <Grid item sm={8}>
          <Checkbox
            checked={isVerifiedByAgent}
            onChange={handleAttestment}
            disabled={isVerifiedByAgent}
            // @ts-expect-error TS(2322): Type 'Element' is not assignable to type 'string'.
            label={getAgentAttestLabelText(isVerifiedByAgent, verifiedDate)}
          />
        </Grid>
        <Grid className="text-right" item sm={4}>
          <CbButton
            styleName="ctaButton"
            size="medium"
            disabled={!isVerifiedByAgent}
            onClick={toggleModalDiffered(
              'RequestToBind',
              {
                agencyType: 'Wholesale',
                ..._.pick(quote, [
                  'id',
                  'agencyName',
                  'companyName',
                  'quoteNumber',
                  'totalPremium',
                ]),
                customerFirstName: account.policyContactFirstName,
                customerLastName: account.policyContactLastName,
                customerEmail: account.policyContactEmail,
                customerPhone: account.policyContactPhone,
                onSuccess: toggleModalDiffered(
                  'StatementDialog',
                  {
                    content: (
                      <Box
                        className="primer-text"
                        textAlign="center"
                        maxWidth="50%"
                        margin="0 auto"
                        color={palette.primary.contrastText}
                      >
                        Your request to bind quote has been sent to{' '}
                        {quote.agencyName}
                      </Box>
                    ),
                    onClose: () => {
                      setTimeout(() => {
                        // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
                        PubSub.publish('quote:refresh');
                      }, 1000);
                    },
                  },
                  {
                    title: 'Request Sent',
                  }
                ),
              },
              {
                title: `Request to Bind Quote for ${account.name}`,
                icon: 'Send',
              }
            )}
          >
            Request to bind
          </CbButton>
        </Grid>
      </Grid>
    </Card>
  );
};
