import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// helpers
import * as Yup from 'yup';
import { lostQuote } from '../../console/agencies/_services/quotes.service';
import { delayedEvent } from '../../utils/eventUtils';
// componenets
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { InputLabelBase } from '../inputs/InputLabelBase';
import { _isOneOf } from '../../utils/data.utils';
// actions

const TextField = withFormController(TextFieldBase);

const reasons = [
  { label: 'Price is High', value: 'Price is High' },
  {
    label: 'Coverage / Limit not enough',
    value: 'Coverage / Limit not enough',
  },
  { label: 'Do not want Cyber', value: 'Do not want Cyber' },
  {
    label: 'Additional - Paper rating, flexibility ',
    value: 'Additional - Paper rating, flexibility ',
  },
  { label: 'Fill-in option', value: 'Fill-in option' },
];

const styles = ({ palette, config }: any) => {
  return {
    header: {
      marginBottom: '1.25rem',
      fontSize: config.textSizes.primer,
      color: palette.primary.contrastText,
    },
    divider: {
      marginBottom: '1.25rem',
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      padding: 0,
      fontSize: config.textSizes.normal,
    },
    requiredIcon: {
      color: '#f95f53',
      fontSize: '1.25rem',
      lineHeight: '1rem',
    },
  };
};

const schema = Yup.object().shape({
  reasonSummary: Yup.string().ensure().required('Loss Reason required'),
  reason: Yup.string().required('Reason Description is required'),
  reasonFillIn: Yup.string().when(['reasonSummary'], {
    is: (reasonSummary) => _isOneOf(reasonSummary, ['Fill-in option']),
    then: Yup.string().ensure().required('Fill-In text is required'),
    otherwise: Yup.string(),
  }),
});

export const LostQuoteConfirm = withStyles(styles)(
  // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');

    const [showFillIn, setShowFillIn] = useState(false);

    const {
      handleSubmit,
      getValues,
      setValue,
      register,
      watch,
      formState: { isSubmitting, errors },
      ...methods
    } = useForm({
      defaultValues: {
        reason: '',
        reasonSummary: '',
        reasonFillIn: '',
      },
      resolver: yupResolver(schema),
    });

    const values = getValues();

    const handleReasonType = (event: any) => {
      setValue('reasonSummary', event.target.value);
      if (event.target.value === reasons[4].value) {
        setShowFillIn(true);
        values.reasonFillIn = '';
      } else {
        values.reasonFillIn = event.target.value;
      }
    };

    const renderOptions = ({ label, value }: any) => {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    };

    const onSubmit = (formData: any) => {
      const reasonSummary = showFillIn
        ? formData.reasonFillIn
        : formData.reasonSummary;
      schema.validate({ reason: formData.reason, reasonSummary });

      const payload = {
        productType: data.product,
        reason: formData.reason,
        reasonSummary,
      };

      return lostQuote({ id: data.id }, payload)
        .then(() => {
          close();
          enqueueSnackbar('Quote Lost Reason Saved Successfully!', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'adminQuotes');
        })
        .catch((error: any) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong. Please try again later'
              )
            )
          );
        });
    };

    return (
      // @ts-expect-error TS(2740): Type '{ children: Element; getFieldState: UseFormG... Remove this comment to see the full error message
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            className="modal-title"
            style={{ textAlign: 'left', marginTop: '-1rem' }}
          >
            <MuiGrid>
              <MuiGrid
                container
                spacing={2}
                justifyContent="center"
                style={{ paddingBottom: 10 }}
              >
                <MuiGrid item md={12}>
                  <InputLabelBase required>Reason for Loss</InputLabelBase>
                </MuiGrid>
                <MuiGrid item md={12} style={{ marginTop: -20 }}>
                  <MuiSelect
                    variant="standard"
                    // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                    name="reasonTypes"
                    required
                    {...register('reasonSummary')}
                    onChange={handleReasonType}
                    values={values.reason}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                    placeholder="please select a reason"
                    // @ts-expect-error TS(2322): Type 'false | FieldWithPossiblyUndefined<FieldErro... Remove this comment to see the full error message
                    error={
                      !values.reasonSummary &&
                      _.get(errors, 'reasonSummary.message')
                    }
                  >
                    {reasons.map(renderOptions)}
                  </MuiSelect>
                  {!values.reasonSummary && (
                    <FormHelperText>
                      {_.get(errors, 'reasonSummary.message')}
                    </FormHelperText>
                  )}
                </MuiGrid>
              </MuiGrid>
              {showFillIn && (
                <MuiGrid container spacing={2} justifyContent="center">
                  <MuiGrid item md={12}>
                    <TextField type="text" name="reasonFillIn" required />
                  </MuiGrid>
                </MuiGrid>
              )}
              <MuiGrid container spacing={2} justifyContent="center">
                <MuiGrid item md={12}>
                  Loss Description
                  <i className={classes.requiredIcon}>*</i>
                </MuiGrid>
                <MuiGrid item md={12} style={{ marginTop: -20 }}>
                  <TextField
                    type="text"
                    name="reason"
                    multiline
                    required
                    minRows={4}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
            {error && (
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
            <CbButton
              type="submit"
              styleName="ctaButton"
              buttonText="Lost"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);

export default LostQuoteConfirm;
