import React, { useContext } from 'react';
import { compose } from 'redux';

import { TableBody as MuiTableBody } from '@mui/material';
import { withStyles } from '@mui/styles';

import { TableContext } from './context.table';
import { TableRow } from './row.table';
import { withLoader } from '../hocs/withLoader';

const styles = {};

export const TableBody = compose(
  withStyles(styles),
  withLoader
)(({ render, classes, ...props }: any) => {
  // @ts-expect-error TS(2339): Property 'data' does not exist on type '{ onSort: ... Remove this comment to see the full error message
  const { data } = useContext(TableContext);

  if (typeof render === 'function') {
    return (
      <MuiTableBody className={classes.root}>
        {render(data, props)}
      </MuiTableBody>
    );
  }

  return (
    <MuiTableBody className={classes.root}>
      {data.map((row: any, idx: any) => (
        <TableRow key={idx} row={row} />
      ))}
    </MuiTableBody>
  );
});

// @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'JSX... Remove this comment to see the full error message
TableBody.displayName = 'TableBody';
