import { ServiceHandlerFactory } from '../../utils/serviceUtils';

export const searchConnectorsByType = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/search?searchForConnected=:searchForConnected&connectorType=:connectorType`,
  },
  isCancelable: false,
});

export const getLegacyConnectors = ServiceHandlerFactory({
  axios: {
    url: `api/connector/v1/connectors/all`,
  },
  isCancelable: false,
});

export const getConnections = ServiceHandlerFactory({
  axios: {
    url: `api/connector/v1/connections`,
  },
  isCancelable: false,
});

export const getConnectorScore = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/:connectorType/getLatestScore/:connectorId`,
  },
  isCancelable: true,
});

export const getActionList = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/:connectorType/getActionList/:connectorId',
  },
  isCancelable: true,
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }: any) => {
        switch (orderBy) {
          case 'score':
          case 'maxScore':
          case 'isCompleted':
            return `${orderBy},${order}`;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});

export const getConnectorStatus = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/connections/:connectorId/status`,
  },
  isCancelable: false,
});

/**
 * New location src/apis/connectors/connectors.api.ts
 * @deprecated
 */
export const deleteConnectorById = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/:connectorId',
    method: 'delete',
  },
});

export const getConnectorScoreHistory = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/:connectorType/getScoresHistory/:connectorId?days=:days`,
  },
  isCancelable: true,
});

export const getShortlistActions = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/:connectorType/getShortlistActions/:connectorId`,
  },
  isCancelable: true,
});

export const getConnector = ServiceHandlerFactory({
  axios: {
    url: `/api/connector/v1/connections?connectorType=:connectorType`,
  },
  isCancelable: true,
});

export const securityStudioConnect = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/connections',
    method: 'post',
  },
});

export const wizerConnect = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/wizer/add?cbid=:cbid',
    method: 'post',
  },
});

export const qualysConnect = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/connections/',
    method: 'post',
  },
});

export const getConnectedConnectors = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/account/:accountId',
  },
  isCancelable: true,
});

export const getAWSregions = ServiceHandlerFactory({
  axios: {
    url: '/api/connector/v1/aws/regions',
  },
  isCancelable: true,
});

export const connectAWS = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/connections',
    method: 'post',
  },
  // paramSchema: [{ path: 'cbid' }],
  isCancelable: true,
});

export const leenOrgCreate = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/leen/organization',
    method: 'post',
  },
});

export const leenTokenCreate = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/leen/invite-token',
    method: 'post',
  },
});

export const storeLeenConnectionResponse = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/connections',
    method: 'post',
  },
});

/**
 * Retrieves the list of connectors from the Leen API.
 *
 * @returns {Promise} A promise that resolves to the list of connections.
 */
export const getConnectionsList = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/leen/connectors',
    method: 'get',
  },
});

export const deleteConnection = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/connections/:id',
    method: 'delete',
  },
});

export const getOAuthMetadata = ServiceHandlerFactory({
  axios: {
    url: 'api/connector/v1/connectors/:connectorType/oauth/metadata',
    method: 'get',
  },
});
