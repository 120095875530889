import { isEmpty, pickBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
// components
import Modals, { Modal } from '.';
import { getModals } from '../../reducers/UxReducers';
import { useToggleModal } from '../../utils/modal.utils';

// selectors

const ModalContainer = (props: any) => {
  const toggleModal = useToggleModal();

  const _modal = pickBy(props.modals, (val) => val === true);
  const _modalKey = Object.keys(_modal)[0];
  const sticky =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (_modalKey && Modals[_modalKey] && Modals[_modalKey].sticky) ||
    Boolean(false);
  const noCloseIcon =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (_modalKey && Modals[_modalKey] && Modals[_modalKey].noCloseIcon) || '';
  return (
    <Modal
      open={!isEmpty(_modal)}
      onClose={sticky ? null : toggleModal.deferred(_modalKey, false)}
      {...props.modals.modalConfig}
      sticky={sticky}
      modalkey={_modalKey}
      noCloseIcon={noCloseIcon}
    >
      {!_modalKey
        ? null
        : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          React.createElement(Modals[_modalKey].component, {
            data: props.modals.modalContent,
            close: toggleModal.deferred(_modalKey, false),
          })}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modals: getModals(state),
});

export default connect(mapStateToProps)(ModalContainer);
