import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CheckBoxFilledIcon(props: any) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g transform="translate(13318 15725)">
        <path
          d="M0,0H24V24H0Z"
          transform="translate(-13318 -15725)"
          fill="none"
        />
        <path
          d="M0,0V18H18V0Z"
          transform="translate(-13315.5 -15722)"
          fill="#2180e2"
        />
      </g>
    </SvgIcon>
  );
}
