import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FormHelperText, DialogContent } from '@mui/material';
import { deleteAgencyTeam } from '../../console/agencies/_services/teams.service';
// utils
import { PubSub, delayedEvent } from '../../utils/eventUtils';

export const TeamDeleteConfirm = ({ data: { id, name }, close }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  useEffect(() => {
    const sub = PubSub.subscribe('foot-button-one', (eventData: any) => {
      if (eventData) {
        onConfirm();
      }
    });
    return () => {
      sub.remove();
    };
    // eslint-disable-next-line
  }, []);

  const onConfirm = () => {
    PubSub.publish('loading', true);
    PubSub.publish('disabled', true);
    deleteAgencyTeam({ id })
      .then(() => {
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
        enqueueSnackbar(`Team ${name} Removed Successfully!`, {
          variant: 'success',
        });
        delayedEvent('table-refetch', 1000, 'agency_teams');
        close();
      })
      .catch((error: any) => {
        setError(_.get(error, 'response.data'));
        PubSub.publish('loading', false);
        PubSub.publish('disabled', false);
      });
  };

  return (
    <DialogContent>
      <div className="modal-title">
        Are you sure you want to remove the following team
        <span className="modal-blue-text"> {name}</span>?
      </div>
      <FormHelperText className="api-text-error">{error}</FormHelperText>
    </DialogContent>
  );
};
