import qs from 'qs';

// lodash
import _ from 'lodash';

import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { responseStringTransformer } from '../utils';
import { moneyFormat } from '../utils/appUtils';

function serializeCarrierPrefix(params: any) {
  const carrierPrefix =
    typeof params?.carrierPrefix === 'string'
      ? params.carrierPrefix.split('-')[0]
      : undefined;

  const nextParams = { ...params, carrierPrefix };
  return qs.stringify(nextParams, {});
}

function formatPrefix(data: any) {
  const nextPrefix =
    typeof data.carrierPrefix === 'string'
      ? data.carrierPrefix.split('-')[0]
      : undefined;

  return {
    ...data,
    carrierPrefix: nextPrefix,
  };
}

export const getP250Preset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/:naicsCode/:preset',
    method: 'POST',
  },
});

export const getPresetData = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/rules/:naicsCode?limit=:limit',
    method: 'POST',
  },
});

export const getP250Rules = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/rules/:naicsCode',
    method: 'POST',
  },
});

export const get250PresetRules = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/rules/:naicsCode/:preset',
    method: 'POST',
  },
});

export const update250Preset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets',
    isCancelable: true,
    method: 'PUT',
  },
});

export const delete250preset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/:id',
    isCancelable: true,
    method: 'delete',
  },
});

export const Add250Preset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets',
    isCancelable: true,
    method: 'POST',
  },
});

export const getTechENORate = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/rules/techeno-rate?effectiveDate=:effectiveDate&state=:state&techENOPercentage=:techENOPercentage&revenue=:revenue&isSurplus=:isSurplus',
  },
  paramSchema: serializeCarrierPrefix,
});

export const calculate250Premium = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/calculator',
    method: 'POST',
    transformRequest: [
      omitRaterSecurityQuestions,
      boolToString,
      (data: any) => ({
        ...data,
        aggregateLimit: data.aggregateLimit || data.limit,
      }),
      formatPrefix,
      JSON.stringify,
    ],
  },
});

function omitRaterSecurityQuestions({ securityQuestions, ...data }: any) {
  return {
    ...data,
    securityQuestions: _.omit(securityQuestions, [
      'incidentResponsePlan',
      'changeInScope',
      'changeInScopeDetail',
      'backupFrequencyEncrypted',
      'backupFrequencySeparate',
      'backupFrequencyOther',
      'backupFrequencyTested',
    ]),
  };
}

function boolToString({ securityQuestions, ...data }: any) {
  return {
    ...data,
    securityQuestions: Object.keys(securityQuestions).reduce(
      (acc, key) =>
        typeof securityQuestions[key] === 'boolean'
          ? {
              ...acc,
              [key]: securityQuestions[key] ? 'YES' : 'NO',
            }
          : {
              ...acc,
              [key]: securityQuestions[key],
            },
      {}
    ),
  };
}

export const getP250EndorsementRules = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/rules/endorsement?effectiveDate=:effectiveDate&state=:state&surplusAvailable=:surplusAvailable&isSurplus=:isSurplus&createdDate=:createdDate&limit=:limit',
    method: 'GET',
    paramsSerializer: serializeCarrierPrefix,
  },
});

export const create250Quote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/:accountId',
    method: 'POST',
  },
  isCancelable: true,
});

export const update250Quote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/:quoteId',
    method: 'PUT',
  },
  isCancelable: true,
});

export const update250UwQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/uw',
    method: 'PUT',
    transformRequest: [formatPrefix, JSON.stringify],
  },
  isCancelable: true,
});

export const bind250Quote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/uw/bind',
    method: 'PUT',
  },
  isCancelable: true,
});

export const release250Quote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/uw/release',
    method: 'PUT',
    transformRequest: [formatPrefix, JSON.stringify],
  },
  isCancelable: true,
});

export const decline250Quote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/uw/decline',
    method: 'PUT',
  },
  isCancelable: true,
});

export const refreshRiskData = ServiceHandlerFactory({
  axios: {
    url: '/api/risk/v1/refresh/:accountId',
    method: 'PUT',
  },
  isCancelable: true,
});

export const getQuoteDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/details/:quoteId',
  },
});

export const createTempQuote = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/cart',
    method: 'POST',
    transformRequest: [
      (data: any) => {
        if (data.account) {
          const {
            id: accountId,
            state,
            revenue,
            naicsCode: primaryNaics,
          } = data.account;
          return { ...data, accountId, state, revenue, primaryNaics };
        }

        return data;
      },
      JSON.stringify,
    ],
  },
});

export const getTempQuotesByAccount = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/cart/account/:accountId?agencyId=:agencyId',
  },
});

/**
 * New location {@link src/api/submissions/submissions.api.ts}
 * @deprecated
 */
export const create250Quotes = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/bulk',
    method: 'POST',
  },
});

export const getSubjectivities = ServiceHandlerFactory({
  axios: {
    url: '/api/quote/v1/P250/uw/sub?type=:type',
  },
});

export const createIRPMNote = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/irpm?isSurplus=:isSurplus',
    method: 'POST',
  },
});

// presets

/**
 * Get details for a custom preset
 *
 * @path type CUSTOM, TEAM, AGENCY
 * @query revenue {number}
 * @query team {string}
 */
export const get250CustomPreset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/:naicsCode/:type',
    method: 'POST',
  },
});

/**
 * @deprecated use getAvailableCustomPresets in rater.api.ts
 * @name getAvailableCustomPresets
 * @description Get all available custom presets across user, agency, and team if team id is provided
 * @param {string} teamId The team id of the current user
 * @returns {Promise} Promise object
 * @example getAvailableCustomPresets({ teamId }).then((resp) => resp.data);
 */
export const getAvailableCustomPresets = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/custom?agencyId=:agencyId',
  },
});

/**
 * @name getAvailableCustomPreset
 * @description Get available custom preset based on email, agency, or account
 * @param {string} type email, team, agency
 * @param {string} id  email, teamId, agencyId
 */
export const getAvailableCustomPreset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/custom/id/:id',
  },
});

/**
 * @name getRaterData
 * @description get the rater data, which are the versions and revisions to display as dropdowns
 * on the UW review page
 */
export const getRaterData = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/raterdata',
    paramsSerializer: (params: any) => qs.stringify(params, { encode: false }),
  },
});

export const create250CustomPreset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/custom',
    method: 'POST',
  },
});

export const update250CustomPreset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/custom/id/:id',
    method: 'PUT',
  },
});

export const delete250CustomPreset = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/presets/custom/id/:id ',
    method: 'DELETE',
  },
});

export const updateNotice = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/account/tambds/:accountId',
    method: 'put',
  },
});

export const getGlobalOptions = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/rules/globals/:state',
    method: 'POST',
    transformResponse: [
      responseStringTransformer,
      (data: any) => ({
        limits: data.aggLimits.map(formatAsCurrency),
        deductibles: data.deductibles.map(formatAsCurrency),

        waitingPeriods: data.hrs.map((value: any) => ({
          label: `${value} hours`,
          value,
        })),
      }),
    ],
  },
});

function formatAsCurrency(value: any) {
  return {
    label: moneyFormat(value),
    value,
  };
}
