import React, { cloneElement } from 'react';
import { Card as MuiCard, Table as MuiTable } from '@mui/material';
import { withStyles } from '@mui/styles';

import { ActionBar as DefaultActionBar } from './action-bar.table';
import { TableBody as DefaultTableBody } from './body.table';
import { TableFooter as DefaultTableFooter } from './footer.table';
import { TableHeader as DefaultTableHeader } from './header.table';
import { TableSkeleton } from './skeleton.table';
import { TableContext } from './context.table';

const styles = () => ({
  container: {
    position: 'relative',
    overflowX: 'auto',
  },
  containerBlank: {
    position: 'relative',
    background: 'inherit',
  },
});

// @ts-expect-error TS(2345): Argument of type '() => { container: { position: s... Remove this comment to see the full error message
export const BaseTable = withStyles(styles)(
  ({
    // @ts-expect-error TS(2339): Property 'cardProps' does not exist on type 'Consi... Remove this comment to see the full error message
    cardProps,
    // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
    classes,
    // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Consis... Remove this comment to see the full error message
    children,
    // @ts-expect-error TS(2339): Property 'context' does not exist on type 'Consist... Remove this comment to see the full error message
    context,
    // @ts-expect-error TS(2339): Property 'fixed' does not exist on type 'Consisten... Remove this comment to see the full error message
    fixed = false,
    // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Consist... Remove this comment to see the full error message
    loading = true,
    // @ts-expect-error TS(2339): Property 'rowHover' does not exist on type 'Consis... Remove this comment to see the full error message
    rowHover,
    ...props
  }) => {
    const {
      // @ts-expect-error TS(2339): Property 'ActionBar' does not exist on type '{}'.
      ActionBar,
      // @ts-expect-error TS(2339): Property 'TableHeader' does not exist on type '{}'... Remove this comment to see the full error message
      TableHeader,
      // @ts-expect-error TS(2339): Property 'TableBody' does not exist on type '{}'.
      TableBody,
      // @ts-expect-error TS(2339): Property 'TableFooter' does not exist on type '{}'... Remove this comment to see the full error message
      TableFooter,
      // @ts-expect-error TS(2339): Property 'QuickView' does not exist on type '{}'.
      QuickView,
      ...childOverrides
    } = createChildHash(children);

    if (loading) {
      return (
        <TableSkeleton
          children={children}
          classes={classes}
          // @ts-expect-error TS(2339): Property 'columns' does not exist on type '{}'.
          columns={props.columns}
          context={context}
          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
          data={props.data}
          loading={loading}
          fixed={fixed}
          rowHover={rowHover}
          {...props}
        />
      );
    }

    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
      data,
      // @ts-expect-error TS(2339): Property 'history' does not exist on type '{}'.
      history,
      // @ts-expect-error TS(2339): Property 'location' does not exist on type '{}'.
      location,
      // @ts-expect-error TS(2339): Property 'match' does not exist on type '{}'.
      match,
      // @ts-expect-error TS(2339): Property 'columns' does not exist on type '{}'.
      columns,
      // @ts-expect-error TS(2339): Property 'q' does not exist on type '{}'.
      q,
      // @ts-expect-error TS(2339): Property 'rqps' does not exist on type '{}'.
      rqps,
      // @ts-expect-error TS(2339): Property 'rowsperpage' does not exist on type '{}'... Remove this comment to see the full error message
      rowsperpage,
      // @ts-expect-error TS(2339): Property 'rowsperpageoptions' does not exist on ty... Remove this comment to see the full error message
      rowsperpageoptions,
      // @ts-expect-error TS(2339): Property 'count' does not exist on type '{}'.
      count,
      ...htmlAttributes
    } = props;

    return (
      <TableContext.Provider
        value={{
          // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
          id: props.id,
          // @ts-expect-error TS(2339): Property 'columns' does not exist on type '{}'.
          columns: props.columns,
          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{}'.
          data: props.data,
          rowHover,
          childOverrides,
          loading,
          fixed,
          ...context,
        }}
      >
        <>
          {ActionBar ? (
            cloneElement(ActionBar, { ...props, ctx: context })
          ) : (
            <DefaultActionBar />
          )}
          <MuiCard
            className={
              ActionBar && ActionBar.props.classes
                ? classes.containerBlank
                : `custom-scrollbar ${classes.container}`
            }
            {...cardProps}
          >
            <MuiTable
              {...htmlAttributes}
              style={{ tableLayout: fixed && 'fixed' }}
              aria-label="sticky table"
            >
              {TableHeader ? (
                cloneElement(TableHeader, props)
              ) : (
                <DefaultTableHeader />
              )}
              {TableBody ? (
                cloneElement(TableBody, { childOverrides, loading, ...props })
              ) : (
                <DefaultTableBody loading={loading} />
              )}
            </MuiTable>
            {TableFooter ? (
              cloneElement(TableFooter, { childOverrides, ...props })
            ) : (
              <DefaultTableFooter />
            )}
          </MuiCard>
          {QuickView && cloneElement(QuickView, props)}
        </>
      </TableContext.Provider>
    );
  }
);

function createChildHash(children: any) {
  return React.Children.toArray(children).reduce((acc, child) => {
    return {
      ...acc,
      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'string | n... Remove this comment to see the full error message
      [child.type.displayName || child.type.name]: child,
    };
  }, {});
}

// @ts-expect-error TS(2339): Property 'defaultProps' does not exist on type 'JS... Remove this comment to see the full error message
BaseTable.defaultProps = {
  columns: [],
  data: [],
  loading: false,
  rowHover: false,
};
