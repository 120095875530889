import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// helpers
import _ from 'lodash';
import Moment from 'moment';
import {
  DialogContent,
  DialogActions,
  FormHelperText,
  Divider,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// platform helpers
import { delayedEvent } from '../../utils/eventUtils';
// components
import CbButton from '../Buttons/CbButton';
import { TextFieldBase } from '../inputs/TextFieldBase';
import { withFormController } from '../hocs/forms';
import { InputLabelBase } from '../inputs/InputLabelBase';
// actions
import { updateTransaction } from '../../console/customers/_services/BillingService';
import { toUniversalUtcDate } from '../../utils/date.utils';
import LanguageCurrencyFieldBase from '../inputs/LanguageCurrencyField';

const TextField = withFormController(TextFieldBase);
const LanguageCurrencyField = withFormController(LanguageCurrencyFieldBase);

const validationSchema = Yup.object().shape({
  transactionId: Yup.string().required().label('Transaction ID'),
  invoiceNumber: Yup.string().required().label('Invoice Number'),
  status: Yup.string().required().label('Status'),
  paymentDate: Yup.string().required().label('Payment Date'),
  paymentMethod: Yup.string().required().label('Payment Method'),
  processorName: Yup.string().required().label('Processor Name'),
  amountPaid: Yup.number()
    .label('Amount Paid')
    .fromCurrency()
    .min(0)
    .max(
      1000000000000,
      'Amount Paid must be less than or equal to 1,000,000,000,000'
    )
    .typeError('Amount Paid is a required field')
    .required(),
});

const styles = ({ config }: any) => {
  return {
    header: {
      textAlign: 'center',
      marginBottom: '1.25rem',
    },
    divider: {
      marginBottom: '1.25rem',
    },
    label: {
      padding: '0 0 0.25rem 0.83rem',
      fontSize: config.textSizes.normal,
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
  };
};

const paymentOptions = [
  { label: 'Check', value: 'Check' },
  { label: 'Credit Card', value: 'Credit Card' },
  { label: 'ACH/Wire Transfer', value: 'Wire' },
  { label: 'Bank Account', value: 'Bank Account' },
  { label: 'Refund/Credit', value: 'Refund/Credit' },
];

const statusTypes = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Failed', value: 'Failed' },
  { label: 'In process', value: 'In Process' },
  { label: 'Void', value: 'Void' },
];

// @ts-expect-error TS(2345): Argument of type '({ config }: any) => { header: {... Remove this comment to see the full error message
export const UpdateTransaction = withStyles(styles)(
  // @ts-expect-error TS(2339): Property 'classes' does not exist on type 'Consist... Remove this comment to see the full error message
  ({ classes, close, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState('');

    const { handleSubmit, ...methods } = useForm({
      defaultValues: {
        transactionId: data.transactionId,
        invoiceNumber: data.invoiceNumber,
        status: data.status,
        paymentDate: toUniversalUtcDate(data.paymentDate),
        paymentMethod: data.paymentMethod,
        processorName: data.processorName,
        amountPaid: data.amountPaid.toFixed(2),
        paymentDesc: data.paymentDesc,
      },
      resolver: yupResolver(validationSchema),
    });

    const {
      control,
      setValue,
      getValues,
      register,
      watch,
      formState: { isSubmitting },
    } = methods;

    watch([
      'transactionId',
      'invoiceNumber',
      'status',
      'paymentDate',
      'paymentMethod',
      'processorName',
      'amountPaid',
      'paymentDesc',
    ]);

    const handleSelect = (event: any) => {
      setValue(event.target.name, event.target.value);
    };

    const renderOptions = ({ label, value }: any) => {
      return (
        <MuiMenuItem className={classes.menuItem} key={label} value={value}>
          {label}
        </MuiMenuItem>
      );
    };

    const onSubmit = (formData: any) => {
      const payload = {
        ...formData,
        id: data.id,
        paymentDate: Moment(formData.paymentDate).format('YYYY-MM-DD'),
        amountPaid: formData.amountPaid,
      };

      return updateTransaction({}, payload)
        .then(() => {
          close();
          enqueueSnackbar('Transaction Updated Successfully.', {
            variant: 'success',
          });
          delayedEvent('table-refetch', 500, 'transactions');
        })
        .catch((error: any) => {
          setError(
            _.get(
              error.response,
              'data.message',
              _.get(
                error.response,
                'data',
                'Something went wrong.Try again later.'
              )
            )
          );
        });
    };
    const values = getValues();

    return (
      <FormProvider {...methods} handleSubmit={handleSubmit}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            className="modal-title"
            style={{ textAlign: 'left', margin: '-2rem 0' }}
          >
            <MuiGrid>
              <MuiGrid container spacing={3}>
                <MuiGrid item md={4}>
                  <TextField
                    name="transactionId"
                    required
                    label="Transaction Id"
                    // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                    controllerProps={{ control }}
                    fullWidth
                  />
                </MuiGrid>
                <MuiGrid item md={4}>
                  <TextField
                    name="invoiceNumber"
                    required
                    label="Invoice #"
                    // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                    controllerProps={{ control }}
                    fullWidth
                  />
                </MuiGrid>
                <MuiGrid item md={4}>
                  <InputLabelBase required className={classes.label}>
                    Status
                  </InputLabelBase>
                  <MuiSelect
                    {...register('status')}
                    variant="standard"
                    name="status"
                    onChange={handleSelect}
                    value={values.status}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {statusTypes.map(renderOptions)}
                  </MuiSelect>
                </MuiGrid>
              </MuiGrid>
              <Divider className={classes.divider} />

              <MuiGrid container spacing={3}>
                <MuiGrid item md={6}>
                  <TextField
                    name="paymentDate"
                    required
                    label="Payment Date"
                    // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                    controllerProps={{ control }}
                    fullWidth
                  />
                </MuiGrid>
                <MuiGrid item md={6}>
                  <InputLabelBase required className={classes.label}>
                    Payment Method
                  </InputLabelBase>
                  <MuiSelect
                    {...register('paymentMethod')}
                    variant="standard"
                    name="paymentMethod"
                    onChange={handleSelect}
                    value={values.paymentMethod}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                  >
                    {paymentOptions.map(renderOptions)}
                  </MuiSelect>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid container spacing={3}>
                <MuiGrid item md={6}>
                  <TextField
                    name="processorName"
                    label="Processor"
                    required
                    // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                    controllerProps={{ control }}
                    fullWidth
                  />
                </MuiGrid>
                <MuiGrid item md={6}>
                  <LanguageCurrencyField
                    name="amountPaid"
                    required
                    label="Amount"
                    // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                    controllerProps={{ control }}
                    fullWidth
                  />
                </MuiGrid>
              </MuiGrid>
              <Divider className={classes.divider} />

              <MuiGrid item md={12}>
                <TextField
                  type="text"
                  name="paymentDesc"
                  label="Payment Details"
                  fullWidth
                  multiline
                  minRows={4}
                  // @ts-expect-error TS(2322): Type 'Control<{ transactionId: any; invoiceNumber:... Remove this comment to see the full error message
                  controllerProps={{ control }}
                />
              </MuiGrid>
            </MuiGrid>
            {error && (
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton styleName="cancel" onClick={close} buttonText="Cancel" />
            <CbButton
              type="submit"
              styleName="ctaButton"
              buttonText="Update Transaction"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);

export default UpdateTransaction;
