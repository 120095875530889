import React from 'react';
import Moment from 'moment';
import { withStyles } from '@mui/styles';
import SocialMedia from './SocialMedia';
import { usePlatformLanguage } from '../../utils/hooks/usePlatformLanguage';
import { legal } from '../../i18n/constants';

const Footer = (props: any) => {
  const region = usePlatformLanguage();
  const { classes } = props;

  return (
    <footer className={classes.publicFooter}>
      <div className={classes.termsContainer}>
        <div className={props.classes.entry}>
          <a
            href="https://cowbell.insure/terms/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            href="https://cowbell.insure/privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div
          className={props.classes.entry}
        >{`© ${Moment().year()} Cowbell Cyber Inc. All Rights Reserved, `}</div>
        <div className={props.classes.entry}>{legal[region]}</div>
      </div>

      <div className={classes.socialContainer}>
        <SocialMedia />
      </div>
    </footer>
  );
};

const styles = ({ config }: any) => ({
  publicFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2,
    marginTop: 'auto',
    bottom: '0',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 10,
  },

  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: config.textSizes.petite,

    '& > *': {
      margin: '0 10px',
    },
  },
  socialContainer: {
    fontSize: config.textSizes.primer,
  },

  entry: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    justifyContent: 'center',
  },
});

// @ts-expect-error TS(2345): Argument of type '({ config }: any) => { publicFoo... Remove this comment to see the full error message
export default withStyles(styles)(Footer);
