import AT from '../actions/ActionTypes';

import { joinExistingAccount } from '../api/SignupService';

export const logout = () => {
  // delete local storage, though redux-persist will add it back
  // with the defaults due to the return statement below
  // localStorage.removeItem(`persist:${rootPersistorConfig.key}`);

  // tell authWorker user logged out
  const uiSettings = localStorage.getItem('cowbell');
  localStorage.clear();
  sessionStorage.clear();
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  localStorage.setItem('cowbell', uiSettings);

  return {
    type: AT.USER_LOGOUT,
  };
};

/* JOIN Existing User Account */
export const submitJoinExistingAccount =
  (credentials: any, token: any) => () => {
    return joinExistingAccount(credentials, token);
  };
